import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Gridstyles } from './index';
import { Applicant } from '../../redux-store/types/api-types';

export default function FatcaDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              FATCA/CRS
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First/Solo Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>

                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {applicant.fatcaCrsDocumentCheck && (
                        <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                          {/* <Checkbox defaultChecked disabled={true} /> */}
                          <Typography
                            sx={{
                              fontSize: 15,
                              fontWeight: 700,
                              color: 'rgba(0, 0, 0, 0.6)',
                              letterSpacing: '0.5px',
                            }}>
                            {`Wish to upload FATCA-CRS Declaration`}
                          </Typography>
                        </Grid>
                      )}
                      {!applicant.fatcaCrsDocumentCheck && (
                        <>
                          <KeyValue title={'Gender'} description={applicant.gender} />
                          <KeyValue
                            title={'Type Of Address Provided At KRA'}
                            description={applicant.typeOfAddressProvidedAtKRA}
                          />
                          <KeyValue
                            title={'Tax Resident Of Any Country Other Than India'}
                            description={
                              applicant.taxResidentOfAnyCountryOtherThanIndia === null
                                ? null
                                : applicant.taxResidentOfAnyCountryOtherThanIndia === true
                                ? 'Yes'
                                : 'No'
                            }
                          />
                          <KeyValue title={'Place Of Birth'} description={applicant.placeOfBirth} />
                          <KeyValue
                            title={'Country Of Birth'}
                            description={applicant.countryOfBirth}
                          />
                          <KeyValue
                            title={'Country Of Nationality'}
                            description={applicant.countryOfNationality}
                          />
                          <KeyValue
                            title={'Country Of Citizenship'}
                            description={applicant.countryOfCitizenship}
                          />
                          {applicant.taxResidentOfAnyCountryOtherThanIndia === true && (
                            <>
                              <KeyValue
                                title={'Tax Country Name'}
                                description={applicant.taxCountryName}
                              />
                              <KeyValue title={'Tax ID'} description={applicant.taxID} />
                              <KeyValue title={'Id Type'} description={applicant.idType} />
                            </>
                          )}
                          <KeyValue title={'Whether US Person'} description={applicant.usPerson} />
                          {applicant.usPerson === 'No' && applicant.usCitizenCheck && (
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              {/* <Checkbox defaultChecked disabled={true} /> */}
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  letterSpacing: '0.5px',
                                }}>
                                {`I confirm that I am neither a US citizen /person nor a resident for Tax purposes in the USA.`}
                              </Typography>
                            </Grid>
                          )}
                          {applicant.fundTaxOfferCheck && (
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              {/* <Checkbox defaultChecked disabled={true} /> */}
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  letterSpacing: '0.5px',
                                }}>
                                {`I/We understand that, the Fund is relying on this information for the purpose of determining the status of the applicant named above in compliance with FATCA/CRS. The Fund is not able to offer any tax advice on CRS or FATCA or its impact on the applicant. I/we shall seek advice from a professional tax advisor for any tax questions.`}
                              </Typography>
                            </Grid>
                          )}
                          {applicant.informationDeclaration && (
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              {/* <Checkbox defaultChecked disabled={true} /> */}
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  letterSpacing: '0.5px',
                                }}>
                                {`I/We certify that I/we provide the information on this form and to the best of my/our knowledge and belief the certification is true, correct, and complete including the taxpayer identification number of the applicant.`}
                              </Typography>
                            </Grid>
                          )}
                          {applicant.fundIntimateDeclaration && (
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              {/* <Checkbox defaultChecked disabled={true} /> */}
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  letterSpacing: '0.5px',
                                }}>
                                {`I/We agree to intimate the Fund, within 30 days if any information or certification on this form becomes incorrect.`}
                              </Typography>
                            </Grid>
                          )}
                          {applicant.fundRegulatorsDeclaration && (
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              {/* <Checkbox defaultChecked disabled={true} /> */}
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  letterSpacing: '0.5px',
                                }}>
                                {`I/We agree that as may be required by domestic regulators/tax authorities the Fund may also be required to report, reportable details to domestic regulators/tax authorities.`}
                              </Typography>
                            </Grid>
                          )}
                          {/* <KeyValue title={'Name Of Entity'} description={applicant.nameOfEntity} />
                      <KeyValue
                        title={'Date Of Incorporation'}
                        description={applicant.dateOfIncorporation?.split(' ')[0]}
                      />
                      <KeyValue
                        title={'City Of Incorporation'}
                        description={applicant.cityOfIncorporation}
                      />
                      <KeyValue
                        title={'Entity Excemption Code'}
                        description={applicant.entityExcemptionCode}
                      /> */}
                        </>
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
