import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Gridstyles } from '../Investments/investor-application';
import { KeyValue } from './contributor';
import { checkSupportLogin } from '../../utils/utilityFunctions';
import { DistributorTypesForUserManageMent } from '../../utils/constant';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';

export default function DistributorDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { auth } = useSelector((store: RootStateType) => store);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                RM/Distributor Details
              </Typography>
            </Grid>

            <Gridstyles>
              <Grid
                item
                container
                pt={1}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                {/* <KeyValue
                  title={'Distributor ARN Code'}
                  description={application?.distributor?.arnCode}
                /> */}
                <KeyValue title={'Distributor Name'} description={application?.distributor?.name} />
                {application?.distributor?.type ===
                  DistributorTypesForUserManageMent.Individual && (
                  <>
                    <KeyValue
                      title={'Distributor Email Id'}
                      description={application?.distributor?.user?.email}
                    />
                    <KeyValue
                      title={'Distributor Mobile Number'}
                      description={application?.distributor?.user?.phone}
                    />
                    <KeyValue
                      title={'Distributor Alternate Mobile Number'}
                      description={application?.distributor?.alternativeMobileNumber}
                    />
                  </>
                )}
                <KeyValue
                  title={'Distributor RM Name'}
                  description={application?.subDistributor?.user.firstName}
                />
                <KeyValue
                  title={'Distributor RM Email Id'}
                  description={application?.subDistributor?.user?.email}
                />
                <KeyValue
                  title={'Distributor RM Mobile Number'}
                  description={application?.subDistributor?.user?.phone}
                />
                <KeyValue
                  title={'Distributor RM Alternate Mobile Number'}
                  description={
                    application?.distributor?.alternativeMobileNumber ||
                    application?.subDistributor?.alternativeMobileNumber
                  }
                />
                {!checkSupportLogin(
                  Number(auth.id),
                  Number(application?.supportSubdistributor?.user.id)
                ) && (
                  <>
                    <KeyValue
                      title={'CSE Name'}
                      description={application?.supportSubdistributor?.user.firstName}
                    />
                    <KeyValue
                      title={'CSE Email'}
                      description={application?.supportSubdistributor?.user?.email}
                    />
                    <KeyValue
                      title={'CSE Mobile Number'}
                      description={application?.supportSubdistributor?.user?.phone}
                    />
                  </>
                )}
                <KeyValue title={'Broker Name'} description={application?.brokerName} />
                {/* <KeyValue title={'AMCs RM Email Id'} description={application?.rm?.user?.email} /> */}
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
