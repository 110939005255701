import {
  AppBar,
  Box,
  CardMedia,
  Container,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Dialog,
  Button,
  Stack,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogProps,
  Divider,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  FormControl,
  Grid,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  ApplicationsIcon,
  FundsSetup,
  RiskProfile,
  AMCAdmins,
  Reports,
  DashboardIcon,
} from './customSVGs';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux-store/actions/auth';
import MenuIcon from '@mui/icons-material/Menu';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToApp from '@mui/icons-material/ExitToApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CancelIcon from '@mui/icons-material/Cancel';
import { RootStateType } from '../redux-store/reducers';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { APPLICATION_TYPE, ENABLE_Email_Consent, USER_ROLES } from '../utils/constant';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import { Formik, useField, useFormikContext } from 'formik';
import { getCountryCodes } from '../utils/utilityFunctions';
import { Applicant } from '../redux-store/types/api-types';
import { Gridstyles, Item, ItemDetails } from './ApplicationDetails';
import { SubHeading } from './investors/components';
import { KeyValue } from './ApplicationDetails/contributor';
import { JsxAttributeLike } from 'typescript';
import * as yup from 'yup';
import { MFTextField } from '../lib/formik';
import MFCheckbox from '../lib/formik/Checkbox';
import { useHistory } from 'react-router';
import { getParams } from '../redux-store/actions/application';
import { initialState } from '../redux-store/reducers/queryParams';
import { renderFilteredOptions, SearchFieldWrapper } from '../lib/formik/searchSelectField';

export function OtpTimer({ onResend }: { onResend: () => void }): JSX.Element {
  const [counter, setCounter] = useState(60);

  // Third Attempts
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <Box>
      <Typography>
        <Button
          sx={{
            color: 'primary.main',
            mt: 2,
            fontSize: 16,
            fontWeight: 500,
            p: 0,
            ':hover': {
              background: 'none',
            },
          }}
          disabled={counter > 0}
          onClick={() => {
            try {
              onResend();
              setCounter(60);
            } catch (e) {
              console.error((e as Error).message);
            }
          }}>
          Resend OTP
        </Button>
        <br />
        {counter > 0 && `in ${counter} seconds`}
      </Typography>
    </Box>
  );
}

// Footer
export const Footer = (): JSX.Element => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'primary.main',
        display: 'flex',
        justifyContent: 'center',
        py: 1,
      }}>
      <Typography sx={{ color: 'common.white' }}>Powered By </Typography>
      <CardMedia
        component="img"
        src="/images/kfin-new-logo.png"
        alt="kFintech Logo"
        sx={{ width: 'unset' }}
      />
    </Box>
  );
};
// Footer End

// Login Layout
export const LoginLayout = ({
  LoginText,
  children,
}: {
  LoginText: string;
  children: JSX.Element;
}): JSX.Element => {
  return (
    <Stack
      sx={{
        minHeight: '100vh',
        background: '#f2f6f8 url("/images/login-bg.svg")',
        backgroundSize: 'cover',
      }}>
      <AppBar elevation={0} position="static" sx={{ bgcolor: 'transparent' }}>
        <Box>
          <CardMedia
            component="img"
            src="/images/108-capital-logo.svg"
            alt="Logo"
            sx={{ width: '150px' }}
          />
        </Box>
      </AppBar>
      <Box
        component="main"
        sx={{
          // height: 'calc(100vh - 43px)',
          flex: 1,
          display: 'grid',
          placeItems: 'center',
          // mt: '-88px',
        }}>
        <Box
          sx={{
            bgcolor: 'grey.50',
            height: { xs: 'unset', sm: '470px' },
            width: 'clamp(300px, 70%, 500px)',
            px: { xs: '25px', sm: '80px' },
            py: '50px',
            mb: 2,
            borderRadius: '10px',
            boxShadow: '0px 3.29214px 6.58427px rgba(27, 27, 27, 0.16)',
          }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: 28,
              fontWeight: 600,
              color: 'primary.main',
              mb: '50px',
              textAlign: 'center',
            }}>
            {LoginText}
          </Typography>
          {children}
        </Box>
      </Box>
      {/* <Footer /> */}
    </Stack>
  );
};
// Login Layout End

// Header & Sidenav
const drawerWidth = '169px';

const MenuButton = ({
  to = null,
  text,
  children,
  downArrow,
  onClick,
}: {
  to?: string | null;
  text: string;
  children: JSX.Element;
  downArrow?: boolean;
  onClick?: () => void;
}) => {
  const { path } = useRouteMatch();
  const match = to
    ? path.includes(to)
    : to !== '' &&
      [
        '/onboarding',
        '/applications',
        '/application',
        '/application-details/:id',
        '/non-individual-application-details/:id',
        '/non-individual-application',
        '/application-preview/:id',
        '/non-individual-application-preview/:id',
      ].includes(path);
  const disabled = to === null;
  return (
    <ListItemButton
      onClick={onClick}
      component={disabled ? Typography : RouterLink}
      to={to || ''}
      sx={{
        p: { xs: '10px 20px', md: 3 },
        background: match
          ? 'linear-gradient(88.93deg, rgba(51, 127, 201, 0.10) 24.43%, rgba(51, 127, 201, 0) 93.37%)'
          : 'transparent',
        color: match ? 'primary.main' : 'secondary.main',
        stroke: match ? '#2057A6' : '#131836',
        strokeWidth: 0,
        '.MuiSvgIcon-root ': {
          opacity: match ? 1 : 0.5,
        },
        ':hover': {
          background: match
            ? 'linear-gradient(88.93deg, rgba(51, 127, 201, 0.10) 24.43%, rgba(51, 127, 201, 0) 93.37%)'
            : 'transparent',
        },
        textAlign: 'center',
        // pointerEvents: text === 'Funds Setup' ? 'none' : 'all',
      }}>
      {children}
      <ListItemText primary={text} />
      {downArrow && <ExpandMoreIcon />}
    </ListItemButton>
  );
};

const KfinDrawer = ({ onToggleClick }: { onToggleClick: () => void }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { role = '', adminSupport } = useSelector((store: RootStateType) => store.auth);
  return (
    <Box>
      <List
        sx={{
          pt: { xs: 2, md: 3 },
          '& .MuiListItemButton-root': {
            mb: 1,
            flexDirection: { xs: 'row', md: 'column' },
            '& .MuiListItemText-root': { mx: { xs: 1, md: 0 } },
            '& .MuiListItemText-root span': {
              fontFamily: 'Poppins, sans-serif',
              fontSize: 16,
              fontWeight: 500,
            },
          },
        }}>
        <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
          <IconButton onClick={onToggleClick} aria-label="close drawer" sx={{ color: '#00D9DA' }}>
            <CancelIcon />
          </IconButton>
        </Box>
        {USER_ROLES.AMCAPPROVER !== role && (
          <>
            <MenuButton to="/dashboard" text="Dashboard">
              <DashboardIcon />
            </MenuButton>
            <MenuButton text="Applications" downArrow={!open} onClick={() => setOpen(!open)}>
              <ApplicationsIcon />
            </MenuButton>
          </>
        )}
        {open && (
          <Box
            sx={{
              textAlign: 'center',
              '.MuiTypography-root': {
                my: 1,
                py: 1,
                fontWeight: 500,
                fontSize: 14,
                display: 'block',
                textDecoration: 'none',
                color: 'text.primary',
                background:
                  'linear-gradient(90deg, rgba(15, 212, 216, 0.072) 0%, rgba(97, 209, 214, 0) 100%)',
              },
            }}>
            <Typography
              component={RouterLink}
              to="/onboarding"
              onClick={() => {
                dispatch(getParams(initialState));
              }}>
              Add New
            </Typography>
            <Typography
              component={RouterLink}
              to="/applications"
              onClick={() => {
                dispatch(getParams(initialState));
              }}>
              View Existing
            </Typography>
            {ENABLE_Email_Consent && (
              <Typography component={RouterLink} to="/view-consent-email-list">
                View Email Consent
              </Typography>
            )}
            <IconButton onClick={() => setOpen(!open)}>
              <ExpandLessIcon sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box>
        )}
        {USER_ROLES.AMCAPPROVER === role && (
          <>
            <MenuButton text="Applications" to="/applications-list">
              <ApplicationsIcon />
            </MenuButton>
            <MenuButton text="Reports" to="/reports">
              <Reports />
            </MenuButton>
          </>
        )}
        {USER_ROLES.AMC_ADMIN === role && (
          <>
            <MenuButton text="User Management" to="/user-management">
              <PersonOutlineOutlinedIcon />
            </MenuButton>
            <MenuButton text="Funds Setup" to="/funds-setup">
              <FundsSetup />
            </MenuButton>
            <MenuButton text="Reports" to="/reports">
              <Reports />
            </MenuButton>
            {adminSupport && (
              <MenuButton text="Admin Support" to="/admin-support">
                <RiskProfile />
              </MenuButton>
            )}
            {/* <MenuButton text="Risk Profile" to="/risk-profile">
              <RiskProfile />
            </MenuButton> */}
          </>
        )}
        {USER_ROLES.SUPER_ADMIN === role && (
          <MenuButton text="AMC Admins" to="/amc-admins">
            <AMCAdmins />
          </MenuButton>
        )}
        {/* <MenuButton to="" text="Settings">
          <SettingsIcon />
        </MenuButton> */}
      </List>
    </Box>
  );
};

export const HeaderSidenav = (): JSX.Element => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'white',
          boxShadow: 'none',
        }}>
        <Toolbar sx={{ px: { xs: 2, lg: 4 } }}>
          <Box sx={{ mr: 'auto' }}>
            <CardMedia
              component="img"
              src="/images/108-capital-logo.svg"
              alt="Logo"
              sx={{ width: '100px' }}
            />
          </Box>
          {/* <Box sx={{ display: 'flex' }}>
            <IconButton onClick={() => history.push('/profile')}>
              <AccountCircleIcon fontSize="large" sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box> */}
          <Box sx={{ display: 'flex' }}>
            <IconButton onClick={() => dispatch(logout())}>
              <ExitToApp fontSize="large" sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: 'none' }, color: 'secondary.main' }}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="side nav">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}>
          <KfinDrawer onToggleClick={() => setMobileOpen(!mobileOpen)} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              mt: '100px',
              border: 0,
              maxHeight: 'calc(100vh - 100px)',
              overflow: 'scroll',
              // pb: 'calc(65px + 10%)',
              // justifyContent: 'center',
            },
          }}
          open>
          <KfinDrawer onToggleClick={() => setMobileOpen(!mobileOpen)} />
        </Drawer>
      </Box>
    </>
  );
};
// Header & Sidenav END

export const CommonLayout = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          // minHeight: 'calc(100vh - 43px)',
          // overflow: 'auto',
          flexDirection: 'column',
          paddingBottom: 0,
        }}>
        <HeaderSidenav />
        <Box
          component="main"
          sx={{
            mt: '65px',
            ml: { xs: 0, md: drawerWidth },
            p: { xs: 2, md: '50px' },
            pt: { md: '20px' },
            borderRadius: '50px 0 0 0',
            bgcolor: { xs: '', md: 'rgba(238, 244, 251, 0.5)' },
            minHeight: 'calc(100vh - 108px)',
            //overflow: 'auto',
          }}>
          <Container maxWidth="lg" disableGutters={true}>
            {children}
          </Container>
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  );
};

export const UserManagementCommonLayout = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          // minHeight: 'calc(100vh - 43px)',
          // overflow: 'auto',
          flexDirection: 'column',
        }}>
        <HeaderSidenav />
        <Box
          component="main"
          sx={{
            mt: '65px',
            ml: { xs: 0, md: drawerWidth },
            p: { xs: 0, md: '50px' },
            pt: { md: '20px' },
            borderRadius: { xs: '', sm: '50px 0 0 0' },
            bgcolor: 'rgba(238, 244, 251, 0.5)',
            minHeight: 'calc(100vh - 108px)',
            //overflow: 'auto',
          }}>
          <Container maxWidth="lg" disableGutters={true}>
            {children}
          </Container>
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  );
};

export const ConfirmationDialog = ({
  open,
  setOpen,
  message = 'Are you sure you want to delete?',
  onCancel,
  onSave,
}: {
  message: string;
  open: boolean;
  setOpen: () => void;
  onCancel: () => void;
  onSave: () => void;
}): JSX.Element => {
  return (
    <Dialog open={open} onClose={setOpen}>
      <Box
        sx={{
          p: { xs: 2, sm: '45px 40px' },
          borderRadius: '10px',
          // width: { xs: '70%', sm: '70%' },
          maxWidth: 400,
          height: { xs: '90%', md: 'unset' },
          overflowY: 'auto',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiButton-root': {
              minWidth: 100,
              fontSize: 16,
            },
          }}>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 500,
              color: '#337FC9',
              textAlign: 'center',
              mb: 5,
            }}>
            {message}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 1.5,
              '.MuiButton-root': {
                minWidth: '100px',
              },
            }}>
            <Button variant="contained" onClick={onSave} sx={{ color: 'common.white' }}>
              Yes
            </Button>
            <Button variant="outlined" onClick={onCancel} sx={{ color: 'primary.main' }}>
              No
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

type Props = DialogProps & {
  title?: string;
  content: string;
  actions: {
    label: string;
    onClick: () => void;
  }[];
};

export default function SimpleDialog({ title, ...props }: Props): JSX.Element {
  return (
    <Dialog
      {...props}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { sx: '300px', md: '400px' },
          maxWidth: { sx: '500px', md: '500px' },
          borderRadius: '8px',
          padding: '30px 30px 20px',
          textAlign: 'center',
          color: '#4f4f4f',
        },
        '& .MuiDialogContent-root': {
          textAlign: 'center',
        },
        '& .MuiDialogActions-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiButton-root': {
            width: '160px',
            height: '58px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '18px',
            margin: 'auto',
            marginBottom: '20px',
          },
        },
      }}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.actions.map((action, index) => (
          <Button variant="contained" color="primary" key={index} onClick={action.onClick}>
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}

export function CommonLayoutForPOA(): JSX.Element {
  const { role } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  return (
    <>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: '48px',
            paddingLeft: '48px',
          }}>
          <CardMedia
            component="img"
            src="/images/108-capital-logo.svg"
            alt="Logo"
            sx={{ width: '100px', m: 2 }}
          />
          {role !== USER_ROLES.INVESTOR && (
            <Box sx={{ display: 'flex' }}>
              <IconButton onClick={() => dispatch(logout())}>
                <ExitToApp fontSize="large" sx={{ color: 'primary.main' }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
    </>
  );
}

export function CountryCodesDropDown({
  name,
  value,
  readOnly = false,
  disabled = false,
}: {
  name: string;
  value: string;
  readOnly?: boolean;
  disabled?: boolean;
}): JSX.Element {
  const { setFieldValue } = useFormikContext();
  const [query, setQuery] = useState('');

  return (
    <InputAdornment
      position="start"
      sx={{
        pr: '0 !important',
        mx: '0 !important',
      }}>
      <Box
        sx={{
          '.MuiOutlinedInput-root': {
            marginTop: '0',
            '&.MuiInputBase-root': {
              '& .MuiSelect-select': {
                padding: '12px 15px 12px 0',
                border: 'none',
              },
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },

          '&.Mui-focused,&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: 'none',
            },
          },
          svg: {
            color: '#337FC9',
          },
        }}>
        <Select
          labelId="user-items"
          onChange={(e) => {
            setFieldValue(name, `${e.target.value}`);
          }}
          value={value}
          renderValue={(value) => {
            return (
              <Typography>{value.split(':')[1] ? value.split(':')[1].trim() : value}</Typography>
            );
          }}
          MenuProps={{
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              '.MuiPaper-root ': {
                width: { xs: '100%', sm: '31%', md: '26%' },
                maxHeight: '20%',
                overflowX: 'auto',
              },
            },
            disableAutoFocusItem: true,
            MenuListProps: {
              disableListWrap: true,
            },
          }}
          onClose={() => {
            setQuery('');
          }}
          readOnly={readOnly}
          disabled={disabled}>
          <SearchFieldWrapper searchFieldPlaceholder={'Search...'} setQuery={setQuery} />
          {renderFilteredOptions(
            getCountryCodes().map((codes) => ({
              key: codes.label,
              value: codes.label,
            })),
            query,
            false
          )}
          {/* {getCountryCodes().map((codes, ind) => (
            <MenuItem value={codes.label} key={ind}>
              {codes.label}
            </MenuItem>
          ))} */}
        </Select>
      </Box>
    </InputAdornment>
  );
}
export interface AmlPopupValues {
  amlComments: string;
  amlAcknowledgement: boolean;
}

const amlPopupInitialValues: AmlPopupValues = {
  amlComments: '',
  amlAcknowledgement: false,
};
const amlPopupValidation = yup.object().shape({
  amlComments: yup.string().required('Field is required'),
  amlAcknowledgement: yup.bool().test('amlAcknowledgement', 'Field is required', (value) => {
    return value || false;
  }),
});
export function AmlPopup({
  handleClose,
  amlPopupOpen,
  onSubmit,
  FURTHER_INFORMATION,
  EXTERNAL_SOURCES,
  displayDocInfo = false,
}: {
  handleClose: () => void;
  amlPopupOpen: boolean;
  onSubmit: (values: AmlPopupValues) => void;
  FURTHER_INFORMATION?: string;
  EXTERNAL_SOURCES?: string;
  displayDocInfo?: boolean;
}): JSX.Element {
  return (
    <Dialog
      onClose={handleClose}
      open={amlPopupOpen}
      sx={{
        '.MuiPaper-root ': {
          p: 2,
          width: { xs: '100%', sm: '70%' },
        },
      }}>
      <Box sx={{ mb: 2, mt: 1 }}>
        <CardMedia
          component="img"
          src="/images/warning-icon-red.svg"
          sx={{ width: '50px', mx: 'auto' }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: '100%', sm: '89%' },
          mx: 'auto',
        }}>
        <Typography
          sx={{
            color: '#BD2932',
            textAlign: 'center',
            mb: 1,
            fontSize: 14,
            fontWeight: 500,
          }}>
          Sorry, this PAN has failed the AML check
        </Typography>
        <Typography
          sx={{
            color: 'secondary.main',
            textAlign: 'start',
            mt: 2,
            fontSize: 16,
            fontWeight: 500,
            mb: 0.5,
          }}>
          Further Information:
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: '25vh',
            overflowY: 'auto',
            overflowWrap: 'anywhere',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}>
          <Typography
            sx={{
              color: 'rgba(0,0,0,0.7)',
              textAlign: 'start',
              m: 2,
              fontSize: 14,
              fontWeight: 500,
            }}>
            {FURTHER_INFORMATION || ''}
          </Typography>
          <Typography
            sx={{
              color: 'primary.main',
              textAlign: 'start',
              ml: 2,
              fontSize: 16,
              fontWeight: 600,
            }}>
            Reference Links
          </Typography>
          <Typography
            sx={{
              color: 'rgb(0, 0, 255)',
              textAlign: 'start',
              fontSize: 14,
              fontWeight: 500,
              m: 2,
              mt: 0.5,
            }}>
            {EXTERNAL_SOURCES || ''}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: 'secondary.main',
            textAlign: 'start',
            fontSize: 14,
            fontWeight: 500,
            mt: 3,
            mb: 0,
          }}>
          If you still wish to proceed with this PAN, please provide reason below.
        </Typography>
        <Formik
          initialValues={amlPopupInitialValues}
          onSubmit={onSubmit}
          validationSchema={amlPopupValidation}>
          {({ handleSubmit, values }) => (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
                '.MuiTypography-root': {
                  fontSize: 15,
                  fontWeight: 500,
                },
              }}>
              <MFTextField
                name="amlComments"
                multiline
                rows={3}
                inputProps={{
                  maxLength: 1024,
                }}
                placeholder="Type here..."
                displayCharecterCounter={'1024'}
                sx={{
                  color: 'rgba(0,0,0,0.7)',
                  pl: 0.5,
                  pt: 0.5,
                  '&.MuiInputBase-root': {
                    fontSize: 14,
                    fontWeight: 500,
                  },
                }}
              />
              <MFCheckbox
                name={`amlAcknowledgement`}
                label="I understand the risks and acknowledge to proceed further."
                sx={{ mt: 1 }}
              />
              {values.amlAcknowledgement && displayDocInfo && (
                <Typography
                  sx={{
                    color: 'primary.main',
                    textAlign: 'center',
                    fontSize: 14,
                    fontWeight: 500,
                    mt: 1,
                    mb: 0,
                  }}>
                  Please upload compliance document in document details.
                </Typography>
              )}
              <Grid container sx={{ mt: 3, mb: 1, justifyContent: 'center', gap: 1.5 }}>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  variant="outlined"
                  sx={{
                    fontSize: 15,
                    color: 'primary.main',
                    minWidth: 180,
                    letterSpacing: 2,
                    fontWeight: 500,
                  }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: 15,
                    minWidth: 180,
                    letterSpacing: 2,
                    fontWeight: 400,
                  }}
                  type="submit">
                  Proceed
                </Button>
              </Grid>
            </Box>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}
export function AmlDetailsFields({
  amlCheck,
  amlCommentedRole,
  amlComments,
}: {
  amlCheck: boolean | undefined;
  amlCommentedRole: string | undefined;
  amlComments: string | undefined;
}): JSX.Element {
  return (
    <Grid
      item
      container
      rowSpacing={3}
      pb={2}
      columnSpacing={13}
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ paddingLeft: { xs: 0, sm: '10%' } }}>
      <KeyValue title={'Aml Check'} description={amlCheck?.toString().toUpperCase() || ''} />
      <KeyValue title={'Commented By'} description={amlCommentedRole?.toUpperCase() || ''} />
      <Grid item xs={12}>
        <Item>{'Comments'}</Item>
        <ItemDetails> {amlComments || '-'}</ItemDetails>
      </Grid>
    </Grid>
  );
}
export function AmlDetails({
  applicants = [],
  applicationType,
}: {
  applicants: Partial<Applicant>[];
  applicationType: string;
}): JSX.Element {
  return (
    <>
      {!applicants.every((applicant) => applicant.amlCheck) && (
        <Box
          sx={{
            bgcolor: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
            borderRadius: '10px',
          }}
          my={5}>
          <Box sx={{ p: 2 }}>
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                  Aml Check Details
                </Typography>
              </Grid>
              {applicants
                .filter((applicant) => !applicant.amlCheck)
                .map((applicant, index: number) => {
                  const title = [
                    'First Applicant Details',
                    'Second Applicant Details',
                    'Third Applicant Details',
                  ][Number(applicant.applicant_type) - 1];
                  return (
                    <React.Fragment key={applicant.id}>
                      {applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL && (
                        <SubHeading
                          sx={{
                            color: 'common.black',
                            letterSpacing: 0.5 + 'px',
                            padding: { xs: '10px 10px', sm: '10px 30px' },
                          }}>
                          {title}
                        </SubHeading>
                      )}
                      <AmlDetailsFields
                        amlCheck={applicant.amlCheck}
                        amlCommentedRole={applicant.amlCommentedRole}
                        amlComments={applicant.amlComments}
                      />
                    </React.Fragment>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
}

export const LoadingDialog = ({
  loadingPopup,
  onLoadingPopupClose,
  loaderText = 'Loading',
}: {
  loadingPopup: boolean;
  onLoadingPopupClose: () => void;
  loaderText?: string;
}): JSX.Element => {
  return (
    <Dialog
      onClose={onLoadingPopupClose}
      open={loadingPopup}
      sx={{
        '.MuiPaper-root ': {
          p: 4,
          width: { xs: '70%', sm: '70%' },
        },
      }}>
      <Box
        sx={{
          width: { xs: '100%', sm: '80%' },
          mx: 'auto',
          '.MuiTypography-root': {
            fontSize: 20,
            fontWeight: 500,
          },
        }}>
        <Typography sx={{ color: 'primary.main', lineHeight: '35px', textAlign: 'center' }}>
          {loaderText}...
        </Typography>
      </Box>
    </Dialog>
  );
};

export const FileUploadDialog = ({
  show,
  handleClose,
  message,
  code,
}: {
  show: boolean;
  handleClose: () => void;
  message?: string;
  code: string;
}): JSX.Element => {
  return (
    <Dialog
      open={show}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { sx: '300px', md: '400px' },
          maxWidth: { sx: '500px', md: '500px' },
          borderRadius: '8px',
          padding: '30px 30px 20px',
          color: '#4f4f4f',
        },
        '& .MuiDialogActions-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiButton-root': {
            width: '160px',
            height: '58px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '18px',
            margin: 'auto',
            marginBottom: '20px',
          },
        },
      }}>
      <DialogContent>
        <DialogContentText>
          {' '}
          <Typography sx={{ textAlign: 'center' }}>{message}</Typography>
          {code === 'FILE_ENCRYPTION_ERROR' && (
            <>
              <Typography fontWeight={450} mt={2}>
                {' '}
                Steps for flattening PDF
              </Typography>
              <Typography mt={1}>
                &bull; Right click on the file, and navigate to “Open With” and choose Chrome
                browser
              </Typography>
              <Typography>&bull; Then Open the print menu by pressing Ctrl+P</Typography>
              <Typography>
                &bull; Under the “Printer” drop-down menu, select “Microsoft Print to PDF” or “Save
                as PDF”.
              </Typography>
              <Typography> &bull; Click “Print”.</Typography>
              <Typography>&bull; When prompted, choose where you want to save the file.</Typography>
              <Typography> &bull; Upload the newly saved file.</Typography>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
