import {
  Box,
  Typography,
  Button,
  AppBar,
  CardMedia,
  Divider,
  IconButton,
  Grid,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApplicationProps,
  Document,
  esignApplicationResponse,
} from '../../redux-store/types/api-types';
import {
  esignApplication,
  getApplicationDetailsWithRefId,
} from '../../redux-store/actions/onBoarding';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { Footer } from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ESIGN_STATUS } from '../../utils/constant';
import {
  acknowledgeApplication,
  getDocuments,
  isFormValidForSubmission,
  updateApplication,
} from '../../redux-store/actions/application';
import { showError } from '../../redux-store/actions/auth';
import { ApplicationDetailsCommonLayout } from '../ApplicationDetails/index';
import { getNationalityList } from '../../redux-store/actions';
import { nationaliyType } from '../../redux-store/types/mdms';
import MFCheckbox from '../../lib/formik/Checkbox';
import { Formik } from 'formik';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));

export type Values = {
  fundMemorandumCheck: boolean | null;
  kycInformationConsent: boolean | null;
};

const initialValues: Values = {
  fundMemorandumCheck: false,
  kycInformationConsent: false,
};

export default function InvestorApplication(): JSX.Element {
  const { referenceId } = useParams<{ referenceId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { investor, auth } = useSelector((store: RootStateType) => store);
  const [loading, setLoading] = useState(false);
  const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const [application, setApplication] = useState<ApplicationProps>();
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const _application = (await dispatch(
          getApplicationDetailsWithRefId(referenceId)
        )) as unknown as ApplicationProps;
        setApplication(_application);
        setFatcaDetails({
          ...fatcaDetails,
          fundMemorandumCheck: _application.fundMemorandumCheck || false,
          kycInformationConsent: _application.kycInformationConsent || false,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  useEffect(() => {
    const { token } = investor;
    if (!token) {
      history.push(`/investment-details/${referenceId}/application-details`);
    }
  }, []);

  const handleEsign = async (values: Values) => {
    try {
      if (values.fundMemorandumCheck === false || values.kycInformationConsent === false) {
        throw 'Declaration is required';
      }
      const nationalitiesMdmsMasters = (await dispatch(
        getNationalityList()
      )) as unknown as nationaliyType;
      const documentsResponse = (await dispatch(getDocuments())) as unknown as Document;
      if (ESIGN_STATUS.PENDING_SIGNATURE !== application?.signDetails.status) {
        ESIGN_STATUS.NOT_GENERATED === application?.signDetails.status
          ? await isFormValidForSubmission(
              application as ApplicationProps,
              true,
              true,
              nationalitiesMdmsMasters,
              documentsResponse,
              auth.role
            )
          : await isFormValidForSubmission(
              application as ApplicationProps,
              true,
              false,
              nationalitiesMdmsMasters,
              documentsResponse,
              auth.role
            );
      }
      setIsSubmitting(true);
      const { signDetails, id } = application || {};
      if (signDetails?.url) {
        window.open(signDetails?.url, '_blank');
        return;
      }
      if (id) {
        const applicationResponse = (await dispatch(
          acknowledgeApplication({
            body: {
              ...application,
              fundMemorandumCheck: values.fundMemorandumCheck,
              kycInformationConsent: values.kycInformationConsent,
            },
            applicationId: id,
          })
        )) as unknown as ApplicationProps;
        if (applicationResponse.fundMemorandumCheck && applicationResponse.kycInformationConsent) {
          const response = (await dispatch(
            esignApplication(referenceId)
          )) as unknown as esignApplicationResponse;
          setApplication({ ...application, signDetails: response } as unknown as ApplicationProps);
          window.open(response?.url, '_blank');
          return;
        }
        return;
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e as string));
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={fatcaDetails} onSubmit={handleEsign} enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Box
          sx={{
            bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
          }}
          component="form"
          // noValidate
          onSubmit={handleSubmit}>
          <IconButton
            sx={{
              position: 'fixed',
              right: 0,
              bottom: 75,
              borderRadius: '5px  0 0 5px',
              '&,:hover': {
                bgcolor: 'primary.main',
              },
            }}
            onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
            <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
          </IconButton>
          <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
            <Box>
              <CardMedia
                component="img"
                src="/images/108-capital-logo.svg"
                alt="Logo"
                sx={{ width: '100px', m: 1, my: 1.0 }}
              />
            </Box>
          </AppBar>
          <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
          <Box sx={{ py: 5, px: { xs: 5, sm: 10 } }} ref={applicationDetailRef}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 2,
                justifyContent: 'space-between',
                mt: 10,
              }}>
              <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main', mb: 4 }}>
                Application Details
              </Typography>
              {application?.editable && (
                <IconButton>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() =>
                      history.push(`/investment-details/edit-application`, {
                        id: application?.id,
                        applicant1ReferenceId: application?.applicant1ReferenceId,
                      })
                    }
                  />
                </IconButton>
              )}
            </Box>
            {application && (
              <>
                <ApplicationDetailsCommonLayout
                  loading={loading}
                  application={application as ApplicationProps}
                />
                <Grid item xs={12} sx={{ mt: 4 }}>
                  <MFCheckbox
                    name={`fundMemorandumCheck`}
                    label={`I hereby confirm that I have received, read, and understood the private placement memorandum and the Contribution Agreement of ${application?.scheme?.schemeName}.`}
                    sx={{ justifyContent: 'flex-start' }}
                    onChange={({ target: { checked } }) => {
                      setValues({
                        ...values,
                        fundMemorandumCheck: checked,
                      });
                    }}
                    // onChange={({ target: { checked } }) => {
                    //   // setValues({
                    //   //   ...values,
                    //   //   applicants: values.applicants.map((applicant) => {
                    //   //     return { ...applicant, fundMemorandumCheck: checked };
                    //   //   }),
                    //   // });
                    // }}
                  />
                  <MFCheckbox
                    name={`kycInformationConsent`}
                    label={`I have granted my consent to ${application?.scheme?.schemeName} or its representatives to fetch my KYC information, data or documents, as may be required.`}
                    onChange={({ target: { checked } }) => {
                      setValues({
                        ...values,
                        kycInformationConsent: checked,
                      });
                    }}
                  />
                </Grid>
                {![ESIGN_STATUS.SIGNED, ESIGN_STATUS.EXPIRED].includes(
                  application.signDetails.status
                ) && (
                  <Box sx={{ textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        color: 'common.white',
                        minWidth: '200px',
                        mt: 4,
                        fontWeight: 600,
                        lineHeight: 1.5,
                      }}
                      // onClick={handleEsign}
                      disabled={isSubmitting}>
                      {`e-Sign ${isSubmitting ? '...' : ''}`}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Footer />
        </Box>
      )}
    </Formik>
  );
}
