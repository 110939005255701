import { ExitToApp } from '@mui/icons-material';
import { AppBar, CardMedia, Divider, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Location } from 'history';
import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router';
import { logout } from '../../redux-store/actions/auth';
import LoginOtp from '../auth/loginOtp';
import { CommonLayout, Footer } from '../commonComponents';
import { Routes } from '../investors/routes';
import InvestmentDetails from './investment-details';
import InvestorApplication from './investor-application';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ROLES } from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';

export const EditApplication = ({
  location,
}: {
  location: Location<{ id: string; applicant1ReferenceId?: string }>;
}): JSX.Element => {
  const { id, applicant1ReferenceId } = location.state || {};
  const dispatch = useDispatch();
  const history = useHistory();

  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <>
      {USER_ROLES.AMCAPPROVER === role ? (
        <CommonLayout>
          <Routes id={id} applicant1ReferenceId={applicant1ReferenceId} />
        </CommonLayout>
      ) : (
        <Box
          sx={{
            bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
          }}>
          <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: '48px',
                paddingLeft: '48px',
              }}>
              <CardMedia
                component="img"
                src="/images/108-capital-logo.svg"
                alt="Logo"
                sx={{ width: '100px', my: 1.86 }}
              />
              {role !== USER_ROLES.INVESTOR && (
                <Box sx={{ display: 'flex' }}>
                  <IconButton
                    onClick={() => {
                      dispatch(logout());
                      history.push('/login');
                    }}>
                    <ExitToApp fontSize="large" sx={{ color: 'primary.main' }} />
                  </IconButton>
                </Box>
              )}
            </Box>
          </AppBar>
          <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
          <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: 10 }}>
            <Routes id={id} applicant1ReferenceId={applicant1ReferenceId} />
          </Box>
          <Footer />
        </Box>
      )}
    </>
  );
};

const InvestorRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={(props: any) => (
          <Redirect to={`${path}/:referenceId/details`} from={props.location.state} />
        )}
      />
      <Route path={`${path}/:referenceId/details`} component={InvestmentDetails} />
      <Route path={`${path}/:referenceId/verify-pan`} component={LoginOtp} />
      <Route path={`${path}/:referenceId/application-details`} component={InvestorApplication} />
      <Route path={`${path}/edit-application`} component={EditApplication} />
      <Route
        path="*"
        render={() => {
          return 'Page you are requesting is not found.....';
        }}
      />
    </Switch>
  );
};

export default InvestorRoutes;
