import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
import { formatShortDate } from '../../utils/date';
import {
  booleanToYesNo,
  currencyConversion,
  isApplicableField,
  isApplicableSetupfee,
} from '../../utils/utilityFunctions';

export default function InvestAndPaymentDetails({
  application,
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                rowSpacing={6}
                columnSpacing={23}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue
                  title={'Wish To Lower the Commitment Amount'}
                  description={application?.doNotWishToValidateCommitmentAmount ? 'Yes' : 'No'}
                />
                <KeyValue title={'Fund (Scheme)'} description={application.scheme?.schemeName} />
                <KeyValue title={'Class Plan'} description={application.plan?.planDescription} />
                {isApplicableSetupfee(application.plan?.setupFeeApplicable || '') && (
                  <KeyValue
                    title={'Setup Fee % (One-time)'}
                    description={
                      application?.applicationSetupFee === 0 ? '0' : application.applicationSetupFee
                    }
                  />
                )}
                <KeyValue
                  title={'Commitment Amount'}
                  description={
                    application.commitmentAmount === 0 ? '0' : application.commitmentAmount
                  }
                  amount={currencyConversion(
                    application.commitmentAmount,
                    application.plan?.currency
                  )}
                />
                {/* <KeyValue
                  title={'Wish To Lower the Commitment Amount'}
                  description={booleanToYesNo(application.doNotWishToValidateCommitmentAmount)}
                /> */}
                <KeyValue
                  title={'Management Fee % (p.a.)'}
                  description={application.managementFeesRate}
                />
                {isApplicableField(application?.placementFeeApplicable || '') && (
                  <KeyValue title={'Placement Fee %'} description={application?.placementFee} />
                )}
                <KeyValue title={'Operating Expense'} description={application?.operatingExpense} />
                {/* <KeyValue title={'Tax Rate'} description={application.taxRate} /> */}
                {/* <KeyValue title={'DrawDown Number'} description={application.drawDownNumber} /> */}
                {/* <KeyValue
                  title={'Contribution Amount'}
                  description={
                    application.contributionChequeAmount === 0
                      ? '0'
                      : application.contributionChequeAmount
                  }
                  amount={currencyConversion(
                    application.contributionChequeAmount,
                    application.plan?.currency
                  )}
                /> */}
                {/*  <KeyValue
                  title={'Setup Fee Amount'}
                  description={application.setupFeeAmount}
                  amount={currencyConversion(
                    application.setupFeeAmount,
                    application.plan?.currency
                  )}
                />
                <KeyValue
                  title={'GST (GST On Setup Fee Amount)'}
                  description={application.setupFeeGST}
                  amount={currencyConversion(application.setupFeeGST, application.plan?.currency)}
                />
                <KeyValue
                  title={'Total Setup Fee'}
                  description={application.totalSetupFee}
                  amount={currencyConversion(application.totalSetupFee, application.plan?.currency)}
                />
                <KeyValue
                  title={'TDS'}
                  description={application.setupFeeTDS}
                  amount={currencyConversion(application.setupFeeTDS, application.plan?.currency)}
                />
                <KeyValue
                  title={'Setup Fee Payable'}
                  description={currencyConversion(application.netSetupFeeLessTDS)}
                />*/}
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          {/* <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Payment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Payment Bank Name'} description={application.paymentBankame} />
                <KeyValue
                  title={'Payment Bank Account Number'}
                  description={application.paymentBankAccountNumber}
                />
                <KeyValue
                  title={'Payment Bank Account Type'}
                  description={application.paymentBankAccontType}
                />
                <KeyValue title={'Mode Of Payment'} description={application.modeOfPayment} />
                <KeyValue
                  title={'Payment/Cheque Date'}
                  description={formatShortDate(application.chequeDate)}
                />
                <KeyValue title={'Payment Ref No'} description={application.paymentRefNo} />
              </Grid>
            </Gridstyles>
          </Grid> */}
        </Box>
      </Box>
    </>
  );
}
