import {
  Button,
  Dialog,
  Hidden,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import { DataTable } from '../DataTable';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { accessType, ClassPlanProps, FundProps } from '../../redux-store/types/funds';
import { Location } from 'history';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fundAccess, getPlanList, updatePlan } from '../../redux-store/actions/funds';
import { ConfirmationDialog } from '../commonComponents';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { currencyConversion } from '../../utils/utilityFunctions';
import { Item } from '../ApplicationDetails';

export const AccessHeaderForPlan = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 14,
            color: 'primary.main',
            fontWeight: 500,
          }}
          component="span">
          Access
          <InfoOutlinedIcon
            color="info"
            fontSize="small"
            onClick={handleOpen}
            sx={{ position: 'relative', top: 4 }}
          />
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 12,
            color: 'primary.main',
            fontWeight: 500,
          }}>
          Enable/Disable
        </Typography>
      </Box>

      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            maxWidth: 550,
            p: { xs: 2, sm: '15px 20px 45px' },
            borderRadius: '10px',
            height: 'unset',
            overflowY: 'auto',
          },
        }}>
        <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        <Box sx={{ mb: 2, textAlign: 'center', '.MuiSvgIcon-root': { fontSize: '3.1875rem' } }}>
          <InfoOutlinedIcon color="info" fontSize="large" />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
              textAlign: 'center',
            },
          }}>
          <Typography sx={{ color: 'secondary.main', lineHeight: '35px', mt: 2 }}>
            Please ensure that you have at least one active fund before disabling the Plan.
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default function ViewPlans({
  location,
}: {
  location: Location<{ fundId: string; PlanId: string }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [plan, setPlan] = useState<FundProps[]>();
  const [access, setAccess] = useState(false);
  const [openDialog, setDialog] = useState<{ planData: FundProps } | null>(null);
  const { fundId, PlanId } = location.state || {};

  const history = useHistory();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (fundId) {
          const res = (await dispatch(getPlanList(fundId))) as unknown as any;
          const { plans } = res;
          setPlan(plans);
        }

        if (!isComponentActive) {
          return;
        }
        setAccess(fundSetupAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const AccessViewOrEditCell = [
    {
      header: AccessHeaderForPlan,
      renderCell: (row: FundProps): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ planData: row })}
                disabled={!access}
              />
            </Stack>
          </>
        );
      },
    },
    // {
    //   header: '',

    //   renderCell: (row: FundProps): JSX.Element => {
    //     return (
    //       <>
    //         <Typography
    //           sx={{
    //             display: 'flex',
    //             flexDirection: 'row',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //           }}>
    //           <Typography
    //             component={RouterLink}
    //             to={{ pathname: 'plan-details', state: { PlanId: row.id, fundId: fundId } }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         </Typography>
    //       </>
    //     );
    //   },
    // },
  ];

  const columns: any = [
    {
      header: 'Plan Code',
      renderCell: (row: ClassPlanProps) => {
        return (
          <Typography
            variant="h5"
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
              px: 2,
            }}
            component={RouterLink}
            to={{ pathname: 'plan-details', state: { PlanId: row.id, fundId: fundId } }}>
            {row.planCode || 'N/A'}
          </Typography>
        );
      },
    },
    {
      header: 'Plan Description',
      label: 'planDescription',
      valueGetter: (row: ClassPlanProps) => row.planDescription || 'N/A',
    },
    {
      header: 'Setup Fees % (One-time)',
      label: 'setupFee',
      valueGetter: (row: ClassPlanProps) => row.setupFeeValues.join(', ') || 'N/A',
    },
    {
      header: 'Minimum Commitment Amount',
      renderCell: (row: ClassPlanProps) => {
        return (
          <>
            <Typography
              variant="h5"
              sx={{
                textDecoration: 'none',
                fontSize: '14px',
                px: { lg: 2 },
              }}>
              {row.minCommitmentAmount || 'N/A'}
            </Typography>
            <Item sx={{ pt: 0, fontSize: { xs: '10px', md: '14px' } }}>
              {currencyConversion(Number(row.minCommitmentAmount), row?.currency)}
            </Item>
          </>
        );
      },
    },
  ];

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <Box
          sx={{
            display: 'flex',
            // justifyContent: 'space-between',
            // pt: { xs: 2, sm: 7 },
            // pb: { xs: 2, sm: 4 },
            // display: 'flex',
            alignItems: { xs: 'flex-start' },
            flexDirection: { lg: 'row', xs: 'column' },
            justifyContent: { lg: 'space-between' },
            ml: { xs: 1 },
            pt: { xs: 2, sm: 4 },
            mr: { xs: 2 },
            pb: { lg: 2 },
          }}>
          <Hidden smUp={true}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('fund-list')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                }}>
                Back
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                my: 2,
                py: 2,
                width: '100%',
                pl: { xs: 3, sm: 0 },
                color: 'primary.main',
                bgcolor: 'rgba(238, 244, 251, 0.5)',
              }}>
              <PersonOutlineOutlinedIcon fontSize="large" />
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  pr: { xs: 5 },
                  pl: 1,
                }}>
                Fund Setup
              </Typography>
            </Box>
          </Hidden>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('fund-list')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                // pl: { xs: 3, sm: 0 },
              }}>
              {'Plans'}
            </Typography>
            {access && (
              <Hidden smUp={true}>
                <Button
                  variant="text"
                  startIcon={<AddIcon />}
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    fontSize: 13,
                    // mr: 2.5,
                    // py: 0.5,
                    // px: 3,
                    ml: 16,
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => history.push('add-plan', { fundId: fundId })}>
                  Add Plan
                </Button>
              </Hidden>
            )}
          </Box>
          {access && (
            <Hidden only="xs">
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 14,
                  mr: 2.5,
                  py: 0.5,
                  px: 3,
                }}
                onClick={() => history.push('add-plan', { fundId: fundId })}>
                Add Plan
              </Button>
            </Hidden>
          )}
        </Box>
        {useMemo(
          () => (
            <DataTable
              tableData={plan || []}
              tableHeader={[...columns, ...AccessViewOrEditCell]}
              currentPage={currentPage}
              noOfPages={1}
              isLoading={isLoading}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={{
                '.MuiTableCell-root': {
                  borderBottom: 'none',
                },
              }}
              tableHeaderCustomStyles={{
                '&:last-child th': { border: 0 },
                '.MuiTableCell-head': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'primary.main',
                },
              }}
            />
          ),
          [columns, isLoading]
        )}
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.planData.isActive ? 'disable' : 'enable'
        } the access to this Plan ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedRes = (await dispatch(
                updatePlan(Number(openDialog.planData.id), {
                  ...openDialog.planData,
                  isActive: !openDialog?.planData.isActive,
                })
              )) as unknown as FundProps;
              setPlan(
                plan?.map((plan) => {
                  if (Number(plan.id) === Number(updatedRes.id)) {
                    return {
                      ...plan,
                      ...updatedRes,
                    };
                  }
                  return plan;
                })
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}
