export const salutationsMasters: string[] = ['Mr.', 'Ms.', 'M/S.', 'Mrs.'];
export const applicantStatusMasters: { [key: string]: string } = {
  Individual: 'Individual (Resident)',
  NRI: 'Individual (NRI)',
};
export const nationalitiesMasters: string[] = ['Indian', 'Others'];
export const occupationDetailsMasters: string[] = [
  'PVT.SECTOR SERVICE',
  'PUBLIC SECTOR',
  'GOVT.SERVICE',
  'HOUSEWIFE',
  'DEFENCE',
  'PROFESSIONAL',
  'RETIRED',
  'BUSINESS',
  // 'AGRICULTURE',
  'STUDENT',
  // 'FOREX DEALER',
  'SENIOR CITIZEN',
  'OTHERS',
];

export const BusinessConstitutionMasters: { [key: string]: string } = {
  proprietorship: 'Proprietorship',
  limited_liability_partnership: 'Limited Liability Partnership',
  unlimited_liability_partnership: 'Unlimited Liability Partnership',
  private_ltd_company: 'Private Ltd. Company',
  public_company: 'Public Company',
  trust_association_society: 'Trust / Association / Society',
  non_profit_organization: 'Non Profit Organization',
  others: 'Others',
};

export const SourceOfFundMaster: { [key: string]: string } = {
  employment_salary: 'Employment / Salary',
  Business_consultancy_income: 'Business/Consultancy Income',
  dividend_interest: 'Dividend/Interest',
  donation_grant: 'Donation / Grant',
  pension_retirement_funds: 'Pension / Retirement Funds',
  shareholders_funds: 'Shareholders funds (for corporates)',
  reserves_retained_earnings: 'Reserves/Retained earnings (for corporates)',
  sales_of_assets: 'Sales of Assets / Property (other than securities)',
  sales_of_shares_securities_investment: 'Sale of Shares / Securities / Investment',
  gifts: 'Gift(s)',
  partners_members_funds: 'Partners/ Members funds (for partnership/other entities)',
  inheritance: 'Inheritance',
  others: 'Others',
};

export const grossAnnualMasters: string[] = [
  'BELOW 1 LAC',
  '1-5 LAC',
  '5-10 LACS',
  '10-25 LACS',
  '>25 LACS - 1 CRORE',
  '> 1 CRORE',
];

export const PEPsMasters = [
  { key: 'I am PEP', value: 'I am PEP' },
  { key: 'I am Related to PEP', value: 'I am Related to PEP' },
  { key: 'Not a PEP', value: 'Not Applicable' },
];

export const addressTypesMasters: string[] = [
  'Resident/Business',
  'Resident',
  'Business',
  'Registered Office',
  'Unspecified',
];

export const whetherRegisterMaster: { [key: string]: string } = {
  nbfc: 'NBFC',
  pension_fund: 'Pension Fund',
  insurance_compant: 'Insurance Company',
  provident_fund: 'Provident Fund',
  sebi_intermediary: 'SEBI Registered Intermediary',
  none_of_above: 'None of the Above',
};

export const genderMasters: string[] = ['Male', 'Female', 'Others'];

export const IdentityProofsMasters: string[] = [
  'Photo PAN Card',
  'Passport',
  "Voter's ID",
  'Driving License',
  'Aadhar Card',
];

export const AddressProofsMasters: string[] = [
  'Passport',
  "Voter's ID",
  'Ration Card',
  'Electricity Bill',
  'Landline/Telephone Bill',
  'Gas Bill',
  'Latest Bank Statement/Passbook',
  'Latest Depository Account Statement',
];

export const BankAccountProofsMasters: string[] = [
  'Bank Statement with IFSC printed on it',
  'Cancelled cheque leaf verified',
  'Letter from the bank giving account details',
  'Passbook (showing bank account details)',
];

export const DrawDownMasters: number[] = [1, 2, 3, 4];

export const filterApplicationTypes = [
  { key: 'individual', value: 'Individual (Resident)' },
  { key: 'non_individual', value: 'Non-Individual' },
  { key: 'poa', value: 'Individual (POA)' },
  { key: 'nri', value: 'Individual (NRI)' },
  { key: 'nri_poa', value: 'Individual (NRI-POA)' },
];

export const ModeOfHoldingTypes = [
  { key: 'single', value: 'Single' },
  { key: 'either or survivor,joint', value: 'Joint' },
];

export const IMAGE_UPLOAD_FORMATS = ['image/jpeg', 'application/pdf', 'image/png', 'image/jpg'];

export const INVESTOR_IMAGE_UPLOAD_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

export const APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_applicant1: 'Sent to 1st applicant',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
};

export const SUPPORT_STATUS: { [key: string]: string } = {
  sent_to_applicant1: 'Sent to 1st applicant',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_fundmanager: 'Approved by investment manager',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
};

export const POA_APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: 'Sent to AIF approver',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
  approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by trustee',
  invitationexpired: 'Invitation Expired',
  signed: 'Completed',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const ESIGN_STATUS: { [key: string]: string } = {
  NOT_GENERATED: 'notgenerated',
  PENDING_SIGNATURE: 'pendingsignature',
  SIGNED: 'signed',
  EXPIRED: 'expired',
};

export enum USER_ROLES {
  SUPER_ADMIN = 'superadmin',
  AMC_ADMIN = 'amcadmin',
  RM = 'rm',
  DISTRIBUTOR = 'distributor',
  INVESTOR = 'investor',
  FUND_MANAGER = 'fundmanager',
  TRUSTEE = 'trustee',
  WITNESS = 'witness',
  APPLICATION = 'application',
  SUBDISTRIBUTOR = 'subdistributor',
  POAAPPROVER = 'poaapprover',
  AMCAPPROVER = 'amcapprover',
  AUTHORISEDSIGNATORY = 'authorisedsignatory',
}

export const ApplicationStatusBasedOnRole: { [key: string]: { [key: string]: string } } = {
  [USER_ROLES.POAAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_poa_approver: 'Pending',
  },
  [USER_ROLES.AMCAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_amc_approver: 'Pending',
  },
};

export const Relations = ['Mother', 'Father', 'Daughter', 'Son', 'Spouse'];

export const NomineeRelations = [
  'MOTHER',
  'FATHER',
  'DAUGHTER',
  'SON',
  'SPOUSE',
  'BROTHER',
  'SISTER',
  'OTHERS',
];

export const adminSupportMaster: { [key: string]: string } = {
  unlock_user: 'Unlock User',
  check_whitelisted: 'Check Whitelisted',
  whitelist_user: 'Whitelist User',
  unwhitelist_user: 'Unwhitelist User',
  change_expired_status: 'Change Expired Status',
  change_status_to_draft: 'Change Status to draft',
};

export const IS_DOB_APPLICABLE_FOR_AMC = true;

export const REJECTED_ROLES: { [key: string]: string } = {
  poaapprover: 'POA Approver',
  amcapprover: 'AIF Approver',
};

export const investorTypeMasters: { [key: string]: string } = {
  trust: 'Trust',
  corporate: 'Company',
  partnership_firm: 'Partnership Firm (Including LLPs)',
  huf: 'HUF',
  bank: 'Bank',
  // govt_bodies: 'Army/ Government Bodies',
  // registered_society: 'Registered Society',
  // fii_fpi: 'FII/FPI',
  // institutional_investor: 'Institutional Investor',
  // unincorporated_or_body_of_individuals: 'Unincorporated association or a body of individuals',
  // foreign_entity: 'Foreign Entity',
};
export const IS_PENNYDROP_APPLICABLE_FOR_AMC = true;

export const getGroupNameMaster = ['Group-A', 'Group-B', 'Group-C'];

export const NON_INDIVIDUAL_APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_amc_approver: 'Sent to AIF approver',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
  approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by trustee',
  invitationexpired: 'Invitation Expired',
  signed: 'Completed',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const CpUboCodesMaster: { [key: string]: string } = {
  C01: '- CP of legal person-ownership',
  C02: '- CP of legal person-other means',
  C03: '- CP of legal person-senior managing official',
  C04: '- CP of legal arrangement-trust-settlor',
  C05: '- CP of legal arrangement-trust-trustee',
  C06: '- CP of legal arrangement-trust-protector',
  C07: '- CP of legal arrangement-trust-beneficiary',
  C08: '- CP of legal arrangement-trust-other',
  C09: '- CP of legal arrangement-trust-other-settlor-equivalent',
  C10: '- CP of legal arrangement-trust-other-trustee-equivalent',
  C11: '- CP of legal arrangement-trust-other-protector-equivalent',
  C12: '- CP of legal arrangement-trust-other-beneficiary-equivalent',
  C13: '- CP of legal arrangement-trust-other-other-equivalent',
  C14: '- Unknown',
};
export const controllingPersonsNationalitiesMasters: string[] = ['Indian', 'Others'];

//Application types
export enum APPLICATION_TYPE {
  INDIVIDUAL = 'individual',
  NON_INDIVIDUAL = 'non_individual',
}

export const AMC_APPROVER_CHECK_FOR_INDIVIDUAL = true; // this parameter is used to check application should be sent to approver first before investor

export const ENABLE_EMAIL_OTP = true;

export const APPLICATION_STATUS_AMCAPPROVER_individual: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_amc_approver: 'Sent to AIF approver',
  sent_to_applicant1: 'Sent to 1st applicant',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
};
export const IndividualApplicationStatusBasedOnRole: { [key: string]: { [key: string]: string } } =
  {
    [USER_ROLES.AMCAPPROVER]: {
      ...APPLICATION_STATUS_AMCAPPROVER_individual,
      sent_to_amc_approver: 'Pending',
    },
  };

export const APPLICATION_LISTING_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_applicant1: 'Sent to 1st applicant',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: 'Sent to AIF approver',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
};
export enum APPLICATION_TYPE_FOR_DOCUMENTS {
  INDIVIDUAL = 'individual',
  NON_INDIVIDUAL = 'non_individual',
  INDIVIDUAL_POA = 'individual_poa',
  NRI = 'nri',
  NRI_POA = 'nri_poa',
  NOMINEE_DOC = 'nominee',
  NOMINEE_GUARDIAN = 'nomineeGuardian',
}
export const BankAccountTypeMaster: { [key: string]: string } = {
  Current: 'CURRENT',
  Savings: 'SAVING',
};
export const NRIBankAccountTypeMaster: { [key: string]: string } = {
  NRE: 'NRE',
  NRO: 'NRO',
};
export const DistributorTypesForUserManageMent: { [key: string]: string } = {
  Individual: 'individual',
  Company: 'company',
};

export const ENABLE_KRA_DOCUMENT_FETCH = true;

export const SALT = 'F2lNZWFxUH';
export const SALT2 = 'WTVPJel6dTGVocF';

export const ONBOARDING_OPTIONS: { [key: string]: string } = {
  otp: 'OTP',
  email_consent: 'Email Consent',
};

export const EmailConsentStatusMaster: { [key: string]: string } = {
  pending: 'Pending',
  rejected: 'Rejected',
  approved: 'Approved',
  revoked: 'Revoked',
};

export const YesNoMaster: { [key: string]: string } = {
  yes: 'Yes',
  no: 'No',
};

export const ENABLE_Email_Consent = false;

export const TYPEOFEXCEPTION: { [key: string]: string } = {
  criminal_matter: 'Criminal matter',
  civil_matter: 'Civil matter',
  sebi_matter: 'SEBI matter',
  mca_matter: 'MCA matter',
  court_order: 'Court order',
  money_laundering: 'Money Laundering',
  rbi_matter: 'RBI matter',
  other_regulatory_matters: 'other Regulatory matters',
  other: 'Others',
};

export const RISK_PROFILE: { [key: string]: { value: string; disabled: boolean } } = {
  high: { value: 'HIGH', disabled: false },
  medium: { value: 'MEDIUM', disabled: true },
  low: { value: 'LOW', disabled: true },
};

export const fewFieldsInKYCDetailsAndInvestorSignatureDocumentValidationDate = '2023-06-09'; //Validation applicable date for making few Fields in KYC details and Investor Signature Document in document details mandatory

export const COMMENT_ROLES: { [key: string]: string } = {
  rm: 'RM',
  distributor: 'Distributor',
  subdistributor: 'Sub Distributor',
  amcapprover: 'AIF Approver',
  poaapprover: 'POA Approver',
};

export const reducedMinimumCommitmentamount = 0;
export const RoleTypes: { [key: string]: string } = {
  amc_rm: 'AMC',
  support_rm: 'Service',
};

export const subdistributorTypes: { [key: string]: string } = {
  direct: 'Distributor RM',
  support: 'CSE',
};

export const DLCLMasters: { [key: string]: string } = {
  cdsl: 'CDSL',
  nsdl: 'NSDL',
  // none: 'None',
};
export const CurrencyMaster: { [key: string]: { currency: string; localeCode: string } } = {
  inr: { currency: 'INR', localeCode: 'en-IN' },
  usd: { currency: 'USD', localeCode: 'en-US' },
};

export const defaultCurrency = 'inr';

export const MULTIPLE_SETUP_FEE = false; // this variable will activate multiple setup fees

export const DOCUMENT_ALLOW_PASTE = false;

export const fatherOrSpouseNameValidationDate = '2024-10-09';
