import Box from '@mui/material/Box';
import { Formik } from 'formik';
import * as yup from 'yup';
import { MFTextField, MFSubmitButton } from '../../lib/formik';
import { useDispatch } from 'react-redux';
import { loginWithOtp } from '../../redux-store/actions/auth';
import { useHistory } from 'react-router';
import { nonDigitRegex, phoneRegExp } from '../../utils/regex';
import { LoginWithOtpResponseBody } from '../../redux-store/types/api-types';
import { CountryCodesDropDown, LoginLayout } from '../commonComponents';
import { getCountryCodes, preventSpecialCharacters } from '../../utils/utilityFunctions';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';

interface Values {
  mobile: string;
  countryCode: string;
  countryNameAndCode: string;
}

const validationSchema = yup.object().shape({
  // mobile: yup
  //   .string()
  //   .required('Mobile number is required')
  //   .matches(phoneRegExp, 'Mobile number is not valid')
  //   .length(10, 'Mobile number should be maximum 10 digits'),
  mobile: yup.string().test('mobile', (value, context) => {
    const { createError, path, parent } = context;
    const codesList = getCountryCodes()
      .map((codes) => {
        if (codes.label === parent.countryNameAndCode) {
          return codes.countryCode;
        }
      })
      .filter((ele) => ele)
      .toString();
    if (!value) {
      return createError({
        message: 'Mobile number is required',
        path,
      });
    }
    if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
      return createError({
        message: 'Invalid Mobile number',
        path,
      });
    }
    if (!isValidPhoneNumber(value, codesList as CountryCode)) {
      return createError({
        message: 'Invalid Mobile number',
        path,
      });
    }
    return true;
  }),
  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
});

export default function MobileLogin(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = async (values: Values) => {
    try {
      const { mobile = '', countryCode = '+91', countryNameAndCode = 'India: +91' } = values;
      const res = (await dispatch(
        loginWithOtp({
          phone: mobile.trim(),
          countryCode: countryNameAndCode?.split(':')[1].trim(),
        })
      )) as unknown as LoginWithOtpResponseBody;
      history.push('verify-otp', {
        mobile,
        countryCode: countryNameAndCode?.split(':')[1].trim(),
        ...(res || {}),
      });
    } catch (e) {
      console.error((e as unknown as Error).message);
    }
  };
  const initialValues: Values = {
    mobile: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
  };

  return (
    <LoginLayout LoginText="Login">
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleSubmit, values }) => (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              '.MuiLoadingButton-root': {
                mt: 5,
              },
              '.MuiInputLabel-root': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 16,
                fontWeight: 500,
              },
            }}>
            <MFTextField
              label="Mobile Number"
              name="mobile"
              placeholder="Enter Mobile number"
              onKeyDown={(e) => {
                preventSpecialCharacters(e);
              }}
              startAdornment={
                <CountryCodesDropDown
                  name={'countryNameAndCode'}
                  value={values.countryNameAndCode}
                />
              }
              regexForFilterValue={nonDigitRegex}
              autoFocus
            />
            {/* <MFTextField
              required
              label="Enter Mobile Number"
              name="mobile"
              type="text"
              placeholder="Enter Mobile Number"
              autoComplete="off"
              autoFocus
            /> */}
            <MFSubmitButton label="Get OTP" />
          </Box>
        )}
      </Formik>
    </LoginLayout>
  );
}
