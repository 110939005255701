import { Grid, Link, SelectChangeEvent, Typography } from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { ProceedSaveLater, SubHeading } from '../investors/components';
import { MFTextField } from '../../lib/formik';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { Applicant } from '../../redux-store/types/api-types';
import {
  grossAnnualMasters,
  investorTypeMasters,
  PEPsMasters,
  RISK_PROFILE,
  USER_ROLES,
  whetherRegisterMaster,
} from '../../utils/constant';
import { DatePicker } from '../../lib/formik/DatePicker';
import React, { useEffect, useState } from 'react';
import {
  allowOnlyNumbers,
  applicationComparison,
  checkPmsPoa,
  // cleardpIdField,
  currencyConversion,
  maxNetworthDate,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { NonIndividualContributorValidationSchema } from '../../utils/schema';
import { useSnackbar } from 'notistack';
import UseRadioGroup from '../../lib/formik/Radio';
import { DematDetails } from '../investors/additionalKYCDetails';
import { nonDigitRegex } from '../../utils/regex';

const contributorObject: Partial<Applicant> = {
  name: '',
  cityOfIncorporation: '',
  ckycNo: '',
  ckycVerified: '',
  kraVerified: '',
  kraMobileNumber: '',
  kraEmailId: '',
  investorType: '',
  detailsOfFamilyCompany: '',
  registeredAs: '',
  registrationNumber: '',
  netWorthDate: '',
  dateOfBirth: '',
  panNo: '',
  grossAnnualIncome: '',
  netWorth: '',
  politicallyExposedPersonStatus: '',
  investmentExperienceInCapitalMarket: '',
  riskProfile: '',
  dlclId: '',
  dpId: '',
  clId: '',
  POADP: 'no',
};

export type Values = {
  saveType: string;
  modeOfHolding: string;
  applicants: Partial<Applicant>[];
};

const initialValues: Values = {
  modeOfHolding: 'single',
  applicants: [contributorObject],
  saveType: 'save and proceed',
};

export default function ContributorDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [contributorValues, setContributorValues] = useState(initialValues);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    try {
      const { applicants = [] } = application || {};
      setContributorValues({
        ...contributorValues,
        applicants: applicants.length
          ? applicants.map((applicant) => {
              return {
                ...applicant,
                dlclId: applicant.dlclId ? applicant.dlclId : '',
                POADP: applicant.POADP ? applicant.POADP : 'no',
              };
            })
          : [contributorObject],
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  }, [application]);

  const handleApplicant = async (values: Values) => {
    const { saveType, applicants = [] } = values;
    const { id, currentStep, banks = [] } = application || {};
    // const isAllBanksPennyChecked = banks.length
    //   ? banks.every((bank) => bank.pennydropCheck)
    //   : false;
    // const contributorDetailsFieldsCheck = application?.applicants
    //   .map((applicant, index) => {
    //     return applicants.map((_applicant, ind) => {
    //       if (index === ind) {
    //         const { investorType } = _applicant;
    //         if (investorType !== applicant.investorType) {
    //           return 'true';
    //         }
    //         return 'false';
    //       }
    //       return 'false';
    //     });
    //   })
    //   .flat();
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants
          .map((applicant) => ({
            ...applicant,
            name: removeSingleQuote(applicant.name),
            cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
          }))
          ?.sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        banks:
          values.applicants[0].name?.split("'").join('').trim() ===
          application?.applicants[0].name?.split("'").join('').trim()
            ? banks
            : banks.map((bank) => ({
                ...bank,
                pennydropCheck: false,
                pennydropVerified: false,
              })),
        applicants: values.applicants
          .map((applicant) => ({
            ...applicant,
            name: removeSingleQuote(applicant.name),
            cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
            clId: applicant.clId || '',
            // dpId: cleardpIdField(applicant.dlclId || '') ? '' : applicant.dpId,
            dpId: applicant.dpId || '',
          }))
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        modeOfHolding: 'single',
        currentStep:
          !!currentStep && currentStep > 1
            ? currentStep
            : !checkPmsPoa(values.applicants)
            ? 1
            : Number(currentStep) + 1,
      }
    );

    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            ...application,
            banks:
              values.applicants[0].name?.split("'").join('').trim() ===
              application?.applicants[0].name?.split("'").join('').trim()
                ? banks
                : banks.map((bank) => ({
                    ...bank,
                    pennydropCheck: false,
                    pennydropVerified: false,
                  })),
            applicants: values.applicants.map((applicant) => ({
              ...applicant,
              name: removeSingleQuote(applicant.name),
              cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
              clId: (applicant.clId as string) || '',
              // dpId: cleardpIdField(applicant.dlclId || '') ? '' : applicant.dpId,
              dpId: applicant.dpId || '',
            })),
            modeOfHolding: 'single',
            currentStep: !checkPmsPoa(values.applicants) ? 1 : 2,
            // !!currentStep && currentStep > 1
            //   ? (values.applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //       isAllBanksPennyChecked) ||
            //     (contributorDetailsFieldsCheck as unknown as string[])?.includes('true')
            //     ? 2
            //     : currentStep
            //   : (values.applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //       isAllBanksPennyChecked) ||
            //     (contributorDetailsFieldsCheck as unknown as string[])?.includes('true')
            //   ? 2
            //   : Number(currentStep) + 1,
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
    } else if (checkApplication) {
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(
          `Application ${application?.applicationNumber} - ` + ' Saved successfully',
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        );
      }
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      const { id, applicant1ReferenceId = '', applicationNumber } = application || {};
      const { saveType } = values;
      await handleApplicant(values);
      saveType === 'save and proceed'
        ? history.push('contact-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
      //history.push(`document-details`, { applicationType: 'non_individual' });
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={contributorValues}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, NonIndividualContributorValidationSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues, setFieldValue }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            //columnSpacing={5}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            {values.applicants.map((applicant, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.name`}
                    label="Name of Entity *"
                    placeholder="Enter Name of Entity"
                    disabled={applicant.dataFetchedFromKRA || false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Date of Registration/Incorporation *'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'DD/MM/YYYY'}
                    name={`applicants.${index}.dateOfBirth`}
                    disabled={applicant.dataFetchedFromKRA || false}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.cityOfIncorporation`}
                    label="Place of Registration/Incorporation *"
                    placeholder="Enter Place of Registration/Incorporation"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.ckycNo`}
                    label="CKYC No./CKYC Acknowledge No./KIN No"
                    placeholder="Enter CKYC No./CKYC Acknowledge No./KIN No"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.investorType`}
                    label="Investor Type *"
                    items={Object.keys(investorTypeMasters).map((investor) => ({
                      key: investorTypeMasters[investor],
                      value: investor,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.panNo`}
                    label="PAN *"
                    placeholder="Enter PAN"
                    disabled={true}
                  />
                </Grid>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Other Details
                </SubHeading>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.grossAnnualIncome`}
                    label="Gross Annual Income"
                    items={grossAnnualMasters.map((grossIncome) => ({
                      key: grossIncome,
                      value: grossIncome,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.netWorth`}
                    label="Net Worth"
                    placeholder="Enter Net Worth"
                  />
                  {values.applicants[index].netWorth &&
                    !isNaN(Number(values.applicants[index].netWorth)) && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.applicants[index].netWorth)}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Net worth as on'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'Enter Net worth as on'}
                    name={`applicants.${index}.netWorthDate`}
                    maxDate={maxNetworthDate()}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.politicallyExposedPersonStatus`}
                    label="Politically Exposed Person (PEP) Status"
                    items={PEPsMasters.map((pep) => ({ key: pep.key, value: pep.value }))}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.detailsOfFamilyCompany`}
                    label="Details of Investor Family/group company"
                    placeholder="Enter Details of Investor Family/group company"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.registeredAs`}
                    label=" Whether Registered as NBFC/Pension Fund/Insurance Company/Provident Fund/SEBI Registered Intermediary *"
                    items={Object.keys(whetherRegisterMaster).map((item) => ({
                      key: whetherRegisterMaster[item],
                      value: item,
                    }))}
                    onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                      setFieldValue(`applicants.${index}.registeredAs`, value);
                      setFieldValue(`applicants.${index}.registrationNumber`, '');
                    }}
                    applyLabelStyles={true}
                    reduceFontSize={true}
                  />
                </Grid>
                {!values.applicants[index].registeredAs ||
                  (whetherRegisterMaster[values.applicants[index].registeredAs || ''] !==
                    whetherRegisterMaster.none_of_above && (
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`applicants.${index}.registrationNumber`}
                        label="Registration Number *"
                        placeholder="Enter Registration Number"
                      />
                    </Grid>
                  ))}
                {/* <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.ckycVerified`}
                    label="CKYC Verified"
                    placeholder="Enter CKYC Verified"
                    disabled={true}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.kraVerified`}
                    label="CVL KRA Verified"
                    placeholder="Enter CVL KRA Verified"
                    disabled={true}
                  />
                </Grid>
                {applicant.kraMobileNumber && (
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`applicants.${index}.kraMobileNumber`}
                      label={`KRA Mobile Number`}
                      placeholder="Enter KRA Mobile Number"
                      disabled={true}
                      regexForFilterValue={nonDigitRegex}
                    />
                  </Grid>
                )}
                {applicant.kraEmailId && (
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`applicants.${index}.kraEmailId`}
                      label={`KRA Email Id`}
                      placeholder="Enter KRA Email Id"
                      disabled={true}
                    />
                  </Grid>
                )}

                <DematDetails values={values} index={index} disabled={false} />
              </React.Fragment>
            ))}
            <ProceedSaveLater
              showSaveAndProceed={checkPmsPoa(values.applicants)}
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
        </>
      )}
    </Formik>
  );
}
