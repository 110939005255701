import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import MFSelectField from '../../lib/formik/SelectField';
import { Formik, useFormikContext } from 'formik';
import {
  getDistributorById,
  getDistributorRMbyId,
  getRMsList,
} from '../../redux-store/actions/userManagement';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  SubDistributorListType,
  DistributorRM,
  AdddistributorRM,
  RmsType,
  newRmType,
  DistributorListType,
} from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addDistributorRM,
  updateDistributorRM,
  getDistributorsList,
} from '../../redux-store/actions/userManagement';
import { DistributorRmSchema } from '../../utils/schema';
import { Location } from 'history';
import { useEffect, useState } from 'react';
import { CountryCodesDropDown } from '../commonComponents';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { subdistributorTypes } from '../../utils/constant';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import { nonDigitRegex } from '../../utils/regex';

export default function AddDistributorRm({
  location,
}: {
  location: Location<{ distributorId: number; subDistributorId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { distributorId: ID, subDistributorId } = location.state || {};
  const [formikValues, setFormikValues] = useState<SubDistributorListType | null>();
  const [selectedRM, setSelectRm] = useState<RmsType[]>([]);
  const [loading, setLoading] = useState(false);
  const [direct, setDirect] = useState<SubDistributorListType[]>([]);
  const [support, setSupport] = useState<SubDistributorListType[]>([]);

  const initialValues: AdddistributorRM = {
    name: '',
    email: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
    phone: '',
    panNumber: '',
    distributors_RM_Code: '',
    subdistributorType: '',
    supportSubdistributorId: null,
    directSubdistributorIds: null,
    rmId: null,
    distributorId: ID,
    pincode: '',
    buildingNo: '',
    streetName: '',
    city: '',
    state: '',
    country: '',
    isActive: true,
    alternativeMobileNumber: '',
  };
  const [distributorRmDetails, setDistributorRmDetails] = useState(initialValues);

  // useEffect(() => {
  //   let isComponentActive = true;
  //   (async function () {
  //     try {
  //       if (!formikValues) return;
  //       if (SubDistributorListType) {
  //         await dispatch(updateDistributorRM(id, formikValues));
  //       } else {
  //         await dispatch(addDistributorRM({ ...formikValues, distributorId: ID }));
  //       }

  //       // const res = (await dispatch(
  //       //   getDistributorsList({ type: 'company' })
  //       // )) as unknown as DistributorListType[];

  //       // const ab = res.filter((a) => {
  //       //   return a.id === Number(distributorId);
  //       // });

  //       if (!isComponentActive) return;
  //       history.push('distributors-rm', { distributorId: ID });
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     isComponentActive = false;
  //   };
  // }, [formikValues]);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(getRMsList({ isActive: true }))) as unknown as newRmType;
        const distributorRes = (await dispatch(
          getDistributorById(ID)
        )) as unknown as DistributorListType;
        setDirect(
          distributorRes.subdistributors.filter(
            (ele) => subdistributorTypes[ele.subdistributorType] === subdistributorTypes.direct
          )
        );
        setSupport(
          distributorRes.subdistributors.filter(
            (ele) => subdistributorTypes[ele.subdistributorType] === subdistributorTypes.support
          )
        );
        if (subDistributorId) {
          const getDistributorRmDetails = (await dispatch(
            getDistributorRMbyId(subDistributorId)
          )) as unknown as SubDistributorListType;
          const {
            user,
            panNumber,
            distributors_RM_Code,
            rmId,
            subdistributorType,
            id,
            distributorId,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            isActive,
            directSubdistributorIds,
            alternativeMobileNumber,
            supportSubdistributorId,
          } = getDistributorRmDetails;
          const { firstName, countryCode, countryNameAndCode, phone, email } = user || {};
          setDistributorRmDetails({
            ...distributorRmDetails,
            name: firstName,
            panNumber,
            distributors_RM_Code,
            subdistributorType,
            rmId,
            id,
            distributorId,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
            phone,
            email,
            isActive,
            alternativeMobileNumber,
            supportSubdistributorId,
            directSubdistributorIds: directSubdistributorIds,
          });
        }
        if (!isComponentActive) {
          return;
        }
        const { rms } = res || {};
        setSelectRm(rms);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentActive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const onSubmit = async (values: any) => {
    try {
      if (subDistributorId) {
        await dispatch(
          updateDistributorRM(subDistributorId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            supportSubdistributorId:
              subdistributorTypes[values.subdistributorType] === subdistributorTypes.direct
                ? values.supportSubdistributorId
                : null,
            directSubdistributorIds:
              subdistributorTypes[values.subdistributorType] === subdistributorTypes.support
                ? values.directSubdistributorIds
                : null,
          })
        );
        history.push('distributors-rm-details', {
          distributorId: values.distributorId,
          subDistributorId,
        });
      } else {
        await dispatch(
          addDistributorRM({
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            distributorId: ID,
            supportSubdistributorId:
              subdistributorTypes[values.subdistributorType] === subdistributorTypes.direct
                ? values.supportSubdistributorId
                : null,
            directSubdistributorIds:
              subdistributorTypes[values.subdistributorType] === subdistributorTypes.support
                ? values.directSubdistributorIds
                : null,
          })
        );
        history.push('distributors-rm', { distributorId: values.distributorId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={distributorRmDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={DistributorRmSchema}>
        {({ handleSubmit, values, setFieldValue, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    subDistributorId
                      ? history.push('distributors-rm-details', {
                          distributorId: values.distributorId,
                          subDistributorId,
                        })
                      : history.push('distributors-rm', {
                          distributorId: values.distributorId,
                        })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    subDistributorId
                      ? history.push('distributors-rm-details', {
                          distributorId: values.distributorId,
                          subDistributorId,
                        })
                      : history.push('distributors-rm', {
                          distributorId: values.distributorId,
                        })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {subDistributorId ? `Distributor's RM` : `Onboarding Distributor's RM`}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Distributor's RM name *"
                      placeholder="Enter Distributor's RM name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Contact Number *"
                      placeholder="Enter Contact Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      regexForFilterValue={nonDigitRegex}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="alternativeMobileNumber"
                      label="Alternate Mobile Number"
                      placeholder="Enter Alternate Mobile Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      regexForFilterValue={nonDigitRegex}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="panNumber"
                      label="PAN"
                      placeholder="Enter PAN"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue('panNumber', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="distributors_RM_Code"
                      label="Distributor's RM Code"
                      placeholder="Enter Distributor's RM Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="subdistributorType"
                      label="Distributor RM Type *"
                      items={Object.keys(subdistributorTypes).map((role) => ({
                        key: subdistributorTypes[role],
                        value: role,
                      }))}
                    />
                  </Grid>
                  {subdistributorTypes[values.subdistributorType] ===
                    subdistributorTypes.direct && (
                    <Grid item xs={12} sm={6} md={4}>
                      <SearchableSelect
                        name="supportSubdistributorId"
                        label="CSE Name"
                        items={support
                          .sort((support1, support2) =>
                            (support1.user['firstName'] || '')
                              .toString()
                              .localeCompare((support2.user['firstName'] || '').toString())
                          )
                          .map((item) => ({
                            key: item.user.firstName,
                            value: item.id,
                          }))}
                        searchFieldPlaceholder={'Search CSE Name'}
                      />
                    </Grid>
                  )}
                  {subdistributorTypes[values.subdistributorType] ===
                    subdistributorTypes.support && (
                    <Grid item xs={12} sm={6} md={4}>
                      <MultipleSelect
                        name="directSubdistributorIds"
                        label="Distributor RM Name(s)  *"
                        items={direct.map((item) => ({
                          key: String(item.id),
                          value: item.user.firstName,
                        }))}
                        renderText="Distributor RM"
                      />
                    </Grid>
                  )}
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <SearchableSelect
                      name="rmId"
                      label="AMC's RM Name *"
                      items={selectedRM
                        .sort((rm1, rm2) =>
                          (rm1['name'] || '')
                            .toString()
                            .localeCompare((rm2['name'] || '').toString())
                        )
                        .map((item) => ({
                          key: item.name,
                          value: item.id,
                        }))}
                      searchFieldPlaceholder={'Search RM'}
                      // onChange={({ target: { value } }) => {
                      //   setValues({
                      //     ...values,
                      //     rmId: value,
                      //   });
                      // }}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="pincode" label="Pincode" placeholder="Enter Pincode" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="buildingNo"
                      label="Building Number"
                      placeholder="Enter Building Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="streetName"
                      label="Street Name"
                      placeholder="Enter Street Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="state" label="State" placeholder="Enter State" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="city" label="City" placeholder="Enter City" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="country" label="Country" placeholder="Enter Country" />
                  </Grid>
                </Grid>
                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
