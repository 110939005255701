import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Hidden,
  LinearProgress,
  Button,
  SelectChangeEvent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  AddDistributorRequestBody,
  DistributorListType,
  newRmType,
  RmsType,
} from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addDistributor,
  editDistributor,
  getDistributorById,
  getDistributorDetailsById,
  getRMsList,
} from '../../redux-store/actions/userManagement';
import { DistributorSchema } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import { Location } from 'history';
import { ConfirmationDialog, CountryCodesDropDown } from '../commonComponents';
import { DistributorTypesForUserManageMent } from '../../utils/constant';
import { getFunds, getFundsList } from '../../redux-store/actions/funds';
import { ClassPlanProps, FundProps, GetFundsListResponseBody } from '../../redux-store/types/funds';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import { showError } from '../../redux-store/actions/auth';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { nonDigitRegex } from '../../utils/regex';

export default function AddDistributor({
  location,
}: {
  location: Location<{ typeOfDistributor: string; distributorId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  //const [formikValues, setFormikValues] = useState<AddDistributorRequestBody | null>();
  const { typeOfDistributor, distributorId } = location.state || {};
  const [rmDropDownMaster, setRmDropDownMaster] = useState<RmsType[]>([]);
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [planData, setPlanData] = useState<string[] | number[] | null>(null);

  type DistributorRequestBodyWithPlanIds = AddDistributorRequestBody & {
    schemeAndPlan: { schemeIds: string | number | null; planIds: string[] | number[] | null }[];
  };

  const initialValues: DistributorRequestBodyWithPlanIds = {
    name: '',
    email: '',
    countryNameAndCode: 'India: +91',
    countryCode: '+91',
    phone: '',
    panNumber: '',
    arnCode: '',
    pincode: '',
    buildingNo: '',
    streetName: '',
    city: '',
    state: '',
    type: typeOfDistributor || '',
    country: '',
    isActive: true,
    rmId: null,
    alternativeMobileNumber: '',
    schemeAndPlan: [
      {
        schemeIds: null,
        planIds: null,
      },
    ],
  };

  // useEffect(() => {
  //   let isComponentActive = true;
  //   (async function () {
  //     try {
  //       if (!formikValues) return;
  //       if (distributor) {
  //         await dispatch(editDistributor(id, formikValues));
  //       } else {
  //         await dispatch(addDistributor(formikValues));
  //       }

  //       if (!isComponentActive) return;
  //       history.push('distributors', { distributorType: formikValues.type });
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     isComponentActive = false;
  //   };
  // }, [formikValues]);

  const [distributorDetails, setDistributorDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [openDialog, setDialog] = useState<{
    index: string | number;
    msgText: string;
    length: number;
  } | null>(null);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const res = (await dispatch(getRMsList({ isActive: true }))) as unknown as newRmType;
        const response = (await dispatch(getFunds({ isActive: true }))) as unknown as FundProps[];
        setLoading(true);
        if (distributorId) {
          const getDistributorDetails = (await dispatch(
            getDistributorDetailsById(distributorId)
          )) as unknown as DistributorListType;
          const {
            name,
            panNumber,
            arnCode,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            id,
            type,
            rmId,
            isActive,
            alternativeMobileNumber,
            planDetails,
          } = getDistributorDetails;
          const { countryCode, countryNameAndCode, email, phone } = getDistributorDetails.user || {
            countryCode: '+91',
            countryNameAndCode: 'India: +91',
            email: '',
            phone: '',
          };
          const { schemes, planIds } = planDetails;
          const planIdsFromScheme = schemes
            .map((scheme) => scheme.plans.map((plan) => Number(plan.id)))
            .flat();
          const filteredPlanIds = planIds
            .map((id) => {
              if (!planIdsFromScheme.includes(Number(id))) {
                return id;
              }
              return;
            })
            .filter((ele) => ele);
          setPlanData(
            filteredPlanIds?.length > 0 ? (filteredPlanIds as string[] | number[]) : null
          );
          const finalSchemePlan = schemes.map((scheme) => {
            return {
              schemeIds: scheme.id,
              planIds: scheme.plans.map((plan) => plan.id),
            };
          });
          setDistributorDetails({
            ...distributorDetails,
            name,
            panNumber,
            arnCode,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            email,
            phone,
            id,
            type,
            rmId,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
            alternativeMobileNumber,
            schemeAndPlan:
              finalSchemePlan && finalSchemePlan.length > 0
                ? finalSchemePlan
                : [{ schemeIds: null, planIds: null }],
          });
        }
        if (!isComponentAlive) return;
        const { rms } = res || {};
        setRmDropDownMaster(rms);
        setFundsList(response);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);
  const onSubmit = async (values: DistributorRequestBodyWithPlanIds) => {
    const { schemeAndPlan, ...rest } = values;
    const planIDs = schemeAndPlan
      .map((_schemeAndPlan) => {
        if (_schemeAndPlan.planIds && _schemeAndPlan.planIds?.length > 0) {
          return _schemeAndPlan.planIds;
        }
        return;
      })
      .flat()
      .filter((ele) => ele);
    try {
      if (distributorId) {
        await dispatch(
          editDistributor(distributorId, {
            ...rest,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            planIds:
              planData && planIDs && planIDs?.length > 0 ? [...planIDs, ...planData] : planIDs,
          })
        );
        history.push('distributor-details', { distributorType: values.type, distributorId });
      } else {
        await dispatch(
          addDistributor({
            ...rest,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            planIds:
              planData && planIDs && planIDs?.length > 0
                ? ([...planIDs, ...planData] as string[] | number[])
                : (planIDs as string[] | number[] | null),
          })
        );
        history.push('distributors', { distributorType: values.type });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  const getClassPlanOptionsOrDetails = (selectedFundId: string | number | null) => {
    const selectedFund = fundsList.find(
      (f) => Number(f.id) === Number(selectedFundId)
    ) as FundProps;
    return (
      selectedFund?.plans?.map((plan) => ({ key: plan.id, value: plan.planDescription })) || []
    );
  };

  return (
    <>
      <Formik
        initialValues={distributorDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={DistributorSchema}>
        {({ handleSubmit, values, setFieldValue, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    distributorId
                      ? history.push(`distributor-details`, {
                          distributorType: values.type,
                          distributorId,
                        })
                      : history.push('distributors', { distributorType: values.type })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    distributorId
                      ? history.push(`distributor-details`, {
                          distributorType: values.type,
                          distributorId,
                        })
                      : history.push('distributors', { distributorType: values.type })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                  textTransform: 'capitalize',
                }}>
                {typeOfDistributor
                  ? 'Onboarding Distributor Details'
                  : `${values.type} Distributor Detail`}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                {typeOfDistributor && (
                  <>
                    <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFSelectField
                          name="type"
                          items={Object.keys(DistributorTypesForUserManageMent).map((_ele) => ({
                            key: _ele,
                            value: DistributorTypesForUserManageMent[_ele],
                          }))}
                          label="Distributor Type *"
                          onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                            setValues({
                              ...values,
                              ...initialValues,
                              type: value as unknown as string,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Distributor Name *"
                      placeholder="Enter Distributor Name"
                    />
                  </Grid>
                  {values.type === 'individual' && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="phone"
                          label="Contact Number *"
                          placeholder="Enter Contact Number"
                          // startAdornment={
                          //   <CountryCodesDropDown
                          //     name={`countryNameAndCode`}
                          //     value={values.countryNameAndCode}
                          //   />
                          // }
                          regexForFilterValue={nonDigitRegex}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="alternativeMobileNumber"
                          label="Alternate Mobile Number"
                          placeholder="Enter Alternate Mobile Number"
                          // startAdornment={
                          //   <CountryCodesDropDown
                          //     name={`countryNameAndCode`}
                          //     value={values.countryNameAndCode}
                          //   />
                          // }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="panNumber"
                      label="PAN"
                      placeholder="Enter Pan"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue('panNumber', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="arnCode"
                      label="ARN Code"
                      placeholder="Enter ARN Code"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue('arnCode', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  {/* {values.type === 'individual' && (
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField name="rmName" label="RM Name" placeholder="Enter RM Name" />
                      <SearchableSelect
                        name="rmId"
                        label="RM Name *"
                        items={rmDropDownMaster
                          .sort((rm1, rm2) =>
                            (rm1['name'] || '')
                              .toString()
                              .localeCompare((rm2['name'] || '').toString())
                          )
                          .map((item) => ({
                            key: item.name,
                            value: item.id,
                          }))}
                        searchFieldPlaceholder={'Search RM'}
                      />
                    </Grid>
                  )} */}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="pincode" label="Pincode" placeholder="Enter Pincode" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="buildingNo"
                      label="Building Number"
                      placeholder="Enter Building Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="streetName"
                      label="Street Name"
                      placeholder="Enter Street Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="state" label="State" placeholder="Enter State" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="city" label="City" placeholder="Enter City" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="country" label="Country" placeholder="Enter Country" />
                  </Grid>

                  {/* empty grid for proper alignment */}
                  <Grid xs={12}></Grid>

                  {values.schemeAndPlan.map((_schemPlan, index) => {
                    return (
                      <>
                        <Grid item xs={12} sm={8}>
                          <MFSelectField
                            name={`schemeAndPlan.${index}.schemeIds`}
                            label="Applicable Scheme"
                            items={fundsList
                              .filter((funds) => {
                                const { schemeAndPlan } = values;
                                const selectedScheme = schemeAndPlan
                                  .filter(
                                    (fund, ind) =>
                                      ind !== index &&
                                      fund.schemeIds &&
                                      fundsList
                                        .map((_funds) => Number(_funds.id))
                                        .includes(Number(fund.schemeIds))
                                  )
                                  .map((_fund) => Number(_fund.schemeIds));
                                return !selectedScheme.includes(Number(funds.id)) && funds;
                              })
                              .map((fund) => ({
                                value: Number(fund.id),
                                key: fund.schemeName,
                              }))}
                            onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                              const updateScheme = values.schemeAndPlan.map((_schem, ind) => {
                                if (ind === index) {
                                  return {
                                    schemeIds: Number(value),
                                    planIds: null,
                                  };
                                }
                                return _schem;
                              });
                              setValues({ ...values, schemeAndPlan: updateScheme });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <Box
                            sx={{
                              display: { sm: 'flex' },
                              alignItems: 'baseline',
                            }}>
                            <MultipleSelect
                              name={`schemeAndPlan.${index}.planIds`}
                              label="Applicable Plans"
                              items={
                                getClassPlanOptionsOrDetails(_schemPlan.schemeIds) as [
                                  { key: string; value: string | number }
                                ]
                              }
                            />
                            {values.schemeAndPlan?.length > 1 ? (
                              <>
                                <Hidden only="xs">
                                  <IconButton
                                    sx={{
                                      p: 0,
                                      pl: 1,
                                      ':hover': {
                                        bgcolor: '#FFF',
                                      },
                                      '&.Mui-disabled': {
                                        cursor: 'pointer',
                                        pointerEvents: 'all',
                                      },
                                    }}
                                    onClick={() => {
                                      setDialog({
                                        index: index,
                                        msgText: 'delete',
                                        length: values.schemeAndPlan?.length,
                                      });
                                    }}>
                                    <DeleteOutlineIcon
                                      // color={row.deletable ? 'error' : 'disabled'}
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </Hidden>
                                <Hidden smUp={true}>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      color: 'white',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      mr: 2.5,
                                      py: 0.5,
                                      px: 3,
                                    }}
                                    onClick={() => {
                                      setDialog({
                                        index: index,
                                        msgText: 'delete',
                                        length: values.schemeAndPlan?.length,
                                      });
                                    }}>
                                    Delete
                                  </Button>
                                </Hidden>
                              </>
                            ) : (
                              values.schemeAndPlan?.length === 1 &&
                              values.schemeAndPlan[values.schemeAndPlan?.length - 1].schemeIds &&
                              values.schemeAndPlan[values.schemeAndPlan?.length - 1].planIds && (
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: 'white',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    py: 0.5,
                                    px: 3,
                                    ml: { lg: 2.5 },
                                  }}
                                  onClick={
                                    () =>
                                      setDialog({
                                        index: index,
                                        msgText: 'clear',
                                        length: values.schemeAndPlan?.length,
                                      })
                                    // setFieldValue('schemeAndPlan', [
                                    //   { schemeIds: null, planIds: null },
                                    // ])
                                  }>
                                  clear
                                </Button>
                              )
                            )}
                          </Box>
                        </Grid>
                      </>
                    );
                  })}
                  <Grid item xs={12} sm={8} md={6} sx={{ display: 'flex' }}>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      sx={{
                        color: 'white',
                        fontWeight: 500,
                        fontSize: 14,
                        mt: 2,
                        mr: 2.5,
                        py: 0.5,
                        px: 3,
                      }}
                      onClick={() => {
                        const { schemeIds, planIds } =
                          values.schemeAndPlan[values.schemeAndPlan?.length - 1];
                        try {
                          if (schemeIds === null || planIds === null || planIds?.length === 0) {
                            throw Error(
                              'Please choose applicable scheme and plans to add more applicable schemes and plans'
                            );
                          }
                          setFieldValue('schemeAndPlan', [
                            ...values.schemeAndPlan,
                            { schemeIds: null, planIds: null },
                          ]);
                        } catch (e) {
                          dispatch(showError((e as Error).message));
                        }
                      }}>
                      Add Scheme
                    </Button>
                  </Grid>
                </Grid>
                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
                <ConfirmationDialog
                  message={`Are you sure you want to ${openDialog?.msgText} Scheme and Plan ?`}
                  open={openDialog !== null}
                  setOpen={() => setDialog(null)}
                  onSave={() => {
                    setDialog(null);
                    if (openDialog && openDialog?.length === 1) {
                      setFieldValue('schemeAndPlan', [{ schemeIds: null, planIds: null }]);
                    }
                    if (openDialog && openDialog?.length > 1) {
                      const schemeAndPlanAfterDeleted = values.schemeAndPlan
                        .map((schemePlan, _ind) => {
                          if (_ind !== openDialog?.index) {
                            return schemePlan;
                          }
                          return;
                        })
                        .filter((ele) => ele);
                      setFieldValue('schemeAndPlan', schemeAndPlanAfterDeleted);
                    }
                  }}
                  onCancel={() => setDialog(null)}
                />
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
