import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNationalityList } from '../../redux-store/actions';
import { FatcaMdmsData } from '../../redux-store/actions/application';
import {
  Applicant,
  FatcaMdms,
  nonIndividualMdmsQuestionsFatca,
} from '../../redux-store/types/api-types';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { KeyValue } from '../ApplicationDetails/contributor';
import {
  AssignAnsAndVisibilityForInitialFatca,
  BorderedTextField,
  Group,
  OpenTextField,
  SectionHeader,
  SingleChoiceRadio,
} from '../NonIndividualInvestor/fatca';
import { SourceOfFundMaster } from '../../utils/constant';

interface Values {
  applicants: {
    fatcadetail: nonIndividualMdmsQuestionsFatca;
    sourceOfFundsLegitimacy: boolean | null;
    sourceOfFundsDeclaration: boolean | null;
    sourceOfFunds: string | null | undefined;
  }[];
}

export default function Fatca({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  const [fatcaData, setFatcaData] = useState<Values>();
  const dispatch = useDispatch();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(FatcaMdmsData())) as unknown as FatcaMdms;
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        if (response.fatca_questions) {
          const _applicants = {
            applicants: applicants.map((applicant) => {
              const {
                fatcadetail: existingFatcaDetails = null,
                sourceOfFundsDeclaration,
                sourceOfFundsLegitimacy,
                sourceOfFunds,
              } = applicant || {};
              return {
                fatcadetail: existingFatcaDetails
                  ? AssignAnsAndVisibilityForInitialFatca(
                      response.fatca_questions,
                      existingFatcaDetails
                    )
                  : [],
                sourceOfFundsDeclaration: sourceOfFundsDeclaration,
                sourceOfFundsLegitimacy: sourceOfFundsLegitimacy,
                sourceOfFunds: sourceOfFunds,
              };
            }),
          };
          setFatcaData(_applicants as Values);
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);
  return (
    <Box
      sx={{
        bgcolor: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
      }}
      mt={5}>
      <Box sx={{ p: 2 }}>
        <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
          <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 3 }}>
            FATCA/CRS Details
          </Typography>
          <Formik
            initialValues={fatcaData as Values}
            enableReinitialize={true}
            onSubmit={() => {
              console.log('this action should not be trigered');
            }}>
            {({ values }) => (
              <Grid
                container
                rowSpacing={1}
                //columnSpacing={5}
                sx={{
                  width: '100%',
                  ml: 0,
                  '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
                }}>
                {values?.applicants.map((applicant, idx) => (
                  <React.Fragment key={idx}>
                    {applicant.fatcadetail.length === 0 && (
                      <Grid
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'common.black',
                          textAlign: 'center',
                          my: 3,
                        }}>
                        No Fatca Details
                      </Grid>
                    )}
                    {applicant.fatcadetail.map((item) => (
                      <React.Fragment key={item.id}>
                        {item.isVisible &&
                          item.section_header &&
                          item.question_type !== 'group' && (
                            <SectionHeader value={item.section_header} />
                          )}
                        {item.isVisible && item.question_type === 'single_choice_radio' && (
                          <SingleChoiceRadio
                            data={item}
                            values={applicant.fatcadetail}
                            formValuesIndex={idx}
                            questionId={item.id}
                            disabled={true}
                          />
                        )}
                        {item.isVisible && item.question_type === 'group' && (
                          <Group
                            data={item}
                            formValuesIndex={idx}
                            values={applicant.fatcadetail}
                            _applicants={applicants}
                            disabled={true}
                            nationalityDropdown={mdmsCountriesList}
                          />
                        )}
                        {item.isVisible && item.question_type === 'open_text_field' && (
                          <OpenTextField
                            text={item.question_text}
                            applicantsIndex={idx}
                            questionId={item.id}
                            placeHolder={item?.placeholder || 'Please enter text here'}
                            isMandatory={item.isMandatory}
                            disabled={true}
                          />
                        )}
                        {item.isVisible && item.question_type === 'bordered_text_field' && (
                          <Grid item xs={12} sm={6} sx={{ pr: { xs: 0, sm: '30px' }, pt: 2 }}>
                            <BorderedTextField
                              applicantsIndex={idx}
                              questionId={item.id}
                              label={item.question_text}
                              placeHolder={item?.placeholder || 'Please enter text here'}
                              isMandatory={item.isMandatory}
                              disabled={true}
                            />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                    {applicant.sourceOfFundsDeclaration && (
                      <>
                        <Grid item xs={12} display="flex" alignItems={'flex-start'} my={2}>
                          <Checkbox defaultChecked disabled={true} />
                          <Typography
                            sx={{
                              fontSize: 15,
                              fontWeight: 700,
                              color: 'rgba(0, 0, 0, 0.6)',
                              letterSpacing: '0.5px',
                            }}>
                            {`I/we hereby confirm that the funds injected/invested have been
                          legitimately and legally obtained/earned and the derivation and use of the
                          funds do not contravene applicable anti-money laundering and terrorist
                          financing legislation.`}
                          </Typography>
                        </Grid>
                        <KeyValue
                          title={'Source Of Funds'}
                          description={
                            SourceOfFundMaster[applicant.sourceOfFunds || ''] ||
                            applicant.sourceOfFunds
                          }
                        />
                      </>
                    )}
                    {applicant.sourceOfFundsLegitimacy && (
                      <Grid item xs={12} display="flex" alignItems={'flex-start'} my={2}>
                        <Checkbox defaultChecked disabled={true} />
                        <Typography
                          sx={{
                            fontSize: 15,
                            fontWeight: 700,
                            color: 'rgba(0, 0, 0, 0.6)',
                            letterSpacing: '0.5px',
                          }}>
                          {`I/we hereby confirm that the aforementioned information is true and
                          correct. I/we will also provide documentary evidence of the source of
                          funds if / when required.`}
                        </Typography>
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            )}
          </Formik>
        </Grid>
      </Box>
    </Box>
  );
}
