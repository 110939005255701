import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Hidden,
  LinearProgress,
  Stack,
  Tooltip,
  Button,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { DatePicker } from '../../lib/formik/DatePicker';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import MFSelectField from '../../lib/formik/SelectField';
import { getFundManagersList, getTrustessList } from '../../redux-store/actions/userManagement';
import { DistributorListType, FundManager, Trustee } from '../../redux-store/types/api-types';
import {
  addFund,
  addPlan,
  fundAccess,
  getPlanList,
  updateFund,
} from '../../redux-store/actions/funds';
import { Location } from 'history';
import {
  accessType,
  addFundPlanType,
  addSetupFee,
  ClassPlanProps,
  FundProps,
} from '../../redux-store/types/funds';
import { addFundValidation, editFundValidation } from '../../utils/schema';
import {
  allowOnlyNumbers,
  currencyConversion,
  isApplicableField,
  isApplicableSetupfee,
} from '../../utils/utilityFunctions';
import {
  CurrencyMaster,
  MULTIPLE_SETUP_FEE,
  YesNoMaster,
  defaultCurrency,
} from '../../utils/constant';
import { getDistributorsListForFilters } from '../../redux-store/actions';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import MFCheckbox from '../../lib/formik/Checkbox';
import UseRadioGroup from '../../lib/formik/Radio';
import { newSetupFee, updatedSetupFee } from './addPlan';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ConfirmationDialog } from '../commonComponents';
import AddIcon from '@mui/icons-material/Add';
import { DataTable } from '../DataTable';

type NewPlanType = addFundPlanType & {
  isApplicableForDistributor: boolean;
};
const initialValues: NewPlanType = {
  schemeCode: '',
  schemeName: '',
  amcCode: '',
  bankName: '',
  bankBranch: '',
  ifscCode: '',
  bankAccountNumber: '',
  id: '',
  schemeId: null,
  planCode: '',
  planDescription: '',
  schemeNature: '',
  registrationNumber: '',
  schemeCategory: '',
  country: '',
  currency: defaultCurrency,
  schemeStartDate: `${new Date()}`,
  lockInPeriod: '',
  lockInExtension: '',
  inActive: true,
  faceValue: 0,
  fundAddress1: '',
  fundAddress2: '',
  fundAddress3: '',
  isActive: true,
  custodian: '',
  setupFee: 0,
  fundManagerId: null,
  trusteeId: null,
  minCommitmentAmount: null,
  exceptionMinCommitmentAmount: null,
  maxCommitmentAmount: 0,
  minContributionPercentage: 0,
  managementFee: 0,
  offeringExpenses: 0,
  tcLink: '',
  distributorIds: null,
  isApplicableForDirect: false,
  isApplicableForDistributor: false,
  setupFeeValues: [newSetupFee],
  setupFeeApplicable: 'yes',
  placementFeeApplicable: '',
  placementFee: null,
  operatingExpense: '',
};

export default function AddFund({
  location,
}: {
  location: Location<{ fundId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fundManager, setFundManager] = useState<any[]>([]);
  const [editField, setEditField] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { fundId } = location.state || {};
  const [access, setAccess] = useState(false);
  const [trustee, setTrustee] = useState<any[]>([]);
  const [distributorList, setDistributorList] = useState<DistributorListType[]>([]);
  const [setupFeeToDelete, setSetupFeeToDelete] = useState<{
    index: number;
  } | null>(null);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const trusteeList = (await dispatch(
          getTrustessList({ isActive: true })
        )) as unknown as Trustee[];
        const fundManagerList = (await dispatch(
          getFundManagersList({ isActive: true })
        )) as unknown as FundManager[];
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        setAccess(fundSetupAccess);
        setFundManager(fundManagerList);
        setTrustee(trusteeList);
        const res = (await dispatch(
          getDistributorsListForFilters({ isActive: true })
        )) as unknown as DistributorListType[];
        setDistributorList(res);

        if (fundId) {
          const GetFundDetail = (await dispatch(
            getPlanList(fundId.toString())
          )) as unknown as FundProps;
          const {
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
          } = GetFundDetail || {};

          setEditField({
            ...editField,
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: NewPlanType) => {
    try {
      const { schemeCode, schemeName, amcCode, bankName, bankBranch, ifscCode, bankAccountNumber } =
        values;

      const {
        planCode,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        setupFee,
        minCommitmentAmount,
        exceptionMinCommitmentAmount,
        maxCommitmentAmount,
        minContributionPercentage,
        managementFee,
        fundManagerId,
        trusteeId,
        tcLink,
        distributorIds,
        setupFeeValues,
        setupFeeApplicable,
        isApplicableForDirect,
        placementFeeApplicable,
        placementFee,
        operatingExpense,
      } = values;

      const setupFees = (setupFeeValues as addSetupFee[])
        .filter((obj) => obj.isActive)
        .map((ele) => ele.fee);
      if (fundId) {
        await dispatch(
          updateFund(fundId, {
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
          })
        );
      } else {
        const fundRes = (await dispatch(
          addFund({
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
          })
        )) as unknown as FundProps;

        await dispatch(
          addPlan({
            schemeId: fundRes.id,
            planCode,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            fundManagerId,
            trusteeId,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee: setupFee ? setupFee : 0,
            minCommitmentAmount,
            exceptionMinCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
            managementFee: managementFee ? managementFee : 0,
            tcLink,
            distributorIds,
            isApplicableForDirect,
            setupFeeApplicable,
            setupFeeValues: (isApplicableSetupfee(setupFeeApplicable) ? setupFees : []) as number[],
            placementFeeApplicable,
            placementFee:
              isApplicableField(placementFeeApplicable) && (placementFee || placementFee === 0)
                ? placementFee
                : null,
            operatingExpense,
          }) as unknown as ClassPlanProps
        );
      }
      history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  const columns: any = [
    {
      header: 'Setup Fees % (One-time)',
      label: 'setupFee',
      renderEditDeleteCell: (row: updatedSetupFee): JSX.Element => {
        return (
          <>
            <MFTextField
              name={`setupFeeValues.${row.sno}.fee`}
              placeholder="Enter Setup Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </>
        );
      },
    },
  ];

  const AccessViewOrEditCell = (setupFeeValuesLength: number) => [
    {
      header: '',
      renderCell: (row: updatedSetupFee): JSX.Element => {
        return (
          <>
            {setupFeeValuesLength > 1 && (
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'inline-flex',
                  maxWidth: '100%',
                  py: 1.5,
                  '& .MuiFormControl-root': { width: '100%', m: 0 },
                }}>
                <Tooltip title="Delete">
                  <IconButton
                    sx={{
                      ':hover': {
                        bgcolor: '#F4FCFC',
                      },
                    }}
                    onClick={() => {
                      setSetupFeeToDelete({ index: row.sno });
                    }}>
                    <DeleteOutlineIcon color="error" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Formik
        initialValues={editField}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={fundId ? editFundValidation : addFundValidation}>
        {({ handleSubmit, values, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {fundId ? 'Edit Fund' : 'Add Fund'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                      // backgroundColor: 'red',
                    }}>
                    {'Fund Details(Scheme)'}
                  </SubHeading>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeCode"
                      label="Scheme Code *"
                      placeholder="Enter Scheme Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeName"
                      label="Scheme Name *"
                      placeholder="Enter Scheme Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="amcCode" label="AMC Code" placeholder="Enter AMC Code" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="bankName" label="Bank Name" placeholder="Enter Bank Name" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="bankBranch"
                      label="Bank Branch"
                      placeholder="Enter Bank Branch"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="ifscCode" label="IFSC Code" placeholder="Enter IFSC Code" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="bankAccountNumber"
                      label="Bank Account Number"
                      placeholder="Enter Bank Account Number"
                    />
                  </Grid>
                </Grid>
                {!fundId && (
                  <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                    <SubHeading
                      sx={{
                        color: 'common.blue',
                        letterSpacing: 0.5 + 'px',
                        padding: { xs: '10px 10px', sm: '10px 30px' },
                        ml: { xs: 3.5 },
                      }}>
                      {'Plan Details'}
                    </SubHeading>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="planCode"
                        label="Plan Code *"
                        placeholder="Enter Plan Code"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="planDescription"
                        label="Plan Description *"
                        placeholder="Enter Plan Description"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="schemeNature"
                        label="Scheme Nature *"
                        placeholder="Enter Scheme Nature"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="registrationNumber"
                        label="Registration Number *"
                        placeholder="Enter Registration Number"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4}>
                      <MFSelectField
                        name="fundManagerId"
                        label="Investment Manager Name *"
                        items={fundManager.map((item) => ({
                          key: item.name,
                          value: item.id,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFSelectField
                        name="trusteeId"
                        label="Trustee Name *"
                        items={trustee.map((item) => ({
                          key: item.name,
                          value: item.id,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="schemeCategory"
                        label="Scheme Category *"
                        placeholder="Enter Scheme Category"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField name="country" label="Country" placeholder="Enter Country" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFSelectField
                        name="currency"
                        label="Currency"
                        items={Object.keys(CurrencyMaster).map((item) => ({
                          key: CurrencyMaster[item].currency,
                          value: item,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <DatePicker
                        label={'Scheme Start Date'}
                        inputLabelStyles={{
                          transform: 'unset',
                          fontSize: 14,
                          fontWeight: 500,

                          color: 'rgba(0,0,0,0.7)',
                        }}
                        placeholder={'Enter Scheme Start Date'}
                        name={`schemeStartDate`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="lockInPeriod"
                        label="Lock In Period(In Year)"
                        placeholder="Enter Lock In Period(In Year)"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="lockInExtension"
                        label="Lock In Extension(In Year)"
                        placeholder="Enter Lock In Extension(In Year)"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="faceValue"
                        label="Face value"
                        placeholder="Enter Face value"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="fundAddress1"
                        label="Fund Address 1"
                        placeholder="Enter Fund Address 1"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="fundAddress2"
                        label="Fund Address 2"
                        placeholder="Enter Fund Address 2"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="fundAddress3"
                        label="Fund Address 3"
                        placeholder="Enter Fund Address 3"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="custodian"
                        label="Custodian"
                        placeholder="Enter Custodian"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="setupFee"
                        label="Setup Fee"
                        placeholder="Enter Setup Fee"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="minCommitmentAmount"
                        label="Minimum Commitment Amount *"
                        placeholder="Enter Minimum Commitment Amount"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                      {values.minCommitmentAmount != 0 && (
                        <Typography sx={{ fontSize: 13 }}>
                          {currencyConversion(values.minCommitmentAmount, values.currency)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="exceptionMinCommitmentAmount"
                        label="Lower commitment Amount *"
                        placeholder="Enter Lower commitment Amount"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                      {values.exceptionMinCommitmentAmount != 0 && (
                        <Typography sx={{ fontSize: 13 }}>
                          {currencyConversion(values.exceptionMinCommitmentAmount, values.currency)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="maxCommitmentAmount"
                        label="Maximum Commitment Amount *"
                        placeholder="Enter Maximum Commitment Amount"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                      {values.maxCommitmentAmount != 0 && (
                        <Typography sx={{ fontSize: 13 }}>
                          {currencyConversion(values.maxCommitmentAmount, values.currency)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="minContributionPercentage"
                        label="Minimum Contribution Percentage"
                        placeholder="Enter Minimum Contribution Percentage"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="managementFee"
                        label="Management Fee % (p.a.)"
                        placeholder="Enter Management Fee"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="tcLink"
                        label={`T&C Link`}
                        placeholder={`Enter T&C Link`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
                      <UseRadioGroup
                        formLabel="Placement Fee Applicable *"
                        name={`placementFeeApplicable`}
                        items={Object.keys(YesNoMaster).map((ele) => ({
                          label: YesNoMaster[ele],
                          value: ele,
                        }))}
                      />
                    </Grid>
                    {isApplicableField(values.placementFeeApplicable) && (
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="placementFee"
                          label="Placement Fee % *"
                          placeholder="Enter Placement Fee"
                          type="number"
                          onKeyDown={(e) => {
                            allowOnlyNumbers(e);
                          }}
                          trimOnBlur={false}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="operatingExpense"
                        multiline
                        rows={4.5}
                        label={`Operating Expense *`}
                        placeholder={`Enter Operating Expense`}
                      />
                    </Grid>
                    {/* empty grid for proper alignment */}
                    <Grid xs={12}></Grid>
                    <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                      <Box sx={{ display: 'flex' }}>
                        <MFCheckbox name={`isApplicableForDirect`} label="Direct" />
                        <MFCheckbox
                          name={`isApplicableForDistributor`}
                          label="Distributor"
                          onChange={({ target: { checked } }) => {
                            setValues({
                              ...values,
                              isApplicableForDistributor: checked,
                              distributorIds: [],
                            });
                          }}
                          checked={values.isApplicableForDistributor}
                        />
                      </Box>
                      {values.isApplicableForDistributor && (
                        <MultipleSelect
                          name="distributorIds"
                          label="Applicable Distributor(s) *"
                          items={distributorList.map((item) => ({
                            key: item.id,
                            value: `${item.arnCode || 'N/A'} - ${item.name} (${item.type})`,
                          }))}
                          renderText="Selected Distributor(s)"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <UseRadioGroup
                        formLabel="Setup Fee Applicable *"
                        name={`setupFeeApplicable`}
                        items={Object.keys(YesNoMaster).map((ele) => ({
                          label: YesNoMaster[ele],
                          value: ele,
                        }))}
                      />
                    </Grid>
                    {isApplicableSetupfee(values.setupFeeApplicable) && (
                      <>
                        <Grid item xs={12} sm={12} md={12}>
                          <DataTable
                            tableData={(values.setupFeeValues as addSetupFee[]).filter(
                              (ele) => ele.isActive
                            )}
                            tableHeader={[
                              ...columns,
                              ...AccessViewOrEditCell(
                                (values.setupFeeValues as addSetupFee[]).filter((e) => e.isActive)
                                  .length
                              ),
                            ]}
                            noOfPages={1}
                            renderAdditionalRow={true}
                            tableBodyCustomStyles={{
                              '.MuiTableCell-root': {
                                borderBottom: 'none',
                              },
                            }}
                            tableHeaderCustomStyles={{
                              '.MuiTableCell-head': {
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: 14,
                                fontWeight: 500,
                                color: 'primary.main',
                                textAlign: 'left',
                                pl: 2,
                              },
                            }}
                            rowCustomStyles={{
                              '.MuiTableCell-root': {
                                py: 0,
                                overflowWrap: 'anywhere',
                                textAlign: 'left',
                                verticalAlign: 'top',
                              },
                            }}
                            boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                          />
                        </Grid>
                        {MULTIPLE_SETUP_FEE && (
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              startIcon={<AddIcon />}
                              sx={{
                                color: 'primary.main',
                                fontWeight: 500,
                                fontSize: 14,
                                mt: 2,
                              }}
                              onClick={() =>
                                setValues({
                                  ...values,
                                  setupFeeValues: [
                                    ...values.setupFeeValues,
                                    { ...newSetupFee, sno: values.setupFeeValues.length },
                                  ] as addSetupFee[],
                                })
                              }>
                              Add Setup Fee
                            </Button>
                          </Grid>
                        )}
                      </>
                    )}
                    <ConfirmationDialog
                      message={'Are you sure you want to delete setup fee ?'}
                      open={setupFeeToDelete !== null}
                      setOpen={() => {
                        setSetupFeeToDelete(null);
                      }}
                      onSave={() => {
                        const remainedSetupFee = (values.setupFeeValues as updatedSetupFee[]).map(
                          (obj) => {
                            if (obj.sno === setupFeeToDelete?.index) {
                              return {
                                ...obj,
                                isActive: false,
                              };
                            }
                            return obj;
                          }
                        );
                        setValues({
                          ...values,
                          setupFeeValues: remainedSetupFee,
                        });
                        setSetupFeeToDelete(null);
                      }}
                      onCancel={() => {
                        setSetupFeeToDelete(null);
                      }}
                    />
                  </Grid>
                )}
                {access && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
