import { Grid, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, validateYupSchema, yupToFormErrors, useFormikContext } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { Applicant } from '../../redux-store/types/api-types';
import {
  addressTypesMasters,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  genderMasters,
  USER_ROLES,
} from '../../utils/constant';
import {
  applicationComparison,
  applyRoleBasedStatus,
  getApplicantName,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory } from 'react-router';
import { updateApplication } from '../../redux-store/actions/application';
import { FATCAValidationSchema } from '../../utils/schema';
import { Notes } from './components';
import { useSnackbar } from 'notistack';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { getNationalityList } from '../../redux-store/actions';
import MFCheckbox from '../../lib/formik/Checkbox';
import { showError } from '../../redux-store/actions/auth';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { Link } from 'react-router-dom';

export type Values = {
  applicants: Partial<Applicant>[];
  saveType: string;
  countryDropdown: string[];
};

const initialValues: Values = {
  applicants: [
    {
      gender: '',
      genderFetchFromKra: false,
      typeOfAddressProvidedAtKRA: '',
      taxResidentOfAnyCountryOtherThanIndia: false,
      placeOfBirth: '',
      countryOfBirth: 'INDIA',
      countryOfNationality: 'INDIA',
      countryOfCitizenship: '',
      taxCountryName: '',
      taxID: '',
      idType: '',
      nameOfEntity: '',
      dateOfIncorporation: '',
      cityOfIncorporation: '',
      countryOfIncorporation: '',
      entityExcemptionCode: '',
      usPerson: '',
      usCitizenCheck: false,
      fundTaxOfferCheck: false,
      informationDeclaration: false,
      fundIntimateDeclaration: false,
      fundRegulatorsDeclaration: false,
      fatcaCrsDocumentCheck: false,
    },
  ],
  saveType: 'save and proceed',
  countryDropdown: [],
};

const FatcaDetails = ({
  applicantName,
  index,
  values,
  nationalitiesMdmsResponse,
  hasPOA,
}: {
  applicantName: string;
  index: number;
  values: Partial<Applicant>;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  hasPOA: boolean | undefined;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { setFieldValue } = useFormikContext();
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <>
      <SubHeading>FATCA/CRS Details of {applicantName} Applicant</SubHeading>
      {hasPOA && (
        <Grid item xs={12}>
          <MFCheckbox
            name={`applicants.${index}.fatcaCrsDocumentCheck`}
            label={'Wish to upload FATCA-CRS Declaration'}
            sx={{ my: 2 }}
            disabled={isFieldDisabled}
          />
        </Grid>
      )}
      {!values.fatcaCrsDocumentCheck && (
        <>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`applicants.${index}.gender`}
              label="Gender *"
              items={genderMasters.map((gender) => ({ key: gender, value: gender }))}
              disabled={values?.genderFetchFromKra || false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`applicants.${index}.typeOfAddressProvidedAtKRA`}
              label="Type of Address Provided At KRA *"
              items={addressTypesMasters.map((address) => ({ key: address, value: address }))}
            />
          </Grid>
          <Grid item xs={12}>
            <UseRadioGroup
              formLabel="Tax Resident of any country other than India *"
              name={`applicants.${index}.taxResidentOfAnyCountryOtherThanIndia`}
              items={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              value={values.taxResidentOfAnyCountryOtherThanIndia?.toString()}
              disabled={isFieldDisabled}
            />
          </Grid>
          {/* {applicant?.taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' && ( */}
          <>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.placeOfBirth`}
                label="Place of Birth *"
                placeholder="Enter Place of Birth"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <MFTextField
            name={`applicants.${index}.countryOfBirth`}
            label="Country of Birth *"
            placeholder="Enter Country of Birth"
            disabled={isFieldDisabled}
          /> */}
              <SearchableSelect
                name={`applicants.${index}.countryOfBirth`}
                label="Country of Birth *"
                items={nationalitiesMdmsResponse.map((nationality) => ({
                  key: nationality.name,
                  value: nationality.name,
                }))}
                disabled={isFieldDisabled}
                searchFieldPlaceholder={'Search Country'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <MFTextField
            name={`applicants.${index}.countryOfNationality`}
            label="Country of Nationality *"
            placeholder="Enter Country of Nationality"
            disabled={isFieldDisabled}
          /> */}
              <SearchableSelect
                name={`applicants.${index}.countryOfNationality`}
                label="Country of Nationality *"
                items={nationalitiesMdmsResponse.map((nationality) => ({
                  key: nationality.name,
                  value: nationality.name,
                }))}
                disabled={isFieldDisabled}
                searchFieldPlaceholder={'Search Country'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchableSelect
                name={`applicants.${index}.countryOfCitizenship`}
                label="Country of Citizenship *"
                items={nationalitiesMdmsResponse.map((citizenship) => ({
                  key: citizenship.name,
                  value: citizenship.name,
                }))}
                disabled={isFieldDisabled}
                searchFieldPlaceholder={'Search Country'}
              />
            </Grid>
            {values?.taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' && (
              <>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.taxCountryName`}
                    label="Tax country Name *"
                    placeholder="Enter Tax country Name"
                    disabled={isFieldDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.taxID`}
                    label="Tax ID *"
                    placeholder="Enter Tax ID"
                    disabled={isFieldDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.idType`}
                    label="ID Type *"
                    placeholder="Enter ID Type"
                    disabled={isFieldDisabled}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <UseRadioGroup
                formLabel="Whether US Person *"
                name={`applicants.${index}.usPerson`}
                items={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
                onChange={(event) => {
                  setFieldValue(`applicants.${index}.usPerson`, event.target.value);
                  setFieldValue(`applicants.${index}.usCitizenCheck`, false);
                }}
                disabled={isFieldDisabled}
              />
              {values?.usPerson?.toString() === 'No' && (
                <MFCheckbox
                  name={`applicants.${index}.usCitizenCheck`}
                  label={
                    'I confirm that I am neither a US citizen /person nor a resident for Tax purposes in the USA.'
                  }
                  sx={{ mt: 2 }}
                />
              )}
              <MFCheckbox
                name={`applicants.${index}.fundTaxOfferCheck`}
                label={
                  'I/We understand that, the Fund is relying on this information for the purpose of determining the status of the applicant named above in compliance with FATCA/CRS. The Fund is not able to offer any tax advice on CRS or FATCA or its impact on the applicant. I/we shall seek advice from a professional tax advisor for any tax questions.'
                }
                sx={{ mt: 2 }}
              />
              <MFCheckbox
                name={`applicants.${index}.informationDeclaration`}
                label={
                  'I/We certify that I/we provide the information on this form and to the best of my/our knowledge and belief the certification is true, correct, and complete including the taxpayer identification number of the applicant.'
                }
                sx={{ mt: 2 }}
              />
              <MFCheckbox
                name={`applicants.${index}.fundIntimateDeclaration`}
                label={
                  'I/We agree to intimate the Fund, within 30 days if any information or certification on this form becomes incorrect.'
                }
                sx={{ mt: 2 }}
              />
              <MFCheckbox
                name={`applicants.${index}.fundRegulatorsDeclaration`}
                label={
                  'I/We agree that as may be required by domestic regulators/tax authorities the Fund may also be required to report, reportable details to domestic regulators/tax authorities.'
                }
                sx={{ mt: 2 }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.nameOfEntity`}
              label="Name of Entity"
              placeholder="Enter Name of Entity"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Date of Incorporation"
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              maxDate={minDateForContributor()}
              name={`applicants.${index}.dateOfIncorporation`}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value={applicant.dateOfBirth || minDateForContributor().toString()}
              onChange={(newValue) => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant, ind) => {
                    if (index === ind) {
                      return {
                        ...applicant,
                        dateOfIncorporation: newValue?.toString(),
                      };
                    }
                    return applicant;
                  }),
                });
              }}
              textFieldStyles={{
                'label + &': {
                  mt: 4,
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #DDEAF3',
                },
                '& .MuiOutlinedInput-root': {
                  position: 'relative',
                  backgroundColor: 'common.white',
                  border: '1px solid #DDEAF3',
                  fontSize: 16,
                  '&:hover': {
                    borderColor: 'primary.main',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  '.MuiInputBase-input': {
                    p: '10px 12px',
                  },
                  '&:focus-visible': {
                    outline: 'none',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.cityOfIncorporation`}
              label="City of Incorporation"
              placeholder="Enter City of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.countryOfIncorporation`}
              label="Country of Incorporation"
              placeholder="Enter Country of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.entityExcemptionCode`}
              label="Entity Exemption Code"
              placeholder="Enter Entity Exemption Code"
            />
          </Grid> */}
          </>
          {/* )} */}
        </>
      )}
    </>
  );
};

export default function Fatca(): JSX.Element {
  const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  useEffect(() => {
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  useEffect(() => {
    const { applicants = [] } = application || {};
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        setFatcaDetails({
          ...fatcaDetails,
          applicants: applicants?.map((applicant) => ({
            gender: applicant.gender || '',
            genderFetchFromKra: applicant.genderFetchFromKra || null,
            typeOfAddressProvidedAtKRA: applicant.typeOfAddressProvidedAtKRA || '',
            taxResidentOfAnyCountryOtherThanIndia:
              applicant.taxResidentOfAnyCountryOtherThanIndia || false,
            placeOfBirth: applicant.placeOfBirth || '',
            countryOfBirth: applicant.countryOfBirth
              ? applicant.countryOfBirth.toUpperCase()
              : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
              ? ''
              : 'INDIA',
            countryOfNationality: applicant.countryOfNationality
              ? applicant.countryOfNationality.toUpperCase()
              : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
              ? ''
              : 'INDIA',
            countryOfCitizenship:
              (applicant.countryOfCitizenship && applicant.countryOfCitizenship.toUpperCase()) ||
              '',
            taxCountryName: applicant.taxCountryName || '',
            taxID: applicant.taxID || '',
            idType: applicant.idType || '',
            nameOfEntity: applicant.nameOfEntity || '',
            dateOfIncorporation: applicant.dateOfIncorporation || '',
            cityOfIncorporation: applicant.cityOfIncorporation || '',
            countryOfIncorporation: applicant.countryOfIncorporation || '',
            entityExcemptionCode: applicant.entityExcemptionCode || '',
            usPerson: applicant.usPerson || '',
            usCitizenCheck: applicant.usCitizenCheck || false,
            fundTaxOfferCheck: applicant.fundTaxOfferCheck || false,
            informationDeclaration: applicant.informationDeclaration || false,
            fundIntimateDeclaration: applicant.fundIntimateDeclaration || false,
            fundRegulatorsDeclaration: applicant.fundRegulatorsDeclaration || false,
            fatcaCrsDocumentCheck:
              applicant.fatcaCrsDocumentCheck === null && application?.hasPOA
                ? true
                : applicant.fatcaCrsDocumentCheck,
          })),
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        applicants: existingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { applicants, saveType } = values;
      const updatedPayload = existingApplicants.map((applicant, index: number) => {
        if (
          !applicants[index].fatcaCrsDocumentCheck &&
          ((applicants[index].usPerson === 'No' && applicants[index].usCitizenCheck === false) ||
            applicants[index].fundTaxOfferCheck === false ||
            applicants[index].informationDeclaration === false ||
            applicants[index].fundIntimateDeclaration === false ||
            applicants[index].fundRegulatorsDeclaration === false)
        ) {
          throw 'Declaration is required';
        }
        const {
          taxResidentOfAnyCountryOtherThanIndia = false,
          // dateOfBirth = '',
          dateOfIncorporation = '',
          ...rest
        } = applicants[index];
        if (applicants[index].fatcaCrsDocumentCheck) {
          return {
            ...applicant,
            typeOfAddressProvidedAtKRA: null,
            taxResidentOfAnyCountryOtherThanIndia: null,
            placeOfBirth: null,
            countryOfBirth: null,
            countryOfNationality: null,
            countryOfCitizenship: null,
            taxCountryName: '',
            taxID: '',
            idType: null,
            nameOfEntity: null,
            dateOfIncorporation: null,
            cityOfIncorporation: '',
            countryOfIncorporation: '',
            entityExcemptionCode: null,
            usPerson: null,
            usCitizenCheck: null,
            fundTaxOfferCheck: null,
            informationDeclaration: null,
            fundIntimateDeclaration: null,
            fundRegulatorsDeclaration: null,
            fatcaCrsDocumentCheck: applicants[index].fatcaCrsDocumentCheck,
          };
        } else {
          return {
            ...applicant,
            taxResidentOfAnyCountryOtherThanIndia:
              taxResidentOfAnyCountryOtherThanIndia?.toString() === 'false' ? false : true,
            // dateOfBirth: dateOfBirth ? dateOfBirth : null,
            dateOfIncorporation: dateOfIncorporation ? dateOfIncorporation : null,
            ...rest,
            placeOfBirth: removeSingleQuote(rest.placeOfBirth),
            taxCountryName: removeSingleQuote(rest.taxCountryName),
            taxID: removeSingleQuote(rest.taxID),
            idType: removeSingleQuote(rest.idType),
          };
        }
      });
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedPayload,
        currentStep: !!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status === 'sent_to_amc_approver' &&
                    hasPOA &&
                    USER_ROLES.SUBDISTRIBUTOR === role
                  ? 'draft'
                  : status,
              currentStep: 5,
              //!!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('nominee-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('nominee-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={fatcaDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, FATCAValidationSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'FATCA/CRS Information is mandatory to disclose any Foreign Citizenship'
            }
          />

          {values.applicants.map((applicant, index) => (
            <FatcaDetails
              applicantName={getApplicantName(index + 1)}
              index={index}
              key={index}
              values={applicant}
              nationalitiesMdmsResponse={mdmsCountriesList}
              hasPOA={application?.hasPOA}
            />
          ))}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
