import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { TYPEOFEXCEPTION } from '../../utils/constant';
import { Gridstyles } from '../Investments/investor-application';
import { KeyValue } from './contributor';

export default function WorldCheckScreening({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mb={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Status of World Check Screening
              </Typography>
            </Grid>

            <Gridstyles>
              <Grid
                item
                container
                pb={5}
                rowSpacing={1}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue
                  title={'Whether Negative Match Found'}
                  description={application.negativeMatchFound}
                />
                <KeyValue
                  title={'Details of any exception or negative match found'}
                  description={application.worldCheckScreeningStatus}
                />
                <KeyValue
                  title={'Type of Exception Found'}
                  description={
                    TYPEOFEXCEPTION[application.typeOfExceptionFound || ''] ||
                    application.typeOfExceptionFound
                  }
                />
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
