import { Box, Button, CircularProgress, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Distributor,
  getDistributors,
  getDistributorsListForFilters,
  getDistributorsResponse,
  getRMsList,
  SubDistributor,
} from '../../redux-store/actions';
import React, { useEffect, useState } from 'react';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  DistributorTypesForUserManageMent,
  USER_ROLES,
  subdistributorTypes,
} from '../../utils/constant';
import { InfoDialog, Notes } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { distributorsValidationSchema } from '../../utils/schema';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  applicationComparison,
  applyRoleBasedStatus,
  checkSubDisDisable,
  checkSupportLogin,
} from '../../utils/utilityFunctions';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import {
  DistributorListType,
  DistributorNewList,
  RMType,
  SubDistributorListType,
  SupportRmProp,
} from '../../redux-store/types/api-types';
import MFSelectField from '../../lib/formik/SelectField';
import { getDistributorById, getDistributorRMbyId } from '../../redux-store/actions/userManagement';
import { getDirectApplicableFunds } from '../../redux-store/actions/funds';
import { FundProps } from '../../redux-store/types/funds';
import { ConfirmationDialog } from '../commonComponents';
import { nonDigitRegex } from '../../utils/regex';

type DistributorDetailsProps = {
  distributorName: string | null;
  distributorId: number | null;
  loginType: string;
  firstName: string;
  empCode: string;
  email: string;
  subdistributorId: number | null;
  subdistributorName: string | null;
  subdistributorEmail: string | null;
  supportDistributorEmail: string | null;
  supportDistributorPhone: string | null;
  filledBy: string | null;
  distributorEmail: string | null;
  distributorPhone: string | null;
  distributorAlternatePhoneNumber: string | null;
  subdistributorPhone: string | null;
  subdistributorAlternatePhoneNumber: string | null;
  supportSubdistributorId: string | null | number;
  rm_id: number | null | undefined;
  brokerName?: string | null;
  phone: string | null;
};

function GridItem({ children }: { children: React.ReactNode }) {
  return (
    <Grid item xs={12} sm={6}>
      {children}
    </Grid>
  );
}

const CommenDetailsForRMDistributor = (): JSX.Element => {
  return (
    <GridItem>
      <MFTextField name="brokerName" label="Broker Name" placeholder="Enter Broker Name" />
    </GridItem>
  );
};

const RMDetail = ({
  isSupportRM,
  rmsList,
  values,
}: {
  isSupportRM: boolean;
  rmsList: any;
  values: any;
}) => {
  const rmsListSorting = [...rmsList].sort((a, b) => (a.name > b.name ? 1 : -1));
  return (
    <>
      <GridItem>
        <MFTextField name="firstName" label="RM Name" placeholder="Enter RM name" disabled={true} />
      </GridItem>
      <GridItem>
        <MFTextField
          name="email"
          label="RM Email ID"
          placeholder="Enter RM Email ID"
          disabled={true}
        />
      </GridItem>
      {isSupportRM && rmsList?.length > 0 && (
        <>
          <GridItem>
            <SearchableSelect
              name="rm_id"
              label="AMC's RM"
              items={rmsListSorting.map((item: SupportRmProp) => ({
                key: item.name,
                value: item.id,
              }))}
              searchFieldPlaceholder={'Search RM'}
            />
          </GridItem>
          {values.rm_id && (
            <>
              <GridItem>
                <MFTextField
                  name="email"
                  label="AMC's RM Email ID"
                  placeholder="Enter AMC's RM Email ID"
                  value={
                    rmsList.find((item: SupportRmProp) => item.id === values.rm_id)?.user?.email
                  }
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="phone"
                  label="AMC's RM Mobile Number"
                  placeholder="Enter AMC's RM Mobile Number"
                  value={
                    rmsList.find((item: SupportRmProp) => item.id === values.rm_id)?.user?.phone
                  }
                  disabled={true}
                  regexForFilterValue={nonDigitRegex}
                />
              </GridItem>
            </>
          )}
        </>
      )}
      <CommenDetailsForRMDistributor />
      {/* <GridItem>
        <MFTextField
          name="empCode"
          label="Employee Code"
          placeholder="Enter Employee Code"
          disabled={true}
        />
      </GridItem> */}
    </>
  );
};

const DistributorDetail = ({
  distributors,
  values,
  selectedDistributor,
  setSelectedDistributor,
  selectSupport,
  setSelectSupport,
}: {
  distributors: Distributor[];
  values: DistributorDetailsProps;
  selectedDistributor: SubDistributor[];
  setSelectedDistributor: (distributor: SubDistributor[]) => void;
  selectSupport: SupportRmProp[];
  setSelectSupport: (distributor: SupportRmProp[]) => void;
}): JSX.Element => {
  const { role } = useSelector((store: RootStateType) => store.auth);
  const { setValues } = useFormikContext();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { auth } = useSelector((store: RootStateType) => store);
  const [openDialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDialogOpen = () => {
    setDialog(true);
  };
  const handleDialogClose = () => {
    setDialog(false);
  };

  return (
    <>
      {distributors.length > 0 ? (
        <>
          {[USER_ROLES.RM].includes(role) && values.distributorId && (
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 14,
                  py: 0.5,
                  px: 3,
                }}
                onClick={handleDialogOpen}>
                clear
              </Button>
            </Grid>
          )}
          <GridItem>
            <SearchableSelect
              name="distributorId"
              label="Distributor Name *"
              items={distributors
                .sort((distributor1, distributor2) =>
                  (distributor1['name'] || '')
                    .toString()
                    .localeCompare((distributor2['name'] || '').toString())
                )
                .map((item) => ({
                  key: item.name,
                  value: item.id,
                }))}
              onChange={({ target: { value } }) => {
                const distributorCode = distributors.find((item) => item.id === value);
                const {
                  id = null,
                  name,
                  user,
                  alternativeMobileNumber = '',
                } = distributorCode || {};
                const { email, phone } = user || { email: '', phone: '' };
                setValues({
                  ...values,
                  distributorName: name,
                  distributorId: id,
                  subdistributorId: null,
                  subdistributorName: '',
                  subdistributorEmail: '',
                  supportDistributorEmail: '',
                  supportDistributorPhone: '',
                  distributorEmail: email,
                  distributorPhone: phone,
                  distributorAlternatePhoneNumber: alternativeMobileNumber || '',
                  subdistributorPhone: '',
                  subdistributorAlternatePhoneNumber: '',
                  supportSubdistributorId: null,
                });
                setSelectedDistributor(
                  distributors
                    ?.find((distributor) => distributor.id === value)
                    ?.subdistributors?.filter(
                      (ele) =>
                        subdistributorTypes[ele.subdistributorType || ''] ===
                        subdistributorTypes.direct
                    ) || []
                );
              }}
              disabled={[
                USER_ROLES.DISTRIBUTOR,
                USER_ROLES.INVESTOR,
                USER_ROLES.POAAPPROVER,
                USER_ROLES.SUBDISTRIBUTOR,
              ].includes(role)}
              searchFieldPlaceholder={'Search Distributor'}
            />
            {loading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress size={30} />
              </Box>
            )}
          </GridItem>
          {/* <GridItem>
            <MFTextField
              name="distributorName"
              label="Distributor Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem> */}
          {values.distributorEmail && values.distributorPhone && (
            <>
              <GridItem>
                <MFTextField
                  name="distributorEmail"
                  label="Distributor Email"
                  placeholder="Enter Distributor Email"
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="distributorPhone"
                  label="Distributor Mobile Number"
                  placeholder="Enter Distributor Mobile Number"
                  disabled={true}
                  regexForFilterValue={nonDigitRegex}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="distributorAlternatePhoneNumber"
                  label="Distributor Alternate Mobile Number"
                  placeholder="Enter Distributor Alternate Mobile Numberl"
                  disabled={true}
                  regexForFilterValue={nonDigitRegex}
                />
              </GridItem>
            </>
          )}
          {!!selectedDistributor?.length && (
            <>
              <GridItem>
                <SearchableSelect
                  name="subdistributorId"
                  label="Distributor's RM Name *"
                  items={selectedDistributor
                    .sort((subDistributor1, subDistributor2) =>
                      (subDistributor1.user['firstName'] || '')
                        .toString()
                        .localeCompare((subDistributor2.user['firstName'] || '').toString())
                    )
                    .map((item) => ({
                      key: item.user.firstName || 'N/A',
                      value: item.id,
                    }))}
                  onChange={async ({ target: { value } }) => {
                    try {
                      setLoading(true);
                      const getDistributorRmDetails = (await dispatch(
                        getDistributorRMbyId(Number(value))
                      )) as unknown as SubDistributorListType;
                      setSelectSupport(
                        (getDistributorRmDetails?.supportSubdistributorDetails
                          ?.supportSubdistributor as any) || []
                      );
                      const {
                        id = null,
                        user,
                        alternativeMobileNumber = '',
                        supportSubdistributorId = null,
                        supportSubdistributorDetails,
                      } = getDistributorRmDetails || {};
                      const { supportSubdistributor = [] } = supportSubdistributorDetails || {};
                      const { email, phone } = user || {};

                      const checkSupportPresent =
                        supportSubdistributorDetails.supportSubdistributorId;
                      setValues({
                        ...values,
                        subdistributorId: id,
                        subdistributorEmail: email || '',
                        subdistributorPhone: phone || '',
                        subdistributorAlternatePhoneNumber: alternativeMobileNumber || '',
                        supportDistributorEmail: !checkSupportPresent
                          ? ''
                          : !checkSupportLogin(
                              Number(auth.id),
                              Number(application?.supportSubdistributor?.user.id)
                            )
                          ? supportSubdistributor[0]?.user?.email
                          : application?.supportSubdistributor.user.email || '',
                        supportDistributorPhone: !checkSupportPresent
                          ? ''
                          : !checkSupportLogin(
                              Number(auth.id),
                              Number(application?.supportSubdistributor?.user.id)
                            )
                          ? supportSubdistributor[0]?.user?.phone
                          : application?.supportSubdistributor.user.phone || '',
                        supportSubdistributorId: !checkSupportPresent
                          ? null
                          : !checkSupportLogin(
                              Number(auth.id),
                              Number(application?.supportSubdistributor?.user.id)
                            )
                          ? supportSubdistributorId
                          : application?.supportSubdistributorId,
                      });
                      setLoading(false);
                    } catch (e: unknown) {
                      setLoading(false);
                      console.error((e as Error).message);
                    }
                  }}
                  disabled={checkSubDisDisable(
                    role,
                    Number(auth.id),
                    Number(application?.supportSubdistributor?.user.id)
                  )}
                  searchFieldPlaceholder={"Search Distributor's RM"}
                />
              </GridItem>
              {/* <GridItem>
            <MFTextField
              name="subdistributorName"
              label="Distributor's RM Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem> */}
              <GridItem>
                <MFTextField
                  name="subdistributorEmail"
                  label="Distributor's RM Email *"
                  placeholder="Enter Distributor email"
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="subdistributorPhone"
                  label="Distributor's RM Mobile Number"
                  placeholder="Enter Distributor's RM Mobile Number"
                  disabled={true}
                  regexForFilterValue={nonDigitRegex}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="subdistributorAlternatePhoneNumber"
                  label="Distributor's RM Alternate Mobile Number"
                  placeholder="Enter Distributor's RM Alternate Mobile Numberl"
                  disabled={true}
                  regexForFilterValue={nonDigitRegex}
                />
              </GridItem>
              {!checkSupportLogin(
                Number(auth.id),
                Number(application?.supportSubdistributor?.user.id)
              ) && (
                <>
                  <GridItem>
                    <SearchableSelect
                      name="supportSubdistributorId"
                      label="CSE Name"
                      items={selectSupport
                        ?.sort((support1, support2) =>
                          (support1.user['firstName'] || '')
                            ?.toString()
                            .localeCompare((support2.user['firstName'] || '').toString())
                        )
                        ?.map((item) => ({
                          key: item?.user?.firstName,
                          value: item?.id,
                        }))}
                      searchFieldPlaceholder={'Search CSE Name'}
                      disabled={true}
                    />
                  </GridItem>
                  <GridItem>
                    <MFTextField
                      name="supportDistributorEmail"
                      label="CSE Email"
                      placeholder="Enter CSE Email"
                      disabled={true}
                    />
                  </GridItem>
                  <GridItem>
                    <MFTextField
                      name="supportDistributorPhone"
                      label="CSE Mobile Number"
                      placeholder="Enter CSE Mobile Number"
                      disabled={true}
                      regexForFilterValue={nonDigitRegex}
                    />
                  </GridItem>
                </>
              )}
            </>
          )}
          <CommenDetailsForRMDistributor />
          {/* <GridItem>
            <MFTextField name="filledBy" label="Filled by" placeholder="Enter filled by" />
            <Typography
              component="span"
              sx={{ position: 'relative', left: 64, bottom: { xs: 85, md: 91 } }}>
              <InfoOutlinedIcon color="info" fontSize="small" onClick={handleOpen} />
            </Typography>
          </GridItem> */}
          <InfoDialog
            onClose={handleClose}
            open={open}
            content={'Please specify the name of the representative filling up the form.'}
          />
          <ConfirmationDialog
            message={`Are you sure you want to clear Distributor details ?`}
            open={openDialog}
            setOpen={handleDialogClose}
            onSave={() => {
              handleDialogClose();
              setValues({
                ...values,
                distributorName: '',
                distributorId: null,
                subdistributorId: null,
                subdistributorName: '',
                subdistributorEmail: '',
                distributorEmail: '',
                distributorPhone: '',
                distributorAlternatePhoneNumber: '',
                subdistributorPhone: '',
                subdistributorAlternatePhoneNumber: '',
                supportSubdistributorId: null,
                supportDistributorEmail: '',
                supportDistributorPhone: '',
              });
              setSelectedDistributor([]);
            }}
            onCancel={handleDialogClose}
          />
        </>
      ) : (
        <Grid
          xs={12}
          sm={12}
          sx={{
            fontSize: 18,
            fontWeight: 500,
            color: 'rgb(97, 209, 214)',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            mt: { lg: 9, xs: 9 },
            mb: 2.9,
            ml: { xs: 3 },
          }}>
          No distributor mapped under this RM
        </Grid>
      )}
    </>
  );
};

export default function DistributorDetails(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role } = useSelector((store: RootStateType) => store.auth);
  const [selectedDistributor, setSelectedDistributor] = useState<SubDistributor[]>([]);
  const [selectSupport, setSelectSupport] = useState<SupportRmProp[]>([]);
  const { auth } = useSelector((store: RootStateType) => store);
  const isSupportRM = auth.id === application?.supportRm?.userId;

  const {
    distributor,
    rm,
    rm_id,
    subdistributorId = null,
    subDistributor,
    filledBy = '',
    supportRm,
    support_rm_id,
    brokerName = '',
    supportSubdistributorId = null,
    supportSubdistributor,
  } = application || {};

  const {
    name,
    id,
    user: { email: distributorEmail, phone: distributorPhone },
    alternativeMobileNumber: distributorAlternatePhoneNumber,
  } = distributor || {
    arnCode: '',
    name: '',
    id: null,
    user: { email: '', phone: '' },
    alternativeMobileNumber: '',
  };

  const {
    empCode,
    user: { email: RMEmail = '', firstName: RMFirstName = '', phone: RMPhone = '' },
  } = (isSupportRM ? supportRm : rm) || { empCode: '', user: { email: '', phone: '' } };

  const {
    user: { firstName: subdistributorName, email: subdistributorEmail, phone: subdistributorPhone },
    alternativeMobileNumber: subdistributorAlternatePhoneNumber,
  } = subDistributor || {
    user: { firstName: '', email: '', phone: '' },
    alternativeMobileNumber: '',
  };

  const { user, id: supportId } = supportSubdistributor || {};

  const initialValues: DistributorDetailsProps = {
    distributorId: id,
    distributorName: name,
    loginType: rm_id !== null || support_rm_id !== null ? 'rm' : 'distributor',
    empCode,
    email: RMEmail,
    firstName: RMFirstName,
    subdistributorId: subdistributorId === null ? subdistributorId : Number(subdistributorId),
    subdistributorName,
    subdistributorEmail,
    filledBy,
    distributorEmail,
    distributorPhone,
    distributorAlternatePhoneNumber,
    subdistributorPhone,
    subdistributorAlternatePhoneNumber,
    supportSubdistributorId:
      supportSubdistributorId === null ? supportSubdistributorId : Number(supportSubdistributorId),
    supportDistributorEmail: user?.email || '',
    supportDistributorPhone: user?.phone || '',
    rm_id,
    brokerName,
    phone: RMPhone,
  };

  const [rmsList, setRmList] = useState<RMType[]>([]);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        if (Object.keys(application || {}).length && role === USER_ROLES.RM) {
          const res = (await dispatch(
            getDistributors({ isActive: true })
          )) as unknown as Distributor[];
          const distributors = res || [];
          const updatedDistributor =
            application?.distributor_id && !application.distributor.isActive
              ? [
                  ...distributors,
                  {
                    ...application.distributor,
                    subdistributors: [application.subDistributor].filter((ele) => ele),
                  } as Distributor,
                ]
              : distributors;
          let updateRMList: RMType[] = [];
          if (isSupportRM) {
            const rms = (await dispatch(
              getRMsList({ roleType: 'amc_rm', isActive: true })
            )) as unknown as RMType[];
            updateRMList =
              application?.rm_id && !application.rm.isActive ? [...rms, application.rm] : rms;
          }

          if (isComponentActive) {
            const updateSubdistributor = (
              application?.distributor_id &&
              application?.distributor.isActive &&
              application?.subdistributorId &&
              !application.subDistributor?.isActive
                ? [
                    ...(updatedDistributor.find((distributor) => distributor.id === id)
                      ?.subdistributors || []),
                    { ...application.subDistributor },
                  ]
                : updatedDistributor.find((distributor) => distributor.id === id)
                    ?.subdistributors || []
            ).filter(
              (ele) =>
                subdistributorTypes[ele.subdistributorType || ''] === subdistributorTypes.direct
            );
            setSelectedDistributor(updateSubdistributor as unknown as SubDistributor[]);
            setSelectSupport([application?.supportSubdistributor as SupportRmProp]);
            setDistributors(updatedDistributor as unknown as Distributor[]);
            setRmList(updateRMList);
          }
        } else if (
          application &&
          supportSubdistributor &&
          checkSupportLogin(Number(auth.id), supportSubdistributor?.user.id)
        ) {
          const { distributor, subDistributor } = application || {};
          setDistributors(distributor ? [distributor as Distributor] : []);
          setSelectSupport([application?.supportSubdistributor as SupportRmProp]);
          const getDistributorRmDetails = (await dispatch(
            getDistributorRMbyId(Number(application?.supportSubdistributorId))
          )) as unknown as SubDistributorListType;
          setSelectedDistributor(
            getDistributorRmDetails?.directSubdistributorsDetails.directSubdistributors as any
          );
        } else {
          const { distributor, subDistributor } = application || {};
          setDistributors(distributor ? [distributor as Distributor] : []);
          setSelectSupport([application?.supportSubdistributor as SupportRmProp]);
          setSelectedDistributor(
            subDistributor
              ? [{ ...subDistributor, id: Number(subDistributor.id) } as SubDistributor]
              : []
          );
        }
      } catch (e: unknown) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [application]);

  const onSubmit = async (values: DistributorDetailsProps) => {
    try {
      const { distributorId, subdistributorId, filledBy, supportSubdistributorId = null } = values;
      const { id, applicant1ReferenceId, currentStep = null, status, hasPOA } = application || {};
      const checkApplication = applicationComparison(application, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        distributor_id: distributorId,
        subdistributorId: subdistributorId ? subdistributorId.toString() : subdistributorId,
        filledBy: filledBy,
        currentStep: currentStep === null ? 1 : currentStep,
        rm_id: values.rm_id,
        brokerName: values.brokerName ? values.brokerName : null,
      });
      let restSchemePlan = {};
      const isDistributorExists =
        !distributorId &&
        application?.distributor_id &&
        (application?.schemeId || application?.planId);
      if (distributorId || isDistributorExists) {
        let getPlanId: any[] = [];
        if (Number(distributorId) !== Number(application?.distributor_id) && distributorId) {
          getPlanId = (
            (await dispatch(getDistributorById(distributorId))) as unknown as DistributorListType
          )?.planDetails.planIds;
        } else if (isDistributorExists) {
          (
            (await dispatch(getDirectApplicableFunds({ isActive: true }))) as unknown as FundProps[]
          )?.map((fund) => {
            getPlanId.push(
              ...fund.plans?.filter((fund) => fund.isApplicableForDirect)?.map((plan) => plan.id)
            );
            return fund;
          });
        }
        if (
          application?.planId &&
          getPlanId?.length > 0 &&
          !getPlanId.map((ele) => ele?.toString()).includes(application?.planId?.toString())
        ) {
          restSchemePlan = {
            schemeId: null,
            planId: null,
            commitmentAmount: 0,
            setupFeePercentage: 0,
            managementFeesRate: 0,
            taxRate: 0,
            drawDownNumber: 0,
            contributionChequeAmount: 0,
            setupFeeAmount: null,
            setupFeeGST: null,
            totalSetupFee: null,
            setupFeeTDS: null,
            netSetupFeeLessTDS: null,
            totalAmountPayable: null,
            feeType: '',
            category: '',
            additionalReturnWithoutCatchup: '',
            catchup: '',
            hurdleRateOfReturn: '',
          };
        }
      }
      if (id && !checkApplication) {
        await dispatch(
          updateApplication({
            body: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...application!,
              distributor_id: distributorId,
              subdistributorId,
              supportSubdistributorId,
              ...restSchemePlan,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status === 'sent_to_amc_approver' &&
                    hasPOA &&
                    USER_ROLES.SUBDISTRIBUTOR === role
                  ? 'draft'
                  : status,
              currentStep: 1,
              //currentStep === null ? 1 : currentStep
              filledBy,
              rm_id: values.rm_id,
              brokerName: values.brokerName ? values.brokerName : null,
            },
            applicationId: id,
          })
        );
        history.push('contributor-details', { id, applicant1ReferenceId });
      } else if (checkApplication) {
        history.push('contributor-details', { id, applicant1ReferenceId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={distributorsValidationSchema(
        selectedDistributor,
        distributors,
        isSupportRM
      )}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'This section specifies the details of the Distributor and RM, as applicable.'
            }
          />
          <Grid container rowSpacing={2} columnSpacing={5}>
            {[USER_ROLES.RM].includes(role) && (
              <Grid item xs={12}>
                <UseRadioGroup
                  formLabel=""
                  name={`loginType`}
                  items={[
                    { label: 'Direct', value: USER_ROLES.RM },
                    { label: 'Distributor', value: USER_ROLES.DISTRIBUTOR },
                  ]}
                />
              </Grid>
            )}
            {![USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR, USER_ROLES.POAAPPROVER].includes(
              role
            ) && values.loginType === USER_ROLES.RM ? (
              <RMDetail isSupportRM={isSupportRM} rmsList={rmsList} values={values} />
            ) : (
              <DistributorDetail
                distributors={distributors}
                values={values}
                selectedDistributor={selectedDistributor}
                selectSupport={selectSupport}
                setSelectSupport={setSelectSupport}
                setSelectedDistributor={(subdistributors) =>
                  setSelectedDistributor(subdistributors)
                }
              />
            )}
          </Grid>
          <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 5 }}>
            <MFSubmitButton label="Next" />
          </Box>
        </Box>
      )}
    </Formik>
  );
}
