/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Checkbox, Dialog, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { Groups, GroupSignatories } from '../../redux-store/types/api-types';
import { DrawDownMasters, getGroupNameMaster } from '../../utils/constant';
import { DataTable } from '../DataTable';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ProceedSaveLater } from '../investors/components';
import { MFTextField } from '../../lib/formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import {
  applicationComparison,
  preventSpecialCharacters,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { nonIndividualAuthorisedSignatories } from '../../utils/schema';
import { ConfirmationDialog, CountryCodesDropDown } from '../commonComponents';
import { showError } from '../../redux-store/actions/auth';
import { useSnackbar } from 'notistack';
import { nonDigitRegex } from '../../utils/regex';
// type GroupsProps = {
//   name: string;
//   threshold: number;
//   groupsignatories: GroupSignatories[];
// };

// export type Values = {
//   groups: GroupsProps[];
//   saveType: string;
// };

export const GroupSignatoriesObject: GroupSignatories = {
  groupId: '',
  name: '',
  pan: '',
  mobile: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  email: '',
  designation: '',
  canEsign: true,
  isActive: true,
};

// const initialValues: Values = {
//   groups: [
//     {
//       name: '',
//       threshold: 1,
//       groupsignatories: [GroupSignatoriesObject],
//     },
//   ],
//   saveType: 'save and proceed',
// };

const headers = [
  {
    header: 'Name',
    label: 'name',
    valueGetter: (row: GroupSignatories) => row.name || 'N/A',
  },
  {
    header: 'PAN',
    valueGetter: (row: GroupSignatories) => row.pan.toUpperCase() || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: GroupSignatories) => row.email || 'N/A',
  },
  {
    header: 'Designation',
    valueGetter: (row: GroupSignatories) => row.designation || 'N/A',
  },
];

export default function AuthorisedSignatories(): JSX.Element {
  const [authorisedSignatoriesGroup, setAuthorisedSignatoriesGroup] = useState<Groups[]>([]);
  const [authorisedSignatories, setAuthorisedSignatories] = useState(GroupSignatoriesObject);
  const [loading, setLoading] = useState(false);
  const [saveType, setSaveType] = useState('');
  const [open, setOpen] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  // const [thresholdValue, setThresholdValue] = useState(1);
  const [groupIndex, setGroupIndex] = useState(Number);
  const [rowIndex, setRowIndex] = useState(Number);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [deletePopup, setDeletePopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async function () {
      try {
        const { groups = [] } = application || {};
        setAuthorisedSignatoriesGroup(
          groups.length
            ? groups.map((group) => {
                return {
                  ...group,
                  groupsignatories: group.groupsignatories.map((signatory) => {
                    return {
                      ...signatory,
                      countryCode: signatory.countryCode ? signatory.countryCode : '+91',
                      countryNameAndCode: signatory.countryNameAndCode
                        ? signatory.countryNameAndCode
                        : 'India: +91',
                    };
                  }),
                };
              })
            : [
                {
                  name: '',
                  threshold: 1,
                  groupsignatories: [],
                },
              ]
        );
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const handleClose = () => {
    setAuthorisedSignatories(GroupSignatoriesObject),
      setRowIndex(Number),
      setGroupIndex(Number),
      setOpen(false);
  };

  const onSubmit = (values: GroupSignatories) => {
    const editsignatory = authorisedSignatoriesGroup[groupIndex].groupsignatories
      .filter((_item) => _item.isActive)
      .map((signatory, _ind) => {
        if (rowIndex === _ind) {
          return { ...values, countryCode: values.countryNameAndCode.split(':')[1].trim() };
        }
        return signatory;
      });
    const deletedsignatory = authorisedSignatoriesGroup[groupIndex].groupsignatories.filter(
      (_item) => !_item.isActive && _item.id
    );

    const addSignatoriesGroup = authorisedSignatoriesGroup.map((group, ind) => {
      if (ind === groupIndex) {
        return {
          ...group,
          name: getGroupNameMaster[groupIndex],
          threshold: group.threshold,
          groupsignatories: [
            authorisedSignatories.pan,
            authorisedSignatories.mobile,
            authorisedSignatories.email,
          ].includes('')
            ? [
                ...group.groupsignatories,
                { ...values, countryCode: values.countryNameAndCode.split(':')[1].trim() },
              ]
            : [...editsignatory, ...deletedsignatory],
        };
      }
      return group;
    });
    setAuthorisedSignatoriesGroup(addSignatoriesGroup);
    handleClose();
  };

  const handleSubmit = async (saveTypeText: string) => {
    try {
      const { id, applicant1ReferenceId = '', currentStep, applicationNumber } = application || {};
      authorisedSignatoriesGroup.map((group) => {
        const getActiveSignatories = group.groupsignatories.filter((item) => item.isActive);
        const getCanEsignCount = getActiveSignatories.filter((item) => item.canEsign);
        if (!getActiveSignatories.length) {
          throw 'Please add Authorised Signatories';
        }
        if (!getCanEsignCount.length) {
          throw 'Please Select a member for E-Sign';
        }
        if (getCanEsignCount.length < group.threshold) {
          throw 'Authorised Signatories that can esign can not be less than the Total No. of required Authorised Signatories';
        }
        return group;
      });

      const checkApplication = applicationComparison(
        {
          ...application,
        },
        {
          ...application,
          groups: authorisedSignatoriesGroup.map((group) => ({
            ...group,
            groupsignatories: group.groupsignatories.map((groupsignatory) => ({
              ...groupsignatory,
              name: removeSingleQuote(groupsignatory.name),
              designation: removeSingleQuote(groupsignatory.designation),
              email: removeSingleQuote(groupsignatory.email),
            })),
            isActive: true,
          })),
          currentStep: !!currentStep && currentStep > 6 ? currentStep : Number(currentStep) + 1,
        }
      );
      if (id && !checkApplication) {
        setSaveType(saveTypeText);
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              groups: authorisedSignatoriesGroup.map((group) => ({
                ...group,
                groupsignatories: group.groupsignatories.map((groupsignatory) => ({
                  ...groupsignatory,
                  name: removeSingleQuote(groupsignatory.name),
                  designation: removeSingleQuote(groupsignatory.designation),
                  email: removeSingleQuote(groupsignatory.email),
                })),
                isActive: true,
              })),
              currentStep: 7,
            },
            applicationId: id,
            ...(saveTypeText !== 'save and proceed' && {
              toastMessage: '',
            }),
          })
        );
        saveTypeText === 'save and proceed'
          ? history.push('declaration-ubo', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (saveTypeText !== 'save and proceed') {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        saveTypeText === 'save and proceed'
          ? history.push('declaration-ubo', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const editCell = {
    header: '',
    renderEditDeleteCell: (
      row: GroupSignatories,
      index: number,
      _groupIndex?: number
    ): JSX.Element => {
      return (
        <Box display="flex">
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                setAuthorisedSignatories(row),
                  typeof _groupIndex !== 'undefined' && setGroupIndex(_groupIndex),
                  setRowIndex(index),
                  setOpen(true);
              }}
              sx={{
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
              }}>
              <EditIcon sx={{ color: 'primary.main' }} fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setAuthorisedSignatories(row),
                  typeof _groupIndex !== 'undefined' && setGroupIndex(_groupIndex),
                  setRowIndex(index),
                  setDeletePopup(true);
              }}
              sx={{
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
              }}>
              <DeleteOutlineIcon color="error" fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
  };

  const sendToEsignCell = {
    header: (): JSX.Element => {
      const [open, setOpen] = useState(false);

      const handleOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
      return (
        <>
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: 14,
              fontWeight: 500,
              color: 'primary.main',
              pr: 0.5,
            }}
            component="span">
            Send e-sign link
          </Typography>
          <InfoOutlinedIcon
            color="info"
            fontSize="small"
            onClick={handleOpen}
            sx={{ position: 'relative', top: 4 }}
          />

          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                maxWidth: 400,
                p: { xs: 2, sm: '15px 20px 45px' },
                borderRadius: '10px',
                height: 'unset',
                overflowY: 'auto',
              },
            }}>
            <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
              <CloseIcon fontSize="medium" />
            </IconButton>
            <Box sx={{ mb: 2, textAlign: 'center', '.MuiSvgIcon-root': { fontSize: '3.1875rem' } }}>
              <InfoOutlinedIcon color="info" fontSize="large" />
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto',
                '.MuiTypography-root': {
                  fontSize: 20,
                  fontWeight: 500,
                  textAlign: 'center',
                },
              }}>
              <Typography sx={{ color: 'secondary.main', lineHeight: '35px' }}>
                Use this option to send the e-sign link to specific set of authorized signatories.
              </Typography>
            </Box>
          </Dialog>
        </>
      );
    },
    renderEditDeleteCell: (
      row: GroupSignatories,
      index: number,
      group_Index?: number
    ): JSX.Element => {
      return (
        <Checkbox
          checked={row.canEsign}
          // defaultChecked
          sx={{ display: { xs: 'block' }, ':hover': { backgroundColor: 'white' } }}
          onChange={() => {
            const setCanEsign = authorisedSignatoriesGroup.map((group, ind) => {
              if (ind === group_Index) {
                return {
                  ...group,
                  name: getGroupNameMaster[group_Index],
                  threshold: group.threshold,
                  groupsignatories: [
                    ...group.groupsignatories
                      .filter((_item) => _item.isActive)
                      .map((signatory, _ind) => {
                        if (index === _ind) {
                          return { ...signatory, canEsign: !signatory.canEsign };
                        }
                        return signatory;
                      }),
                    ...authorisedSignatoriesGroup[groupIndex].groupsignatories.filter(
                      (_item) => !_item.isActive && _item.id
                    ),
                  ],
                };
              }
              return group;
            });

            setAuthorisedSignatoriesGroup(setCanEsign);
          }}
        />
      );
    },
  };

  return (
    <Grid
      container
      rowSpacing={1}
      //columnSpacing={5}
      sx={{
        width: '100%',
        ml: 0,
        '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
      }}>
      {authorisedSignatoriesGroup.map((group, index) => (
        <React.Fragment key={index}>
          <Grid
            sx={{
              display: 'flex',
            }}
            alignItems="center"
            mb={4}
            mt={1}>
            <Typography sx={{ color: 'primary.main', fontSize: '16px' }}>
              Total No. of required authorised signatories
              <Typography component="span" sx={{ color: '#BD2932' }}>
                *
              </Typography>{' '}
              :
            </Typography>
            <Box
              sx={{
                width: '100px',
                ml: 2,
                '.MuiOutlinedInput-root': {
                  marginTop: '0',
                  backgroundColor: 'rgba(97, 209, 214, 0.07)',
                  '&.MuiInputBase-root': {
                    '& .MuiSelect-select': {
                      padding: '7px 38px',
                      border: 'none',
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                },

                '&.Mui-focused,&:hover': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: 'none',
                  },
                },
                svg: {
                  color: '#337FC9',
                },
              }}>
              <Select
                labelId="user-items"
                onChange={(e) => {
                  const updatethreshold = authorisedSignatoriesGroup.map((_group, _ind) => {
                    if (index === _ind) {
                      return {
                        ..._group,
                        threshold: Number(e.target.value),
                      };
                    }
                    return _group;
                  });
                  setAuthorisedSignatoriesGroup(updatethreshold);
                }}
                value={group.threshold}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                }}>
                {DrawDownMasters.filter((_num) => _num < 4).map((thresholdValue, ind) => (
                  <MenuItem
                    sx={{
                      borderBottom: '1px solid #ccc',
                      textAlign: 'center',
                      '&:last-child': { border: 'none' },
                      justifyContent: 'center',
                    }}
                    value={thresholdValue}
                    key={ind}>
                    {thresholdValue}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          {group.groupsignatories.filter((_signatory) => _signatory.isActive).length !== 0 && (
            <Grid xs={12} sm={12} lg={12}>
              <DataTable
                groupIndex={index}
                tableData={group.groupsignatories.filter((_signatory) => _signatory.isActive)}
                tableHeader={[sendToEsignCell, ...headers, editCell]}
                renderAdditionalRow={false}
                tableHeaderCustomStyles={{
                  '.MuiTableCell-head': {
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'primary.main',
                  },
                }}
                rowCustomStyles={{
                  '.MuiTableCell-root': {
                    py: '8px',
                    overflowWrap: 'anywhere',
                  },
                }}
                boxStyles={{ minHeight: '0px', minWidth: 450 }}
              />
            </Grid>
          )}
          {group.groupsignatories.filter((item) => item.isActive).length < 20 && (
            <Grid
              xs={12}
              sm={12}
              lg={12}
              display="flex"
              sx={{
                justifyContent: {
                  xs:
                    group.groupsignatories.filter((_signatory) => _signatory.isActive).length === 0
                      ? 'start'
                      : 'center',
                  sm:
                    group.groupsignatories.filter((_signatory) => _signatory.isActive).length === 0
                      ? 'start'
                      : 'end',
                },
              }}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 13,
                  mr: 2.5,
                  whiteSpace: 'nowrap',
                  mt: '20px',
                }}
                onClick={() => {
                  setAuthorisedSignatories(GroupSignatoriesObject),
                    setGroupIndex(index),
                    setOpen(true);
                }}>
                Add Authorised Signatory
              </Button>
            </Grid>
          )}
        </React.Fragment>
      ))}
      {/* {  console.log(saveTypeText)} */}
      <ProceedSaveLater
        saveLater={() => {
          handleSubmit('save for later');
        }}
        saveAndProceed={() => {
          handleSubmit('save and proceed');
        }}
        loader={loading}
        clickedButton={saveType}
      />

      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            p: 4,
            width: { xs: '70%', sm: '70%' },
            pt: 2,
          },
        }}>
        <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              textAlign: 'center',
            },
            '.MuiFormControl-root': { my: 1 },
            px: { xs: '0', sm: 5 },
          }}>
          <Typography
            sx={{
              color: '#0D2762',
              position: 'relative',
              bottom: '20px',
              fontSize: '23px',
              fontWeight: 500,
            }}>
            {authorisedSignatories.pan ? 'Edit Member' : 'Add Member'}
          </Typography>

          <Formik
            initialValues={authorisedSignatories}
            onSubmit={onSubmit}
            //validationSchema={nonIndividualAuthorisedSignatories}
            validate={(values: GroupSignatories) => {
              const setValuesForEdit = authorisedSignatoriesGroup.map((group, _ind) => {
                if (_ind === groupIndex) {
                  return {
                    ...group,
                    groupsignatories: group.groupsignatories
                      .filter((_signatory) => _signatory.isActive)
                      .map((_signatory, index) => {
                        if (index === rowIndex) {
                          if (
                            values.pan !== _signatory.pan &&
                            values.mobile !== _signatory.mobile &&
                            values.email !== _signatory.email
                          ) {
                            return _signatory;
                          }
                          if (
                            values.pan === _signatory.pan &&
                            values.mobile === _signatory.mobile &&
                            values.email === _signatory.email
                          ) {
                            const { mobile, email, pan, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan !== _signatory.pan &&
                            values.mobile === _signatory.mobile &&
                            values.email === _signatory.email
                          ) {
                            const { mobile, email, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan === _signatory.pan &&
                            values.mobile !== _signatory.mobile &&
                            values.email === _signatory.email
                          ) {
                            const { pan, email, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan === _signatory.pan &&
                            values.mobile === _signatory.mobile &&
                            values.email !== _signatory.email
                          ) {
                            const { mobile, pan, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan !== _signatory.pan &&
                            values.mobile !== _signatory.mobile &&
                            values.email === _signatory.email
                          ) {
                            const { email, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan !== _signatory.pan &&
                            values.mobile === _signatory.mobile &&
                            values.email !== _signatory.email
                          ) {
                            const { mobile, ...rest } = _signatory;
                            return { ...rest };
                          }
                          if (
                            values.pan === _signatory.pan &&
                            values.mobile !== _signatory.mobile &&
                            values.email !== _signatory.email
                          ) {
                            const { pan, ...rest } = _signatory;
                            return { ...rest };
                          }
                          return;
                        }
                        return _signatory;
                      }),
                  };
                }
                return group;
              });

              try {
                validateYupSchema(
                  values,
                  nonIndividualAuthorisedSignatories,
                  true,
                  authorisedSignatories.pan
                    ? setValuesForEdit
                    : authorisedSignatoriesGroup.map((_group) => {
                        return {
                          ..._group,
                          groupsignatories: _group.groupsignatories.filter(
                            (_signatory) => _signatory.isActive
                          ),
                        };
                      })
                );
              } catch (e) {
                return yupToFormErrors(e);
              }
            }}
            enableReinitialize={true}>
            {({ handleSubmit, setFieldValue, values }) => (
              <Box component="form" noValidate onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  <MFTextField name={`name`} label="Name *" placeholder="Enter Name" />
                </Grid>
                <Grid item xs={12}>
                  <MFTextField
                    name={`pan`}
                    label="PAN *"
                    placeholder="Enter Pan"
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    onChange={(e) => {
                      setFieldValue('pan', e.target.value.toUpperCase());
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFTextField
                    name={`mobile`}
                    label={`Mobile Number *`}
                    placeholder="Enter Mobile Number"
                    onKeyDown={(e) => {
                      preventSpecialCharacters(e);
                    }}
                    startAdornment={
                      <CountryCodesDropDown
                        name={`countryNameAndCode`}
                        value={values.countryNameAndCode}
                      />
                    }
                    regexForFilterValue={nonDigitRegex}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFTextField
                    name={`email`}
                    label="Email ID (Office ID Only) *"
                    placeholder="Enter Email ID"
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFTextField
                    name={`designation`}
                    label="Designation *"
                    placeholder="Enter Designation"
                  />
                </Grid>
                <Grid xs={12} sm={12} lg={12} display="flex" sx={{ justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: 15,
                      minWidth: 180,
                      letterSpacing: 2,
                      fontWeight: 500,
                      alignSelf: 'center',
                      mt: 2,
                    }}
                    type="submit">
                    {authorisedSignatories.pan ? 'Save' : 'Add'}
                  </Button>
                </Grid>
              </Box>
            )}
          </Formik>
        </Box>
      </Dialog>
      <ConfirmationDialog
        message={'Are you sure you want to delete Member ?'}
        open={deletePopup}
        setOpen={() => setDeletePopup(false)}
        onSave={() => {
          setDeletePopup(false);
          const deleteSignatory = authorisedSignatoriesGroup.map((group, ind) => {
            if (ind === groupIndex) {
              return {
                ...group,
                name: getGroupNameMaster[groupIndex],
                threshold: group.threshold,
                groupsignatories: group.groupsignatories
                  .filter((_item) => _item.isActive)
                  .map((signatory, _ind) => {
                    if (rowIndex === _ind) {
                      return { ...signatory, isActive: false };
                    }
                    return signatory;
                  }),
              };
            }
            return group;
          });

          // const deletedSignatory = authorisedSignatoriesGroup.map((group, _ind) => {
          //   if (_ind === groupIndex) {
          //     return {
          //       ...group,
          //       name: getGroupNameMaster[groupIndex],
          //       threshold: group.threshold,
          //       groupsignatories: group.groupsignatories.filter(
          //         (_signatory) => !_signatory.isActive && _signatory.id
          //       ),
          //     };
          //   }
          //   return group;
          // });
          const active_DeletedSignatory = deleteSignatory.map((group, _ind) => {
            if (_ind === groupIndex) {
              return {
                ...group,
                name: getGroupNameMaster[groupIndex],
                threshold: group.threshold,
                groupsignatories: [
                  ...authorisedSignatoriesGroup[groupIndex].groupsignatories.filter(
                    (_signatory) => !_signatory.isActive && _signatory.id
                  ),
                  ...group.groupsignatories.filter(
                    (_signatory) => _signatory.isActive || (!_signatory.isActive && _signatory.id)
                  ),
                ],
              };
            }
            return group;
          });
          //const setAuthorisedSignatories=deleteSignatory[groupIndex].groupsignatories:[...active_DeletedSignatory[groupIndex].groupsignatories,...deleteSignatory[groupIndex].groupsignatories]
          setAuthorisedSignatoriesGroup(active_DeletedSignatory);
          setAuthorisedSignatories(GroupSignatoriesObject),
            setRowIndex(Number),
            setGroupIndex(Number);
          // try {
          //   const deleteSignatory = authorisedSignatoriesGroup.map((group, ind) => {
          //     if (ind === groupIndex) {
          //       return {
          //         ...group,
          //         name: getGroupNameMaster[groupIndex],
          //         threshold: group.threshold,
          //         groupsignatories: group.groupsignatories.map((signatory, _ind) => {
          //           if (rowIndex === _ind) {
          //             return { ...signatory, isActive: false };
          //           }
          //           return signatory;
          //         }),
          //       };
          //     }
          //     return group;
          //   });
          //   authorisedSignatoriesGroup.map((group) => {
          //     const getCanEsignCount = group.groupsignatories.filter((item) => item.canEsign);
          //     if (getCanEsignCount.length < group.threshold) {
          //       throw 'Authorised Signatories that can esign cannot be less than the threshold';
          //     }
          //     return group;
          //   });
          //   const { id } = application || {};
          //   if (id && authorisedSignatories.groupId) {
          //     await dispatch(
          //       updateApplication({
          //         body: {
          //           ...application,
          //           groups: deleteSignatory,
          //         },
          //         applicationId: id,
          //       })
          //     );
          //     return;
          //   }
          //   const deletedSignatories = deleteSignatory.map((group, _ind) => {
          //     if (_ind === groupIndex) {
          //       return {
          //         ...group,
          //         name: getGroupNameMaster[groupIndex],
          //         threshold: group.threshold,
          //         groupsignatories: group.groupsignatories.filter(
          //           (_signatory) => _signatory.isActive
          //         ),
          //       };
          //     }
          //     return group;
          //   });
          //   setAuthorisedSignatoriesGroup(deletedSignatories);
          //   setAuthorisedSignatories(GroupSignatoriesObject),
          //     setRowIndex(Number),
          //     setGroupIndex(Number);
          // } catch (e) {
          //   //typeof e === 'string' && dispatch(showError(e));
          //   console.error((e as Error).message);
          // }
        }}
        onCancel={() => {
          setAuthorisedSignatories(GroupSignatoriesObject),
            setRowIndex(Number),
            setGroupIndex(Number),
            setDeletePopup(false);
        }}
      />
    </Grid>
  );
}
