import { Grid, Typography, SelectChangeEvent, Dialog, IconButton, Box } from '@mui/material';
import { InfoDialog, ProceedSaveLater, SubHeading } from './components';
import {
  Formik,
  validateYupSchema,
  FormikHelpers,
  yupToFormErrors,
  useFormikContext,
} from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import { Applicant } from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  BusinessConstitutionMasters,
  fewFieldsInKYCDetailsAndInvestorSignatureDocumentValidationDate,
  grossAnnualMasters,
  occupationDetailsMasters,
  PEPsMasters,
  RISK_PROFILE,
  SourceOfFundMaster,
  USER_ROLES,
  DLCLMasters,
  YesNoMaster,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkValidationBasedOnDate,
  currencyConversion,
  getApplicantName,
  getOccupationType,
  getSourceOfFund,
  removeSingleQuote,
  saveForLater,
  // cleardpIdField,
  getclIdField,
  getdpIdField,
  maxNetworthDate,
  checkPmsPoa,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { KYCDetailsSchema } from '../../utils/schema';
import { MFTextField } from '../../lib/formik';
import { useSnackbar } from 'notistack';
import MFCheckbox from '../../lib/formik/Checkbox';
import { showError } from '../../redux-store/actions/auth';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '../../lib/formik/DatePicker';
import UseRadioGroup from '../../lib/formik/Radio';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ConfirmationDialog } from '../commonComponents';

type updatedKyc = Applicant & {
  fundSource: string | null;
  occupationType: string | null;
};

type Values = {
  applicants: Partial<updatedKyc>[];
  saveType: string;
};

const initialValues: Values = {
  applicants: [
    {
      occupationDetails: '',
      occupationType: '',
      grossAnnualIncome: '',
      politicallyExposedPersonStatus: 'Not Applicable',
      ckycNo: '',
      nameOfEmployer: '',
      designation: '',
      employmentYears: null,
      businessConstitution: '',
      otherBusinessConstitution: '',
      riskProfile: 'high',
      // investmentExperienceInCapitalMarket: '',
      netWorth: '',
      sourceOfFunds: '',
      netWorthDate: null,
      fundSource: '',
      sourceOfFundsLegitimacy: true,
      sourceOfFundsDeclaration: null,
      dlclId: '',
      POADP: '',
      dpId: '',
      clId: '',
    },
  ],
  saveType: 'save and proceed',
};

const KYCDetails = ({
  applicant,
  index,
  values,
  setValues,
  createdDate,
}: {
  applicant: string;
  index: number;
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  createdDate: string | undefined;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [open, setOpen] = useState(false);

  const handleOpenf = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const mandatoryStarBasedOnApplicationAfterDate = checkValidationBasedOnDate(
    createdDate,
    fewFieldsInKYCDetailsAndInvestorSignatureDocumentValidationDate
  )
    ? '*'
    : '';

  return (
    <>
      <SubHeading>Additional KYC Details of {applicant} Applicant</SubHeading>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.occupationType`}
          label="Occupation Details *"
          items={occupationDetailsMasters.map((occupation) => ({
            key: occupation,
            value: occupation,
          }))}
          onChange={(e) => {
            setValues({
              ...values,
              applicants: values.applicants.map((_applicant, _index) => {
                if (_index === index) {
                  return {
                    ..._applicant,
                    occupationType: e.target.value,
                    occupationDetails: '',
                  };
                }
                return _applicant;
              }) as Partial<updatedKyc>[],
            });
          }}
        />
      </Grid>
      {values.applicants.map((applicant, idx: number) => {
        if (applicant.occupationType === 'OTHERS' && idx === index) {
          return (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.occupationDetails`}
                label="Please Specify Occupation Type *"
                placeholder={`Enter Occupation Type`}
                onChange={(e) => {
                  const occupationDetails = e.target.value.replace(/\s+/g, ' ');
                  setValues({
                    ...values,
                    applicants: values.applicants.map((_applicant, _index) => {
                      if (_index === index) {
                        return {
                          ..._applicant,
                          occupationDetails: occupationDetails,
                        };
                      }
                      return _applicant;
                    }) as Partial<updatedKyc>[],
                  });
                }}
              />
            </Grid>
          );
        }
      })}
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.grossAnnualIncome`}
          label="Gross Annual Income"
          items={grossAnnualMasters.map((grossIncome) => ({
            key: grossIncome,
            value: grossIncome,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.netWorth`}
          label="Net Worth"
          placeholder="Enter Net Worth"
        />
        {values.applicants[index].netWorth && !isNaN(Number(values.applicants[index].netWorth)) && (
          <Typography sx={{ fontSize: 13 }}>
            {currencyConversion(values.applicants[index].netWorth)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label={'Net worth as on'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Net worth as on'}
          name={`applicants.${index}.netWorthDate`}
          maxDate={maxNetworthDate()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.politicallyExposedPersonStatus`}
          label="Politically Exposed Person (PEP) Status *"
          items={PEPsMasters.map((pep) => ({ key: pep.key, value: pep.value }))}
        />
        <Typography
          component="span"
          sx={{ position: 'relative', left: { lg: 286, xs: 286 }, bottom: { xs: 85, md: 95 } }}>
          <HelpOutlineIcon
            color="info"
            fontSize="small"
            onClick={handleOpenf}
            sx={{ cursor: 'pointer', pointerEvents: 'all' }}
          />
        </Typography>
        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            '.MuiPaper-root ': {
              maxWidth: 800,
              p: { xs: 2, sm: '15px 20px 45px' },
              borderRadius: '10px',
              height: 'unset',
              overflowY: 'auto',
            },
          }}>
          <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <Box sx={{ mb: 2, textAlign: 'center', '.MuiSvgIcon-root': { fontSize: '3.1875rem' } }}>
            <HelpOutlineIcon color="info" fontSize="large" />
          </Box>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 20,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'secondary.main', lineHeight: '35px' }}>
              {
                'Politically Exposed Persons (PEP) are defined as individuals are, or have been, entrusted with prominent public functions and their family members and close associates in a domestic or foreign country, e.g., Heads of States or of Governments, senior politicians, senior Government/judicial/ military officers, senior executives of state-owned corporations, important political party officials, etc.'
              }
            </Typography>
          </Box>
        </Dialog>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.ckycNo`}
          label="CKYC Number"
          placeholder={'Enter CKYC number'}
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
          disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <MFCheckbox
          name={`applicants.${index}.sourceOfFundsLegitimacy`}
          label={
            'I/we hereby confirm that the funds injected/invested have been legitimately and legally obtained/earned and the derivation and use of the funds do not contravene applicable anti-money laundering and terrorist financing legislation.'
          }
          onChange={({ target: { checked } }) => {
            setValues({
              ...values,
              applicants: values.applicants.map((detail: any, idx: number) => {
                if (index === idx) {
                  return {
                    ...detail,
                    sourceOfFundsLegitimacy: checked,
                    sourceOfFunds: '',
                    fundSource: '',
                  };
                }
                return detail;
              }),
            });
          }}
        />
      </Grid>
      {values.applicants[index].sourceOfFundsLegitimacy && (
        <Grid item xs={12} sm={12}>
          <MFSelectField
            name={`applicants.${index}.fundSource`}
            label="Source of Funds *"
            items={Object.keys(SourceOfFundMaster).map((source) => ({
              key: SourceOfFundMaster[source as string],
              value: source,
            }))}
            onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
              setValues({
                ...values,
                applicants: values.applicants.map((detail: any, idx: number) => {
                  if (index === idx) {
                    return {
                      ...detail,
                      fundSource: value,
                      sourceOfFunds: '',
                    };
                  }
                  return detail;
                }),
              });
            }}
          />
          {values.applicants[index].fundSource === 'others' && (
            <MFTextField
              name={`applicants.${index}.sourceOfFunds`}
              label="Please Specify Source of Funds *"
              placeholder={`Enter Source of Funds`}
            />
          )}
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <MFCheckbox
          name={`applicants.${index}.sourceOfFundsDeclaration`}
          label={
            'I/we hereby confirm that the aforementioned information is true and correct. I/we will also provide documentary evidence of the source of funds if / when required.'
          }
        />
      </Grid>
    </>
  );
};

export const DematDetails = ({
  values,
  index,
  disabled,
}: {
  values: Values;
  index: number;
  disabled: boolean;
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { setFieldValue } = useFormikContext();

  const handleOpenf = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* <SubHeading>Demat Account details</SubHeading> */}
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.dlclId`}
          label={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiTypography-root': {
                  fontFamily: 'inherit',
                  fontWeight: 'inherit',
                  fontSize: 'inherit',
                },
              }}>
              <Typography sx={{ mr: 1 }}>DPCL ID *</Typography>
              <InfoOutlinedIcon
                color="info"
                fontSize="small"
                onClick={handleOpenf}
                sx={{ cursor: 'pointer', pointerEvents: 'all' }}
              />
            </Box>
          }
          items={Object.keys(DLCLMasters).map((Id) => ({
            key: DLCLMasters[Id],
            value: Id,
          }))}
          disabled={disabled}
        />
        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            '.MuiPaper-root ': {
              maxWidth: 700,
              p: { xs: 2, sm: '15px 20px 45px' },
              borderRadius: '10px',
              height: 'unset',
              overflowY: 'auto',
            },
          }}>
          <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <Box
            sx={{
              width: { xs: '100%', sm: '90%' },
              mx: 'auto',
            }}>
            <Typography variant="h6" fontWeight={550}>
              For NSDL
            </Typography>
            <Typography fontWeight={500}>
              &bull; DP ID - Alphanumeric- First two digits should be alpha (Starts with IN) - TOTAL
              8 DIGITS
            </Typography>
            <Typography fontWeight={500} sx={{ mt: 1 }}>
              &bull; CL ID - ALL NUMERIC (8 DIGITS)
            </Typography>
            <Typography variant="h6" fontWeight={550} sx={{ mt: 2 }}>
              For CDSL
            </Typography>
            <Typography fontWeight={500}>&bull; DP ID - ALL NUMERIC (8 DIGITS)</Typography>
            <Typography fontWeight={500} sx={{ mt: 1 }}>
              {' '}
              &bull; CL ID - ALL NUMERIC (8 DIGITS)
            </Typography>
          </Box>
        </Dialog>
      </Grid>
      {getdpIdField(values.applicants[index].dlclId || '') && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.dpId`}
            label="DP ID *"
            placeholder="Enter DP ID"
            disabled={disabled}
          />
        </Grid>
      )}
      {getdpIdField(values.applicants[index].dlclId || '') && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.clId`}
            label="CL ID *"
            placeholder="Enter CL ID"
            disabled={disabled}
          />
        </Grid>
      )}
    </>
  );
};

export default function AdditionalKYCDetails(): JSX.Element {
  const [kycDetails, setkycDetails] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const { applicants: exisitingApplicants = [] } = application || {};
    setkycDetails({
      ...kycDetails,
      applicants: exisitingApplicants.map((applicant) => ({
        occupationType: getOccupationType(applicant.occupationDetails)
          ? applicant.occupationDetails
          : 'OTHERS',
        occupationDetails:
          applicant.occupationDetails === 'OTHERS'
            ? ''
            : getOccupationType(applicant.occupationDetails)
            ? ''
            : applicant.occupationDetails,
        grossAnnualIncome: applicant.grossAnnualIncome || '',
        politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
          ? applicant.politicallyExposedPersonStatus
          : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
          ? ''
          : 'Not Applicable',
        ckycNo: applicant.ckycNo || '',
        // investmentExperienceInCapitalMarket: applicant.investmentExperienceInCapitalMarket || '',
        sourceOfFunds: applicant.sourceOfFunds || '',
        sourceOfFundsLegitimacy: applicant.sourceOfFundsLegitimacy || true,
        sourceOfFundsDeclaration: applicant.sourceOfFundsDeclaration || false,
        fundSource: getSourceOfFund(applicant.sourceOfFunds)
          ? applicant.sourceOfFunds
          : !applicant.sourceOfFunds
          ? ''
          : 'others',
        netWorth: applicant.netWorth || '',
        netWorthDate: applicant.netWorthDate || null,
        dlclId: applicant.dlclId ? applicant.dlclId : '',
        dpId: applicant.dpId || '',
        clId: applicant.clId || '',
        POADP: applicant.POADP || '',
      })),
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const { applicants, saveType } = values;
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};

      const updatedApplicants = exisitingApplicants.map((applicant, index) => {
        const { fundSource, occupationType, ...rest } = applicants[index];
        if (
          applicants[index].sourceOfFundsLegitimacy === false ||
          applicants[index].sourceOfFundsDeclaration === false
        ) {
          throw 'Declaration is required';
        }
        return {
          ...applicant,
          ...rest,
          sourceOfFunds: getSourceOfFund(applicants[index].fundSource)
            ? applicants[index].fundSource
            : applicants[index].sourceOfFunds,
          occupationDetails: getOccupationType(applicants[index].occupationDetails)
            ? occupationType
            : removeSingleQuote(applicants[index].occupationDetails),
          clId: applicants[index].clId || '',
          // dpId: cleardpIdField(applicants[index].dlclId || '') ? '' : applicants[index].dpId,
          dpId: applicants[index].dpId || '',
        };
      });
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedApplicants,
        currentStep:
          !!currentStep && currentStep > 3
            ? currentStep
            : !checkPmsPoa(updatedApplicants)
            ? 3
            : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status === 'sent_to_amc_approver' &&
                    hasPOA &&
                    USER_ROLES.SUBDISTRIBUTOR === role
                  ? 'draft'
                  : status,
              currentStep: !checkPmsPoa(updatedApplicants) ? 3 : 4,
              //!!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={kycDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, KYCDetailsSchema(application?.createdAt), true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((_, index) => (
            <>
              <KYCDetails
                applicant={getApplicantName(index + 1)}
                key={index}
                index={index}
                values={values}
                setValues={setValues}
                createdDate={application?.createdAt}
              />
              <DematDetails values={values} index={index} disabled={isFieldDisabled || false} />
            </>
          ))}
          <ProceedSaveLater
            showSaveAndProceed={checkPmsPoa(values.applicants)}
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
