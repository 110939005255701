import {
  Box,
  Typography,
  IconButton,
  Grid,
  Hidden,
  Skeleton,
  LinearProgress,
  Button,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik, useFormikContext } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { RmsType, addRmDetails } from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Location } from 'history';
import { addRM, editRM, getRMById } from '../../redux-store/actions/userManagement';
import { userManagementRmSchema } from '../../utils/schema';
import { CountryCodesDropDown } from '../commonComponents';
import { RoleTypes } from '../../utils/constant';
import MFSelectField from '../../lib/formik/SelectField';
import { nonDigitRegex } from '../../utils/regex';

const initialValues: addRmDetails = {
  name: '',
  email: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  phone: '',
  panNumber: '',
  empCode: '',
  pincode: '',
  buildingNo: '',
  streetName: '',
  city: '',
  state: '',
  country: '',
  isActive: true,
  roleType: '',
};

export default function InviteRmDetails({
  location,
}: {
  location: Location<{ rmId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [formikValues, setFormikValues] = useState<addRmDetails | null>();
  const { rmId } = location.state || { rmId: '' };
  const [rmDetails, setRmDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        if (rmId) {
          const getRmDetails = (await dispatch(getRMById(rmId))) as unknown as RmsType;
          const {
            name,
            panNumber,
            empCode,
            pincode,
            city,
            state,
            country,
            streetName,
            buildingNo,
            id,
            isActive,
            roleType,
          } = getRmDetails;
          const { phone, email, countryNameAndCode, countryCode } = getRmDetails.user;
          setRmDetails({
            ...rmDetails,
            name,
            panNumber,
            empCode,
            pincode,
            city,
            state,
            country,
            streetName,
            buildingNo,
            id,
            phone,
            email,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
            roleType,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: addRmDetails) => {
    try {
      if (!rmId) {
        await dispatch(
          addRM({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() })
        );
        history.push(`relationship-managers`);
      } else {
        await dispatch(
          editRM(rmId, { ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() })
        );
        history.push(`relationship-manager-details`, { rmId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={rmDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={userManagementRmSchema}>
        {({ handleSubmit, setFieldValue, values }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    rmId
                      ? history.push(`relationship-manager-details`, { rmId })
                      : history.push('relationship-managers')
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
            </Hidden>
            {/* <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' },
              }}> */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    rmId
                      ? history.push(`relationship-manager-details`, { rmId })
                      : history.push('relationship-managers')
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 2, sm: 0 },
                }}>
                {rmId ? 'RM Details' : 'Onboarding RM Details'}
              </Typography>
            </Box>
            {/* <Button
                variant="contained"
                sx={{
                  fontSize: '15px',
                  fontWeight: 500,
                  letterSpacing: 0.5,
                  mr: { xs: 1, sm: 4 },
                  ml: { xs: 1, sm: 0 },
                }}>
                View Distributors
              </Button>
            </Box> */}
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="name" label="RM Name *" placeholder="Enter RM name" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Phone Number *"
                      placeholder="Enter Phone Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      regexForFilterValue={nonDigitRegex}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="roleType"
                      label="Role Type *"
                      items={Object.keys(RoleTypes).map((role) => ({
                        key: RoleTypes[role],
                        value: role,
                      }))}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="panNumber"
                      label="Pan"
                      placeholder="Enter Pan"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue('panNumber', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="empCode" label="Emp Code" placeholder="Enter Emp Code" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="pincode" label="Pincode" placeholder="Enter Pincode" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="buildingNo"
                      label="Building Number"
                      placeholder="Enter Building Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="streetName"
                      label="Street Name"
                      placeholder="Enter Street Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="state" label="State *" placeholder="Enter State" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="city" label="City *" placeholder="Enter City" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="country" label="Country" placeholder="Enter Country" />
                  </Grid>
                </Grid>
                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
