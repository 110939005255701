import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Gridstyles } from './index';
import { Applicant } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { getPEPStatus } from '../NonIndividualApplicationDetails/ContributorDetails';
import { currencyConversion, getclIdField, getdpIdField } from '../../utils/utilityFunctions';
import { SourceOfFundMaster, DLCLMasters, YesNoMaster } from '../../utils/constant';

export default function KycDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Additional KYC Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={15}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Occupation Details'}
                        description={applicant.occupationDetails}
                      />
                      <KeyValue
                        title={'Gross Annual Income'}
                        description={applicant.grossAnnualIncome}
                      />
                      <KeyValue
                        title={'Net Worth'}
                        description={currencyConversion(applicant.netWorth)}
                      />
                      <KeyValue
                        title={'Net worth as on'}
                        description={formatShortDate(applicant.netWorthDate)}
                      />
                      {/*<KeyValue
                        title={'Net Worth Date'}
                        description={formatShortDate(applicant.netWorthDate)}
                      /> */}
                      <KeyValue
                        title={'Politically Exposed Person (PEP) Status'}
                        description={getPEPStatus(applicant.politicallyExposedPersonStatus || '')}
                      />
                      <KeyValue title={'CKYC Number'} description={applicant.ckycNo} />

                      <KeyValue
                        title={'DPCL ID'}
                        description={DLCLMasters[applicant.dlclId || '']}
                      />
                      {getdpIdField(applicant.dlclId || '') && (
                        <KeyValue title={'DP ID'} description={applicant.dpId} />
                      )}
                      {getdpIdField(applicant.dlclId || '') && (
                        <KeyValue title={'CL ID'} description={applicant.clId} />
                      )}
                      {applicant.sourceOfFundsDeclaration && (
                        <>
                          <Grid item xs={12} display="flex" alignItems={'flex-start'} my={2}>
                            <Checkbox defaultChecked disabled={true} />
                            <Typography
                              sx={{
                                fontSize: 15,
                                fontWeight: 700,
                                color: 'rgba(0, 0, 0, 0.6)',
                                letterSpacing: '0.5px',
                              }}>
                              {`I/we hereby confirm that the funds injected/invested have been
                          legitimately and legally obtained/earned and the derivation and use of the
                          funds do not contravene applicable anti-money laundering and terrorist
                          financing legislation.`}
                            </Typography>
                          </Grid>
                          <KeyValue
                            title={'Source Of Funds'}
                            description={
                              SourceOfFundMaster[applicant.sourceOfFunds || ''] ||
                              applicant.sourceOfFunds
                            }
                          />
                        </>
                      )}
                      {applicant.sourceOfFundsLegitimacy && (
                        <Grid item xs={12} display="flex" alignItems={'flex-start'} my={2}>
                          <Checkbox defaultChecked disabled={true} />
                          <Typography
                            sx={{
                              fontSize: 15,
                              fontWeight: 700,
                              color: 'rgba(0, 0, 0, 0.6)',
                              letterSpacing: '0.5px',
                            }}>
                            {`I/we hereby confirm that the aforementioned information is true and
                          correct. I/we will also provide documentary evidence of the source of
                          funds if / when required.`}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
