import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ProceedSaveLater } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import {
  Applicant,
  ApplicationProps,
  DistributorListType,
} from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { DatePicker } from '../../lib/formik/DatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFunds, getFundsList } from '../../redux-store/actions/funds';
import { ClassPlanProps, FundProps, GetFundsListResponseBody } from '../../redux-store/types/funds';
import { investmentPaymentDetailsSchema } from '../../utils/schema';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_TYPE,
  USER_ROLES,
  reducedMinimumCommitmentamount,
  defaultCurrency,
  YesNoMaster,
  MULTIPLE_SETUP_FEE,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  calculateContributionAmt,
  currencyConversion,
  isApplicableField,
  isApplicableSetupfee,
  saveForLater,
  setUpFeeCalculations,
} from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { getDirectApplicableFunds } from '../../redux-store/actions/funds';
import { getDistributorDetailsById } from '../../redux-store/actions/userManagement';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import MFCheckbox from '../../lib/formik/Checkbox';

type Values = Partial<ApplicationProps> & {
  doNotWishToValidateCommitmentAmount: boolean;
  saveType: string;
};

const fundInitialValues = {
  schemeId: '',
  planId: '',
  commitmentAmount: 0,
  netApplicationMoney: 0,
  additionalAmount: null,
  setupFeePercentage: 0,
  managementFeesRate: 0,
  taxRate: 0,
  drawDownNumber: 0,
  applicationSetupFee: null,
  contributionChequeAmount: 0,
  setupFeeAmount: null,
  setupFeeGST: null,
  totalSetupFee: null,
  setupFeeTDS: null,
  netSetupFeeLessTDS: null,
  offeringExpenses: 0,
  totalAmountPayable: null,
  placementFee: null,
  placementFeeApplicable: '',
  operatingExpense: '',
};

const initialValues: Values = {
  ...fundInitialValues,
  modeOfPayment: 'cheque',
  paymentRefNo: '',
  chequeDate: '',
  paymentBankAccountNumber: '',
  paymentBankame: '',
  paymentBankAccontType: '',
  doNotWishToValidateCommitmentAmount: false,
  saveType: 'save and proceed',
};

export const DoNotWishToValidateCommitmentAmountRoles = (role: any): boolean => {
  return [USER_ROLES.RM].includes(role);
};

export default function InvestmentPaymentDetails(): JSX.Element {
  // const [open, setOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [fundsListLoader, setFundsListLoader] = useState<boolean>(true);
  const [selectedFund, setSelectedFund] = useState<FundProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Partial<ClassPlanProps> | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [paymentDetails, setPaymentDetails] = useState(initialValues);
  const [networthAmount, setNetworthAmount] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    minCommitmentAmount = 10000000,
    maxCommitmentAmount = 20000000,
    minContributionPercentage = 10,
    setupFee = 0,
    currency = defaultCurrency,
    setupFeeApplicable = '',
    setupFeeValues = [],
    exceptionMinCommitmentAmount,
  } = selectedPlan || {};

  useEffect(() => {
    const { plan, applicants = [] as Applicant[] } = application || ({} as ApplicationProps);
    const totalNetWorth = applicants
      .map((applicant) => Number(applicant.netWorth))
      .reduce((a: number, b: number) => a + b, 0);
    setNetworthAmount(Number(totalNetWorth));
    const {
      minCommitmentAmount = 1,
      maxCommitmentAmount = 0,
      minContributionPercentage = 1,
      currency = defaultCurrency,
      setupFeeApplicable = '',
      setupFeeValues = [],
      exceptionMinCommitmentAmount,
    } = plan || {};
    setSelectedPlan(
      plan
        ? {
            ...selectedPlan,
            setupFeeApplicable: setupFeeApplicable,
            setupFeeValues: setupFeeValues,
            minCommitmentAmount: Number(minCommitmentAmount),
            maxCommitmentAmount: Number(maxCommitmentAmount),
            minContributionPercentage: Number(minContributionPercentage),
            currency,
            exceptionMinCommitmentAmount: Number(exceptionMinCommitmentAmount),
          }
        : null
    );

    setPaymentDetails({
      ...paymentDetails,
      schemeId: application?.schemeId || '',
      planId: application?.planId || '',
      commitmentAmount: application?.commitmentAmount || 0,
      setupFeePercentage: application?.setupFeePercentage || 0,
      managementFeesRate: application?.managementFeesRate || 0,
      applicationSetupFee:
        application?.applicationSetupFee || (application?.applicationSetupFee === 0 ? 0 : null),
      // taxRate: application?.taxRate || null,
      // drawDownNumber: application?.drawDownNumber || null,
      contributionChequeAmount: Number(application?.contributionChequeAmount) || 0,
      modeOfPayment: application?.modeOfPayment || '',
      paymentRefNo: application?.paymentRefNo || '',
      chequeDate: application?.chequeDate || null,
      paymentBankAccountNumber: application?.paymentBankAccountNumber || '',
      paymentBankame: application?.paymentBankame || '',
      paymentBankAccontType: application?.paymentBankAccontType || '',
      setupFeeAmount: application?.setupFeeAmount,
      setupFeeGST: application?.setupFeeGST,
      totalSetupFee: application?.totalSetupFee,
      setupFeeTDS: application?.setupFeeTDS,
      netSetupFeeLessTDS: application?.netSetupFeeLessTDS,
      totalAmountPayable: application?.totalAmountPayable,
      doNotWishToValidateCommitmentAmount:
        application?.doNotWishToValidateCommitmentAmount || false,
      placementFee: application?.placementFee || null,
      placementFeeApplicable: application?.placementFeeApplicable || '',
      operatingExpense: application?.operatingExpense || '',
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { saveType, ...rest } = values;
      const checkApplication = applicationComparison(
        { ...application, setupFeePercentage: Number(application?.setupFeePercentage) },
        {
          ...application,
          ...rest,
          contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
          setupFeePercentage: Number(rest.setupFeePercentage),
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > 3
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > 7
              ? currentStep
              : Number(currentStep) + 1,
          applicationSetupFee: isApplicableSetupfee(selectedPlan?.setupFeeApplicable || '')
            ? rest?.applicationSetupFee
            : null,
          placementFeeApplicable: rest?.placementFeeApplicable || '',
          placementFee:
            isApplicableField(rest?.placementFeeApplicable || '') &&
            (rest?.placementFee || rest?.placementFee == 0)
              ? rest?.placementFee
              : null,
          operatingExpense: rest?.operatingExpense || '',
        }
      );
      const feeAmountObj = setUpFeeCalculations(
        Number(rest.commitmentAmount) || 0,
        Number(rest.setupFeePercentage) || 0
      );
      const netSetupFee = feeAmountObj.totalSetupFee - (rest.setupFeeTDS || 0);

      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              ...rest,
              contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
              setupFeePercentage: Number(rest.setupFeePercentage),
              setupFeeAmount: feeAmountObj.feeAmount,
              setupFeeGST: feeAmountObj.feeGst,
              totalSetupFee: feeAmountObj.totalSetupFee,
              netSetupFeeLessTDS: netSetupFee,
              applicationSetupFee: isApplicableSetupfee(selectedPlan?.setupFeeApplicable || '')
                ? rest?.applicationSetupFee
                : null,
              placementFeeApplicable: rest?.placementFeeApplicable || '',
              placementFee:
                isApplicableField(rest?.placementFeeApplicable || '') &&
                (rest?.placementFee || rest?.placementFee == 0)
                  ? rest?.placementFee
                  : null,
              operatingExpense: rest?.operatingExpense || '',
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role) &&
                applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL
                  ? 'sent_to_amc_approver'
                  : status === 'sent_to_amc_approver' &&
                    hasPOA &&
                    USER_ROLES.SUBDISTRIBUTOR === role
                  ? 'draft'
                  : status,
              currentStep: applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? 4 : 8,
              // applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              //   ? !!currentStep && currentStep > 3
              //     ? currentStep
              //     : Number(currentStep) + 1
              //   : !!currentStep && currentStep > 7
              //   ? currentStep
              //   : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    setFundsListLoader(true);
    (async function () {
      try {
        const response = application?.distributor_id
          ? ((
              (await dispatch(
                getDistributorDetailsById(application?.distributor_id)
              )) as unknown as DistributorListType
            ).planDetails.schemes as FundProps[])
          : ((await dispatch(
              getDirectApplicableFunds({ isActive: true })
            )) as unknown as FundProps[]);
        if (!isComponentAlive) {
          return;
        }
        const { scheme } = application || {};
        const updatedFundPlan = application?.schemeId
          ? !application.scheme.isActive
            ? [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
            : (response.map((res) => res.id?.toString()) as unknown as string[]).includes(
                application?.schemeId?.toString()
              )
            ? response
            : [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
          : response;
        setFundsList(updatedFundPlan);
        const updatePlans =
          application?.schemeId &&
          application?.scheme.isActive &&
          application?.planId &&
          !application.plan?.isActive
            ? ({
                ...updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)),
                plans: [
                  ...(
                    updatedFundPlan.find(
                      (fund) => Number(fund.id) === Number(scheme?.id)
                    ) as unknown as FundProps
                  ).plans,
                  application.plan,
                ],
              } as unknown as FundProps)
            : updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)) || null;
        setSelectedFund(updatePlans);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    setFundsListLoader(false);
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const getClassPlanOptionsOrDetails = (detail?: string) => {
    if (detail) {
      return selectedFund?.[detail] || '';
    }
    return (
      selectedFund?.plans?.map((plan) => ({ key: plan.planDescription, value: Number(plan.id) })) ||
      []
    );
  };
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={paymentDetails}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            investmentPaymentDetailsSchema(
              values.doNotWishToValidateCommitmentAmount
                ? Number(exceptionMinCommitmentAmount)
                : minCommitmentAmount,
              maxCommitmentAmount,
              minContributionPercentage,
              selectedPlan?.currency || '',
              setupFeeApplicable,
              setupFeeValues as number[],
              selectedPlan as ClassPlanProps
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          {/* {DoNotWishToValidateCommitmentAmountRoles(role) && (
            <Grid item xs={12}>
              <MFCheckbox
                label={'Wish To Lower the Commitment Amount'}
                name={'doNotWishToValidateCommitmentAmount'}
              />
            </Grid>
          )} */}
          <Grid item xs={12}>
            <MFCheckbox
              label={'Wish To Lower the Commitment Amount'}
              name={'doNotWishToValidateCommitmentAmount'}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchableSelect
              name={'schemeId'}
              label="Fund (Scheme) *"
              items={fundsList.map((fund) => ({ value: Number(fund.id), key: fund.schemeName }))}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                setValues({
                  ...values,
                  ...fundInitialValues,
                  schemeId: value as string,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                });
                setSelectedFund(fundsList.find((f) => Number(f.id) === value) || null);
                setSelectedPlan(null);
              }}
              disabled={isFieldDisabled}
              searchFieldPlaceholder={'Search Fund (Scheme)'}
              loading={fundsListLoader}
            />
          </Grid>
          {/* {!!values.schemeId && (
            <Grid item xs={12}>
              <Box
                sx={{
                  boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                  p: { xs: '20px', sm: '30px 40px' },
                  my: 2,
                }}>
                <Box
                  onClick={() => setOpen(!open)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 600,
                      fontSize: 16,
                      color: 'primary.main',
                      letterSpacing: '0.05em',
                    }}>
                    Bank Details related to this fund (Scheme)
                  </Typography>
                  <ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : '' }} />
                </Box>
                {open && (
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      mt: 3,
                      '.MuiTypography-root': {
                        fontSize: 16,
                        letterSpacing: '0.05em',
                        '&.MuiTypography-body2 ': { fontWeight: 600 },
                      },
                    }}>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Name</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankName')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Account Number</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankAccountNumber')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>IFSC Code</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('ifscCode')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Branch</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankBranch')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          )} */}
          <Grid item xs={12} sm={6}>
            <SearchableSelect
              name={'planId'}
              label="Class (Plan) *"
              items={getClassPlanOptionsOrDetails() as [{ key: string; value: string | number }]}
              disabled={!values.schemeId || isFieldDisabled}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                const selectedPlan = selectedFund?.plans
                  ?.filter((plan) => plan.isActive)
                  ?.find((p) => Number(p.id) === value);
                setSelectedPlan(selectedPlan || null);
                setValues({
                  ...values,
                  planId: value as string,
                  commitmentAmount: 0,
                  contributionChequeAmount: 0,
                  setupFeePercentage: 0,
                  applicationSetupFee: MULTIPLE_SETUP_FEE
                    ? ((selectedPlan?.setupFeeValues || []) as number[]).includes(1)
                      ? 1
                      : null
                    : ((selectedPlan?.setupFeeValues || []) as number[])[0],
                  // setupFeePercentage: selectedPlan?.setupFee,
                  managementFeesRate: selectedPlan?.managementFee,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  placementFeeApplicable: selectedPlan?.placementFeeApplicable,
                  operatingExpense: selectedPlan?.operatingExpense,
                  placementFee: selectedPlan?.placementFee,
                });
              }}
              searchFieldPlaceholder={'Search Class (Plan)'}
              loading={fundsListLoader}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'commitmentAmount'}
              label="Commitment Amount *"
              type="number"
              placeholder="Enter Commitment Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  Number(value) || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  commitmentAmount: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  contributionChequeAmount: 0,
                  // contributionChequeAmount: calculateContributionAmt(
                  //   Number(value),
                  //   Number(selectedPlan?.minContributionPercentage)
                  // ),
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled}
            />
            {values.commitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.commitmentAmount, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={'managementFeesRate'}
              label="Management Fee % (p.a.)"
              placeholder="Enter Management Fee Percentage"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={'taxRate'}
              label="Tax Rate"
              placeholder="Enter Tax Rate"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={'drawDownNumber'}
              label="Drawdown Number"
              items={DrawDownMasters.map((master) => ({ key: master, value: master }))}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'contributionChequeAmount'}
              label="Contribution Amount *"
              placeholder="Enter Contribution Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  values.commitmentAmount || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  contributionChequeAmount: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                });
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.contributionChequeAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.contributionChequeAmount, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid> */}

          {isApplicableSetupfee(selectedPlan?.setupFeeApplicable || '') && (
            <>
              <Grid item xs={12} sm={6}>
                {MULTIPLE_SETUP_FEE ? (
                  <MFSelectField
                    name={'applicationSetupFee'}
                    label="Setup Fee % (One-time) *"
                    items={((selectedPlan?.setupFeeValues || []) as number[]).map((master) => ({
                      key: master,
                      value: master,
                    }))}
                    // disabled={!values.planId || isFieldDisabled}
                    disabled={true}
                  />
                ) : (
                  <MFTextField
                    name={'applicationSetupFee'}
                    label="Setup Fee % (One-time) *"
                    placeholder="Enter Setup Fee"
                    type="number"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    trimOnBlur={false}
                    // disabled={!values.planId || isFieldDisabled}
                    disabled={true}
                  />
                )}
              </Grid>
            </>
          )}
          {isApplicableField(values?.placementFeeApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name="placementFee"
                label="Placement Fee % *"
                placeholder="Enter Placement Fee"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={true}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name="operatingExpense"
              multiline
              rows={4.5}
              label={`Operating Expense *`}
              placeholder={`Enter Operating Expense`}
              disabled={true}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={'setupFeePercentage'}
              label="Offering Expenses *"
              type="number"
              placeholder="Enter Offering Expenses"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  values.commitmentAmount || 0,
                  Number(value)
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  setupFeePercentage: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  netApplicationMoney: Number(values.commitmentAmount) - Number(value),
                  totalAmountPayable:
                    Number(values.contributionChequeAmount) + Number(values.additionalAmount),
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled}
            />
            {values.setupFeePercentage != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeePercentage)}
              </Typography>
            )}
          </Grid> */}
          {/*    <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeAmount'}
              label="Setup Fee Amount"
              type="number"
              placeholder="Enter Setup Fee Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.setupFeeAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeAmount, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeGST'}
              label="GST (GST On Setup Fee Amount)"
              type="number"
              placeholder="Enter GST"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.setupFeeGST != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeGST, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalSetupFee'}
              label="Total Setup Fee"
              type="number"
              placeholder="Enter Total Setup Fee"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalSetupFee != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalSetupFee, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeTDS'}
              label="TDS"
              type="number"
              placeholder="Enter TDS"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const setupFeeLessTDS = (values.totalSetupFee || 0) - Number(value);
                setValues({
                  ...values,
                  setupFeeTDS: value ? Number(value) : null,
                  netSetupFeeLessTDS:
                    values.totalSetupFee && value ? setupFeeLessTDS : values.totalSetupFee,
                  totalAmountPayable: values.contributionChequeAmount || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled}
            />
            {values.setupFeeTDS != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeTDS, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'netSetupFeeLessTDS'}
              label="Setup Fee Payable"
              type="number"
              placeholder="Enter Setup Fee Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.netSetupFeeLessTDS != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.netSetupFeeLessTDS, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>*/}
          {/* <Grid
            item
            xs={12}
            onClick={() => setPaymentOpen(!paymentOpen)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              my: 2,
            }}>
            <Typography
              component="h3"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: 25,
                fontWeight: 500,
                color: 'secondary.main',
              }}>
              Payment Details
            </Typography>
            <ExpandMoreIcon sx={{ fontSize: 30, transform: paymentOpen ? 'rotate(180deg)' : '' }} />
          </Grid> */}
          {/* {paymentOpen && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankame'}
                  label="Payment Bank Name"
                  placeholder="Enter Payment Bank Name"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankAccountNumber'}
                  label="Payment Bank Account Number"
                  placeholder="Enter Payment Bank Account Number"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
                <UseRadioGroup
                  name={'paymentBankAccontType'}
                  formLabel="Payment Bank Account Type"
                  defaultValue="current"
                  items={[
                    { label: 'Current', value: 'current' },
                    { label: 'Savings', value: 'savings' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <UseRadioGroup
                  name={'modeOfPayment'}
                  formLabel="Mode of Payment"
                  defaultValue="cheque"
                  items={[
                    { label: 'Cheque', value: 'cheque' },
                    { label: 'RTGS', value: 'rtgs' },
                    { label: 'Online Transfer', value: 'online-transfer' },
                    { label: 'Others', value: 'others' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              {values.modeOfPayment === 'cheque' && (
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Payment/Cheque Date'}
                    placeholder={'Enter Cheque Date'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    name="chequeDate"
                    disabled={isFieldDisabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentRefNo'}
                  label="Payment Ref No"
                  placeholder="Enter Payment Ref No"
                  disabled={isFieldDisabled}
                />
              </Grid>
            </>
          )} */}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
